import React from 'react';
import moment from 'moment';
import _cloneDeep from 'lodash.clonedeep';

import DataTable from '../DataTable';
import { StatusChips } from '../../../../components';
import { labelsOverride } from '../../../../labelsOverride';


const FormatData = ({ data, field, user, onUpdate }) => {
  const clonedData = _cloneDeep(data);

  if (field === 'prevStudies') {
    const rowHeader = {
      name: 'Titulación',
      institutionName: 'Centro',
      iniDate: 'Inicio',
      gradDate: 'Fin'
    };
    const formatedData = clonedData?.map((el) => {
      if (el.iniDate) el.iniDate = moment(el.iniDate).format('DD/MM/YYYY');
      if (el.gradDate) el.iniDate = moment(el.gradDate).format('DD/MM/YYYY');
      return el;
    });
    return <DataTable rowHeader={rowHeader} data={formatedData} />
  }

  if (field === 'workExperience') {
    const rowHeader = {
      company: 'Empresa',
      jobName: 'Puesto',
      country: 'País',
      city: 'Ciudad',
      desc: 'Funciones',
      iniDate: 'Inicio',
      endDate: 'Fin',
    };
    const formatedData = clonedData?.map((el) => {
      const country = el?.country?.nameLang?.es;
      el.country = country;
      if (el.iniDate) el.iniDate = moment(el.iniDate).format('DD/MM/YYYY');
      if (el.endDate) el.endDate = moment(el.endDate).format('DD/MM/YYYY');
      return el;
    });
    return <DataTable rowHeader={rowHeader} data={formatedData} />
  }

  if (field === 'languageExperience') {
    const rowHeader = {
      languageName: 'Idioma',
      speak: 'Hablado',
      write: 'Escrito',
      understand: 'Comprensión'
    };
    return <DataTable rowHeader={rowHeader} data={clonedData} />
  }

  if (field === 'digitalExperience') {
    const rowHeader = {
      name: 'Competencia',
      level: 'Nivel',
      certificate: 'Certificado',
    };
    return <DataTable rowHeader={rowHeader} data={clonedData} />
  }

  if (field === 'otherExperience') {
    const rowHeader = { name: 'Curso' };
    const formatedData = clonedData?.map((el) => ({ name: el }));
    return <DataTable rowHeader={rowHeader} data={formatedData} />
  }

  if (field === 'otherDocuments') {
    const rowHeader = { name: 'Documento' };
    const formatedData = clonedData?.map((el) => {
      return { name: el?.substring(el.lastIndexOf('/') + 1), url: el };
    });
    const options = { down: true };
    return <DataTable rowHeader={rowHeader} data={formatedData} options={options} user={user} />
  }

  if (field === 'documents') {
    const rowHeader = {
      name: 'Nombre',
      dateEnd: 'Antes de',
      status: 'Estado',
      statusReason: 'Razón',
    };

    const formatedData = clonedData?.map((el) => {
      el.status = <StatusChips status={el.status} />;
      if (el?.dateEnd) el.dateEnd = moment(el.dateEnd).format('DD/MM/YYYY');
      if (el?.documentFile) {
        el.url = `docpattern/documentFile/${el.documentFile}`;
      }
      return el;
    });
    const options = { down: true, up: true, editDocument: true, removeDocument: true }
    return (
      <DataTable
        rowHeader={rowHeader}
        data={formatedData}
        options={options}
        field="documents"
        user={user}
        onUpdate={onUpdate}
      />
    )
  }

  if (field === 'payments') {
    const rowHeader = {
      amount: 'Cantidad',
      service: 'Servicio',
      name: 'Cuota',
      statusText: 'Estado',
      type: 'Tipo',
      dateBefore: 'Fecha límite',
      dateDone: 'Fecha pagado',
    };

    const typesDicctionary = {
      deposit: 'Ingreso',
      card: 'Trajeta',
      wireTransfer: 'Transferencia'
    };
    const quotaDictionary = {
      firstPayment: 'Primer pago',
      secondPayment: 'Segundo pago',
      insurance: 'Seguro',
      extension: 'Prórroga',
    };

    const formatedData = clonedData?.map((item) => {
      if (!item) return;
      const el = _cloneDeep(item);
      el.statusText = <StatusChips status={el.status} />;
      if (el?.dateBefore) el.dateBefore = moment(el.dateBefore).format('DD/MM/YYYY');
      if (el?.dateDone) el.dateDone = moment(el.dateDone).format('DD/MM/YYYY');
      if (el?.receiptFile) el.url = `payment/receiptFile/${el.receiptFile}`;
      if (el?.amount) el.amount = `${el.amount} ${el.currency}`
      if (el?.service) el.service = el.service.nameLang.es;
      if (el?.quotaName) el.name = quotaDictionary[el.quotaName];
      if (el?.type) el.type = typesDicctionary[el.type];
      return el;
    });
    const options = { down: true, up: true, linkPayment: true }

    return (
      <DataTable
        rowHeader={rowHeader}
        data={formatedData}
        options={options}
        user={user}
        onUpdate={onUpdate}
        rawData={clonedData}
      />
    );
  }

  if (field === 'statusHistory') {
    const rowHeader = { date: 'Fecha', status: 'Estado' };
    const formatedData = clonedData?.map((el) => {
      return { date: moment(el.date).format('DD/MM/YYYY'), status: labelsOverride[el.status] };
    }).sort((a, b) => a.date - b.date);
    const options = { down: true };
    return <DataTable rowHeader={rowHeader} data={formatedData} options={options} user={user} />
  }

  if (field === 'notifications') {
    const rowHeader = {
      fecha: 'Fecha',
      title: 'Título',
      content: 'Contenido',
      type: 'Tipo',
      isRead: 'Leído',
    };

    const formatedData = clonedData?.map((el) => {
      if (el?.timestamp) {
        el.fecha = moment(el.timestamp).format('DD/MM/YYYY');
      }
      el.isRead = el?.read ? '✅' : <span>&#10060;</span>;
      return el;
    });
    const options = { editNotification: true, removeNotification: true };
    return (
      <DataTable
        rowHeader={rowHeader}
        data={formatedData}
        options={options}
        field="notifications"
        onUpdate={onUpdate}
        user={user}
      />
    )
  }

  return null;
};

export default FormatData;
