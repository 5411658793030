import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import Amplify from '@aws-amplify/core';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import AdminUserContext from './contexts/adminUser';
import { getCurrentUser } from './entities/services/auth';
import awsConfig from './aws-config';
import SchemaManager from './entities/schema';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import backofficeConfig from 'backoffice-config';

const { data } = require('./graphql/schema.json');
SchemaManager.configure({
  awsConfig,
  data,
  queries,
  mutations,
  ...backofficeConfig,
});

// window.LOG_LEVEL = "DEBUG";
Amplify.configure(awsConfig);

const browserHistory = createBrowserHistory();

// Chart.elements.Rectangle was renamed to Chart.elements.BarElement
// helpers.extend. Use Object.assign instead
// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [adminUser, setAdminUser] = useState(null);

  useEffect(() => {
    const getSessionUser =  async () => {
      const { success, data } = await getCurrentUser();
      setLoading(false)
      if (success) {
        setAdminUser({
          username: data.username,
          group: data.group,
          country: data.attributes.name
        });
      }
    }
    getSessionUser();
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <AdminUserContext.Provider value={{ adminUser, setAdminUser }}>
        <Router history={browserHistory}>
          {loading ? null : <Routes />}
        </Router>
      </AdminUserContext.Provider>
    </ThemeProvider>
  );
};

export default App;
