import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  pending: {
    backgroundColor: '#ff9800',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  },
  inReview: {
    backgroundColor: '#4d4d4d',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  },
  error: {
    backgroundColor: '#f44336',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  },
  done: {
    backgroundColor: '#4caf50',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  }
}));

const statusDictionary = {
  pending: { tagText: 'Pendiente', tagColor: 'pending' },
  received: { tagText: 'Recibido', tagColor: 'inReview' },
  inReview: { tagText: 'En revisión', tagColor: 'inReview'},
  error: { tagText: 'Error', tagColor: 'error' },
  done: { tagText: 'Pagado', tagColor: 'done' },
  accepted: { tagText: 'Aceptado', tagColor: 'done' },
  rejected: { tagText: 'Rechazado', tagColor: 'error' },
};

const StatusChips = (props) => {
  const { status } = props;
  const classes = useStyles();

  const tagText = statusDictionary?.[status]?.tagText;
  const tagColor = statusDictionary?.[status]?.tagColor;

  return <Chip label={tagText} size="small" className={classes[tagColor]}/>
};

StatusChips.propTypes = {
  status: PropTypes.string.isRequired,
};
 
export default StatusChips;