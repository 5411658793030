import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@material-ui/core';

import {
  FormatData,
  NewDocumentModal,
  RecomendationModal,
  VacancyCard,
} from './components';
import { StatusChips } from '../../components';


const useStyles = makeStyles(theme => ({
  spacer: {
    height: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  exportButton: {
    marginLeft: theme.spacing(1),
  },
  cardActions: {
    justifyContent: 'space-between',
  },
}));


function getStepsContent(student) {
  const { statusHistory } = student;

  const statusList = statusHistory.map((item) => item.status);
  const getCompleted = (label) => statusList.includes(label);
  const steps = [
    { label: 'Registrado', active: false, completed: true, value: 'registered' },
    { label: 'Inscrito', active: false, completed: getCompleted('paidRegistration'), value: 'paidRegistration' },
    { label: 'Completado el perfil', active: false, completed: getCompleted('profileCompleted'), value: 'profileCompleted' },
    { label: 'Bloqueadas vacantes', active: false, completed: getCompleted('prioritiesLocked'), value: 'prioritiesLocked' },
    { label: 'Asignada vacante', active: false, completed: getCompleted('internshipAsigned'), value: 'internshipAsigned' },
    { label: 'Pago y documentación', active: false, completed: getCompleted('pendingInternshipDocumentation'), value: 'pendingInternshipDocumentation' },
    { label: 'Proceso Autorización de Residencia', active: false, completed: getCompleted('residenceAuth'), value: 'residenceAuth' },
    { label: 'Proceso Visado', active: false, completed: getCompleted('visa'), value: 'visa' },
    { label: 'Proceso de Práctica', active: false, completed: getCompleted('onInternship'), value: 'onInternship' },
    { label: 'Práctica Finalizada', active: false, completed: getCompleted('internshipEnded'), value: 'internshipEnded' },
    { label: 'Pago Prórroga', active: false, completed: getCompleted('paidExtension'), value: 'paidExtension' },
    { label: 'Proceso de prórroga', active: false, completed: getCompleted('extension'), value: 'extension' },
  ]
  return steps;
}


const StudentProcess = (props) => {
  const { user, loading, onUpdate, handlePushNewState, setNewStatus } = props;
  const classes = useStyles();

  const {
    documents,
    favourites,
    payments, 
    priorities,
    recommended,
    recommendedDate,
    recommendedStatus,
    statusHistory = [],
  } = user;

  const orderedPriorities = priorities?.sort((a, b) => a.order - b.order ) || [];

  if (loading) return null;

  const studentStatusHistory = statusHistory ? statusHistory.reverse() : [];

  const getRecomendedStatus = () => {
    if (recommendedStatus === 'accepted') return <StatusChips status="accepted" />;
    if (recommendedStatus === 'rejected') return <StatusChips status="rejected" />;
    return <StatusChips status="pending" />;
  }

  const steps = getStepsContent(user);
 
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <Stepper alternativeLabel>
            {
              steps.map((step) => {
                const { label, active, completed } = step;
                return (
                  <Step key={label} active={active} completed={completed}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                )
              })
            }
          </Stepper>

          <Divider />
          <Divider />
          <CardHeader title="Estado" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <TextField
                  select
                  className={classes.textField}
                  variant="outlined"
                  label="Nuevo estado"
                  defaultValue={''}
                  name="status"
                  fullWidth
                  onChange={({ target: { value } }) => setNewStatus(value)}
                >
                  {
                    steps.map((step) => {
                      const { label, value } = step;
                      return <MenuItem key={value} value={value}>{label}</MenuItem>
                    })
                  }
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <Button className={classes.exportButton} color="primary" variant="contained" onClick={handlePushNewState}>Asignar</Button>
              </Grid>
            </Grid>
            <div className={classes.spacer} />
            <div className={classes.spacer} />
            <FormatData data={studentStatusHistory} field="statusHistory" user={user}/>
            <div className={classes.spacer} />
          </CardContent>
          <Divider />
          <Divider />
          <CardHeader title="Pagos realizados" />
          <CardContent>
            <FormatData data={payments} field="payments" user={user} onUpdate={onUpdate} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={12} xs={12}>
        <Card>
          <CardHeader title="Documentos obligatorios" />
          <Divider />
          <CardContent>
            <FormatData data={documents} field="documents" user={user} onUpdate={onUpdate} name="documentFile" />
          </CardContent>
          <CardActions>
            <NewDocumentModal user={user} title="Añadir documento" onUpdate={onUpdate} name="documentFile" />
          </CardActions>
        </Card>
      </Grid>


      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Prioridades" />
          <Divider />
          <CardContent>
            {orderedPriorities.map((priority) => {
              const { order, vacancy } = priority;
              if (!vacancy) return null;
              return (
                <React.Fragment key ={order}>
                  <VacancyCard key={order} vacancy={vacancy} />
                  <div className={classes.spacer} />
                </React.Fragment>
              )
            })}
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Favoritas" />
          <Divider />
          <CardContent>
            {favourites?.map((fav) => {
              if (!fav) return null;
              return (
                <React.Fragment key ={fav.id}>
                  <VacancyCard key={fav.id} vacancy={fav} />
                  <div className={classes.spacer} />
                </React.Fragment>
              )
            })}
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Recomendadas" />
          <Divider />
          <CardContent>
            {recommended && <VacancyCard vacancy={recommended} date={recommendedDate}/>}
            <div className={classes.spacer} />
          </CardContent>
          <CardActions className={classes.cardActions}>
            <RecomendationModal user={user} onUpdate={onUpdate} />
            <p>{getRecomendedStatus()}</p>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

StudentProcess.propTypes = {
  handlePushNewState: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setNewStatus: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default StudentProcess;
