import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  CurrentStudy,
  FormatData,
  Partner,
  UserForm,
} from './components';
import { FalseIcon, TrueIcon } from '../../components';
import { getFileURL } from '../../entities/services/storage';
import { CVpdf } from './components';


const useStyles = makeStyles(theme => ({
  flexSpacer: {
    flexGrow: 1
  },
  spacer: {
    height: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  exportButton: {
    marginLeft: theme.spacing(1),
  },
}));


const StudentData = (props) => {
  const { user, data, loading, error, rawUser, onUpdate } = props;
  const classes = useStyles();
  const history = useHistory();

  const [showCV, setShowCV] = useState(false);

  const handleBack = () => history.goBack();

  const {
    code,
    createdAt,
    digitalExperience,
    emergencyContacts,
    internship,
    languageExperience,
    number,
    otherDocuments,
    otherExperience,
    partner,
    photo,
    prevStudies,
    skills,
    updatedAt,
    vacancyPrioritiesLocked,
    workExperience,
  } = user;

  const photoSrc = photo ? getFileURL(photo) : '';
  const isExtension = internship?.isExtension;

  const printCV = () => {
    const { name, lastname } = user;
    const title = `${name}_${lastname}_CV`;
    const cv = document.getElementById('print-cv');
    const html = `
    <html>
      <head>
        <title>${title}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <style>
        * {
          margin: 0;
          padding: 0;
        }
        .body {
          font-family: 'Source Sans 3', sans-serif;
          font-weight: 400;
          font-size: 10pt;
          color: #7F7F7F;
          width: 100%;
        }
       .header {
          color: #595959;
          background-color: #edeeef;
          display: flex;
          justify-content: space-between;
          align-items: end;
          padding: 7.5pt 50pt 22pt;
        }
        .logo {
          padding: 22pt 50pt;
        }
        .nexus-logo {
          width: 100px;
        }
        .nexus-logo img {
          width: 100%;
        }
        .title {
          font-size: 42pt;
          font-weight: 700;
          padding-bottom: 10pt;
          border-bottom: 3pt solid #a6a6a6;
        }
        .photo {
          padding-bottom: 6pt;
          border-bottom: 3pt solid #a6a6a6;
        }
        .img {
          width: auto;
          height: auto;
          max-width: 100pt;
          max-height: 150pt;
        }
        .gap {
          height: 18pt;
        }
        .content {
          display: flex;
          min-height: 78%;
        }
        .left {
          width: 35%;
          background-color: #edeeef;
          padding: 22pt 12pt 50pt 50pt;
        }
        .right {
          width: 65%;
          padding: 22pt 12pt 50pt 50pt;
        }
        .sec_title {
          color: #595959;
          font-size: 14pt;
          font-weight: 700;
          margin-bottom: 10pt;
        }
        .featured {
          font-size: 12pt;
          display: flex;
          align-items: center;
          margin-bottom: 5pt;
        }
        .icon {
          display: inline-block;
          width: 50pt;
        }
        .lang {
          min-width: 50%;
        }
        .data {
          font-size: 9pt;
        }
        .section {
          margin-bottom: 50pt;
        }
        .section:last-child {
          margin-bottom: 10pt;
        }
        .section_columns {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20pt;
        }
        .section_columns-title {
          color: #595959;
          font-size: 14pt;
          font-weight: 700;
          text-transform: uppercase;
        }
        .section_columns-subtitle {
          font-size: 12pt;
          margin-bottom: 5pt;
        }
        .section_columns-left {
          width: 20%;
          font-size: 9pt;
          line-height: 12pt;
        }
        .section_columns-right {
          width: 80%;
          padding-left: 10pt;
        }
        ul {
          padding-left: 12pt;
        }
      </style>
      <body>
        ${cv.outerHTML}
      </body>
    </html>
    `;
    const printWindow = window.open('', title);
    printWindow.document.write(html);

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    printWindow.print();
    // Close on print
    // setTimeout(function(){
    //   printWindow.close();
    //   return true;
    // }, 3);
  }

  if (loading) return null;

  if (showCV) {
    return (
      <>
        <Button
          className={classes.exportButton}
          onClick={() => setShowCV(false)}
        >
          Volver a la edición de datos
        </Button>
        <CVpdf student={rawUser} />
        <Button
          onClick={printCV}
        >
          Imprimir
        </Button>
      </>
    );
  }
 
  return (
    <Grid container spacing={4}>
      <Grid container item md={12} xs={12} spacing={2}>
        <span className={classes.flexSpacer} />
        {/* <Button className={classes.exportButton}>Reiniciar contraseña</Button>
        <Button className={classes.exportButton}>Enviar mensaje</Button> */}
        <Button
          className={classes.exportButton}
          onClick={() => setShowCV(true)}
        >
          Ver CV para descargar
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.exportButton}
          onClick={handleBack}
        >
          Volver
        </Button>
      </Grid>

      <Grid container item md={12} xs={12} spacing={2} alignItems="stretch">
        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <Typography>Código: <b>{code}/{number}</b></Typography>
              <Typography>Creado: <b>{moment(createdAt).format('DD/MM/YYYY')}</b></Typography>
              <Typography>Última actualización: <b>{moment(updatedAt).format('DD/MM/YYYY')}</b></Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <Typography>Nombre: <strong>{`${user.name} ${user.lastname}`}</strong></Typography>
              <Typography>Teléfono: <strong>{user.mobilePhone}</strong></Typography>
              <Typography>Email: <strong>{user.email}</strong></Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={3}>
          <img src={photoSrc} alt="Foto de perfil" height="100" />
        </Grid>

        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <Typography>
                PRIORIDADES BLOQUEADAS&#160;
                {
                  vacancyPrioritiesLocked
                    ? <span role="img" aria-label="Prioridades bloqueadas"><TrueIcon /></span>
                    : <span role="img" aria-label="Prioridades no bloqueadas"><FalseIcon /></span>
                }  
              </Typography>
              {internship?.service && <Typography>Pack {internship?.service.nameLang.es}</Typography>}
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <Partner partner={partner} studentId={user.id} />
        </Grid>

        {
          isExtension && (
            <Grid item md={4} xs={12}>
              <Card>
                <CardContent>
                  <Typography>
                    PRÓRROGA SOLICITADA <span role="img" aria-label="Prioridades bloqueadas"><TrueIcon /></span>  
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        }
      </Grid>

      <Grid item md={8} xs={12}>
        <Card>
          <CardHeader title="Datos estudiante" />
          <Divider />
          <CardContent>
            <UserForm user={data} loading={loading} error={error} rawUser={rawUser} onUpdate={onUpdate} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={4} xs={12}>
        <Card>
          <CardHeader title="Estudios actuales" />
          <Divider />
          <CardContent>
            <CurrentStudy user={data} loading={loading} error={error} rawUser={rawUser} onUpdate={onUpdate} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={12} xs={12}>
        <Card>
          <CardHeader title="Experiencia laboral/prácticas" />
          <Divider />
          <CardContent>
            <FormatData data={workExperience} field="workExperience" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Estudios superiores previos" />
          <Divider />
          <CardContent>
            <FormatData data={prevStudies} field="prevStudies" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Otros cursos" />
          <Divider />
          <CardContent>
            <FormatData data={otherExperience} field="otherExperience" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Idiomas" />
          <Divider />
          <CardContent>
            <FormatData data={languageExperience} field="languageExperience" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Competencias digitales" />
          <Divider />
          <CardContent>
            <FormatData data={digitalExperience} field="digitalExperience" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Otros documentos" />
          <Divider />
          <CardContent>
            <FormatData data={otherDocuments} field="otherDocuments" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12}>
        <Card>
          <CardHeader title="Habilidades" />
          <Divider />
          <CardContent>
            {skills}
          </CardContent>
        </Card>
      </Grid>

      {
        emergencyContacts?.length > 0 ? (
          emergencyContacts.map((contact) => {
            const { name: contactName, phone: contactPhone, relationship, email: contactEmail } = contact;

            return (
              <Grid item md={3} xs={12} key={`${contactPhone}${contactName}`}>
                <Card>
                  <CardContent>
                    <Typography>Contacto emergencia: <strong>{relationship}</strong></Typography>
                    <Typography><b>{contactName}: {contactPhone}</b></Typography>
                    <Typography>{contactEmail}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        ) : (
          <Grid item md={3} xs={12}>
            <Card>
              <CardContent>
                <Typography>Contacto emergencia: (sin rellenar)</Typography>
              </CardContent>
            </Card>
          </Grid>
        )
      }
    </Grid>
  );
};

StudentData.propTypes = {
  data: PropTypes.array.isRequired,
  error: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  rawUser: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

export default StudentData;
