import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DragHandleIcon from '@material-ui/icons/DragHandle';

const useStyles = makeStyles(theme => ({
  upDifferenceIcon: {
    color: theme.palette.success.dark
  },
  upDifferenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  downDifferenceIcon: {
    color: theme.palette.error.dark
  },
  downDifferenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  equalDifferenceValue: {
    marginRight: theme.spacing(1)
  }
}));

const UpDownData = (props) => {
  const { difference } = props;

  const classes = useStyles();

  if (difference > 0) {
    return (
      <>
        <ArrowUpwardIcon className={classes.upDifferenceIcon} />
        <Typography className={classes.upDifferenceValue} variant="body2">
          {Math.abs(difference)}%
        </Typography>
      </>
    )
  }
  if (difference < 0) {
    return (
      <>
        <ArrowDownwardIcon className={classes.downDifferenceIcon} />
        <Typography className={classes.downDifferenceValue} variant="body2">
          {Math.abs(difference)}%
        </Typography>
      </>
    )
  }
  return (
    <>
      <DragHandleIcon />
      <Typography className={classes.equalDifferenceValue} variant="body2">
        {Math.abs(difference)}%
      </Typography>
    </>
  )
};

UpDownData.propTypes = {
  difference: PropTypes.number.isRequired,
};

export default UpDownData;
