import { useState, useEffect, useCallback, useContext } from 'react';
import { listEntity } from '../services/entities';
import SchemaManager from '../schema';
import AdminUserContext from 'contexts/adminUser';

const useGraphList = ({ entity, related = false, getAll = false, sorted = false }) => {
  const { adminUser } = useContext(AdminUserContext)

  const [loading, setLoading] = useState(true);
  const [canOrder, setCanOrder] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [filters, setFilters] = useState(null);
  const [relatedEntities, setRelatedEntities] = useState([]);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [page, setPage] = useState(0);

  const fetchEntity = useCallback(async () => {
    const { success, msg, data, paginationToken } = await listEntity({ adminUser, entity, getAll, paginationToken: paginationTokens[page], filters });
    const { tableFields, fields, hasOrder } = SchemaManager.getEntity(entity);
    if (success) {
      setData(sorted ? data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : data);
      setTableFields(tableFields);
      setFields(fields);

      const currentPaginationTokens = [...paginationTokens];
      currentPaginationTokens[page + 1] = paginationToken;
      setPaginationTokens(currentPaginationTokens);

      if (related) {
        const relatedFields = tableFields.filter(tf => tf.type === 'entitySelect' || tf.type === 'entitySelectMultiple');
        const relatedPromisses = relatedFields.map(async (rf) => listEntity(rf.relatedEntity, true));
        const relatedData = await Promise.all(relatedPromisses);
        const relatedEntitiesData = {};
        relatedFields.forEach((rf, i) => {
          const { success, data } = relatedData[i];
          relatedEntitiesData[rf.name] = success ? data : [];
        });
        setRelatedEntities(relatedEntitiesData);
      }
    } else {
      setError(msg)
    }
    setCanOrder(hasOrder);
    setLoading(false);
  }, [entity, setData, setError, setLoading, paginationTokens, page, filters]);

  useEffect(() => {
    setLoading(true);
    fetchEntity();
  }, [entity, page, filters])

  return {
    loading,
    setLoading,
    error,
    data,
    handleRefreshData: fetchEntity,
    tableFields,
    fields ,
    relatedEntities,
    canOrder,
    page,
    hasNextPage: !!paginationTokens[page + 1],
    handleNextPage: () => setPage(page + 1),
    handlePrevPage: () => setPage(page - 1),
    setFilters,
  }
}

export default useGraphList;
