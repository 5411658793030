/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      key
      richTextLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        richTextLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      code
      nameLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCountrys = /* GraphQL */ `
  query ListCountrys(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFaq = /* GraphQL */ `
  query GetFaq($id: ID!) {
    getFaq(id: $id) {
      id
      type
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      questionLang {
        es
        en
      }
      answerLang {
        es
        en
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        questionLang {
          es
          en
        }
        answerLang {
          es
          en
        }
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResidence = /* GraphQL */ `
  query GetResidence($id: ID!) {
    getResidence(id: $id) {
      id
      code
      nameLang {
        es
        en
      }
      iva
      createdAt
      updatedAt
    }
  }
`;
export const listResidences = /* GraphQL */ `
  query ListResidences(
    $filter: ModelResidenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUniversity = /* GraphQL */ `
  query GetUniversity($id: ID!) {
    getUniversity(id: $id) {
      id
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const listUniversitys = /* GraphQL */ `
  query ListUniversitys(
    $filter: ModelUniversityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUniversitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      iva
      comision
      accountCountry
      accountNumber
      address
      titleHolder
      bankBIC
      createdAt
      updatedAt
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        iva
        comision
        accountCountry
        accountNumber
        address
        titleHolder
        bankBIC
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      key
      nameLang {
        es
        en
      }
      showOnBoarding
      price
      includes {
        es
        en
      }
      notIncludes {
        es
        en
      }
      firstPaymentLang {
        es
        en
      }
      secondPaymentLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      destination {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      otherCurrencyPrices {
        country
        currency
        price
        firstPayment
        secondPayment
      }
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      eu
      graduate
      universities {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      price
      firstPayment
      secondPayment
      createdAt
      updatedAt
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        destination {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        eu
        graduate
        universities {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        price
        firstPayment
        secondPayment
        otherCurrencyPrices {
          country
          currency
          price
          firstPayment
          secondPayment
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVacancy = /* GraphQL */ `
  query GetVacancy($id: ID!) {
    getVacancy(id: $id) {
      id
      active
      featured
      zonaGeografica
      type
      company
      department
      stars
      descriptionLang {
        es
        en
      }
      locationURL
      moreInfoURL
      bgImage
      logoImage
      address
      city
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      websiteURL
      benefitEconomy
      benefitMealLang {
        es
        en
      }
      benefitHouse
      datesOpen {
        dateIni
        dateEnd
      }
      companyName
      companyAddress
      companyIdentityDocument
      companyLegalRep
      companyLegalRepId
      contactName
      contactPhone
      contactEmail
      richTextObsLang {
        es
        en
      }
      conditionsLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVacancys = /* GraphQL */ `
  query ListVacancys(
    $filter: ModelVacancyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVacancys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchVacancys = /* GraphQL */ `
  query SearchVacancys {
    searchVacancys {
      id
      active
      featured
      zonaGeografica
      type
      company
      department
      stars
      descriptionLang {
        es
        en
      }
      locationURL
      moreInfoURL
      bgImage
      logoImage
      address
      city
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      websiteURL
      benefitEconomy
      benefitMealLang {
        es
        en
      }
      benefitHouse
      datesOpen {
        dateIni
        dateEnd
      }
      companyName
      companyAddress
      companyIdentityDocument
      companyLegalRep
      companyLegalRepId
      contactName
      contactPhone
      contactEmail
      richTextObsLang {
        es
        en
      }
      conditionsLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      birthday
      code
      contactEmail
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      currentStudy {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      digitalExperience {
        name
        level
        certificate
      }
      documents {
        name
        documentFile
        dateEnd
        status
        statusReason
        responsible
        onExtension
        showToStudent
        docpatternId
      }
      email
      emergencyContacts {
        name
        email
        phone
        relationship
      }
      euNationality
      facebookProfileURL
      favourites {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      gender
      internship {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      languageExperience {
        languageName
        speak
        write
        understand
      }
      lastname
      mobilePhone
      name
      nationalities {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      nationalitiesList
      number
      otherDocuments
      otherExperience
      partner {
        id
        name
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        iva
        comision
        accountCountry
        accountNumber
        address
        titleHolder
        bankBIC
        createdAt
        updatedAt
      }
      passport {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        number
        birthPlace
        expiration
      }
      pastInternships {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      payments {
        id
        student {
          id
          birthday
          code
          contactEmail
          country {
            id
            code
            createdAt
            updatedAt
          }
          currentStudy {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          digitalExperience {
            name
            level
            certificate
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          email
          emergencyContacts {
            name
            email
            phone
            relationship
          }
          euNationality
          facebookProfileURL
          favourites {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          gender
          internship {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          languageExperience {
            languageName
            speak
            write
            understand
          }
          lastname
          mobilePhone
          name
          nationalities {
            id
            code
            iva
            createdAt
            updatedAt
          }
          nationalitiesList
          number
          otherDocuments
          otherExperience
          partner {
            id
            name
            iva
            comision
            accountCountry
            accountNumber
            address
            titleHolder
            bankBIC
            createdAt
            updatedAt
          }
          passport {
            number
            birthPlace
            expiration
          }
          pastInternships {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          payments {
            id
            amount
            currency
            quotaName
            status
            type
            receiptFile
            dateBefore
            dateDone
            paymentIntentId
            code
            createdAt
            updatedAt
          }
          photo
          popupView
          prevStudies {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          priorities {
            order
          }
          recommended {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          recommendedDate
          recommendedStatus
          residence {
            id
            code
            iva
            createdAt
            updatedAt
          }
          residenceExpiration
          residenceHasExpiration
          skills
          skypeUsername
          status
          statusHistory {
            date
            status
          }
          tutor {
            name
            lastname
            email
            phone
          }
          university {
            id
            name
            createdAt
            updatedAt
          }
          vacancyPrioritiesLocked
          workExperience {
            jobName
            iniDate
            endDate
            city
            desc
            company
          }
          device
          previousReturnNotificationSent
          createdAt
          updatedAt
        }
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        amount
        currency
        quotaName
        status
        type
        receiptFile
        dateBefore
        dateDone
        paymentIntentId
        code
        createdAt
        updatedAt
      }
      photo
      popupView
      prevStudies {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      priorities {
        order
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
      }
      recommended {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      recommendedDate
      recommendedStatus
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      residenceExpiration
      residenceHasExpiration
      skills
      skypeUsername
      status
      statusHistory {
        date
        status
      }
      tutor {
        name
        lastname
        email
        phone
      }
      university {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      vacancyPrioritiesLocked
      workExperience {
        jobName
        iniDate
        endDate
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        desc
        company
      }
      device
      previousReturnNotificationSent
      createdAt
      updatedAt
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        birthday
        code
        contactEmail
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        currentStudy {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        digitalExperience {
          name
          level
          certificate
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        email
        emergencyContacts {
          name
          email
          phone
          relationship
        }
        euNationality
        facebookProfileURL
        favourites {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        gender
        internship {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        languageExperience {
          languageName
          speak
          write
          understand
        }
        lastname
        mobilePhone
        name
        nationalities {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        nationalitiesList
        number
        otherDocuments
        otherExperience
        partner {
          id
          name
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          iva
          comision
          accountCountry
          accountNumber
          address
          titleHolder
          bankBIC
          createdAt
          updatedAt
        }
        passport {
          country {
            id
            code
            createdAt
            updatedAt
          }
          number
          birthPlace
          expiration
        }
        pastInternships {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        payments {
          id
          student {
            id
            birthday
            code
            contactEmail
            email
            euNationality
            facebookProfileURL
            gender
            lastname
            mobilePhone
            name
            nationalitiesList
            number
            otherDocuments
            otherExperience
            photo
            popupView
            recommendedDate
            recommendedStatus
            residenceExpiration
            residenceHasExpiration
            skills
            skypeUsername
            status
            vacancyPrioritiesLocked
            device
            previousReturnNotificationSent
            createdAt
            updatedAt
          }
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          amount
          currency
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          code
          createdAt
          updatedAt
        }
        photo
        popupView
        prevStudies {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        priorities {
          order
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
        }
        recommended {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        recommendedDate
        recommendedStatus
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        residenceExpiration
        residenceHasExpiration
        skills
        skypeUsername
        status
        statusHistory {
          date
          status
        }
        tutor {
          name
          lastname
          email
          phone
        }
        university {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        vacancyPrioritiesLocked
        workExperience {
          jobName
          iniDate
          endDate
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          desc
          company
        }
        device
        previousReturnNotificationSent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      url
      featuredVideo
      clientLogoImage
      clientLogoBlackImage
      clientName
      title
      mainImage
      mainVideo
      mainVideoLink
      shortDesc
      seoTitle
      seoDesc
      category {
        id
        name
      }
      tags
      related {
        id
        url
        featuredVideo
        clientLogoImage
        clientLogoBlackImage
        clientName
        title
        mainImage
        mainVideo
        mainVideoLink
        shortDesc
        seoTitle
        seoDesc
        category {
          id
          name
        }
        tags
        related {
          id
          url
          featuredVideo
          clientLogoImage
          clientLogoBlackImage
          clientName
          title
          mainImage
          mainVideo
          mainVideoLink
          shortDesc
          seoTitle
          seoDesc
          category {
            id
            name
          }
          tags
          related {
            id
            url
            featuredVideo
            clientLogoImage
            clientLogoBlackImage
            clientName
            title
            mainImage
            mainVideo
            mainVideoLink
            shortDesc
            seoTitle
            seoDesc
            tags
            isBasProject
            createdAt
            updatedAt
          }
          isBasProject
          content {
            order
            type
            bgColor
            caption
            challenge
            context
            goal
            blockListImage
            blockImage
            text
            title
            url
            videoSubtype
          }
          createdAt
          updatedAt
        }
        isBasProject
        content {
          order
          type
          bgColor
          caption
          challenge
          context
          goal
          blockListImage
          blockImage
          text
          title
          url
          videoSubtype
        }
        createdAt
        updatedAt
      }
      isBasProject
      content {
        order
        type
        bgColor
        caption
        challenge
        context
        goal
        blockListImage
        blockImage
        text
        title
        url
        videoSubtype
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        featuredVideo
        clientLogoImage
        clientLogoBlackImage
        clientName
        title
        mainImage
        mainVideo
        mainVideoLink
        shortDesc
        seoTitle
        seoDesc
        category {
          id
          name
        }
        tags
        related {
          id
          url
          featuredVideo
          clientLogoImage
          clientLogoBlackImage
          clientName
          title
          mainImage
          mainVideo
          mainVideoLink
          shortDesc
          seoTitle
          seoDesc
          category {
            id
            name
          }
          tags
          related {
            id
            url
            featuredVideo
            clientLogoImage
            clientLogoBlackImage
            clientName
            title
            mainImage
            mainVideo
            mainVideoLink
            shortDesc
            seoTitle
            seoDesc
            tags
            isBasProject
            createdAt
            updatedAt
          }
          isBasProject
          content {
            order
            type
            bgColor
            caption
            challenge
            context
            goal
            blockListImage
            blockImage
            text
            title
            url
            videoSubtype
          }
          createdAt
          updatedAt
        }
        isBasProject
        content {
          order
          type
          bgColor
          caption
          challenge
          context
          goal
          blockListImage
          blockImage
          text
          title
          url
          videoSubtype
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStudentNotifications = /* GraphQL */ `
  query ListStudentNotifications($studentId: String) {
    listStudentNotifications(studentId: $studentId) {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      student {
        id
        birthday
        code
        contactEmail
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        currentStudy {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        digitalExperience {
          name
          level
          certificate
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        email
        emergencyContacts {
          name
          email
          phone
          relationship
        }
        euNationality
        facebookProfileURL
        favourites {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        gender
        internship {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        languageExperience {
          languageName
          speak
          write
          understand
        }
        lastname
        mobilePhone
        name
        nationalities {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        nationalitiesList
        number
        otherDocuments
        otherExperience
        partner {
          id
          name
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          iva
          comision
          accountCountry
          accountNumber
          address
          titleHolder
          bankBIC
          createdAt
          updatedAt
        }
        passport {
          country {
            id
            code
            createdAt
            updatedAt
          }
          number
          birthPlace
          expiration
        }
        pastInternships {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        payments {
          id
          student {
            id
            birthday
            code
            contactEmail
            email
            euNationality
            facebookProfileURL
            gender
            lastname
            mobilePhone
            name
            nationalitiesList
            number
            otherDocuments
            otherExperience
            photo
            popupView
            recommendedDate
            recommendedStatus
            residenceExpiration
            residenceHasExpiration
            skills
            skypeUsername
            status
            vacancyPrioritiesLocked
            device
            previousReturnNotificationSent
            createdAt
            updatedAt
          }
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          amount
          currency
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          code
          createdAt
          updatedAt
        }
        photo
        popupView
        prevStudies {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        priorities {
          order
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
        }
        recommended {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        recommendedDate
        recommendedStatus
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        residenceExpiration
        residenceHasExpiration
        skills
        skypeUsername
        status
        statusHistory {
          date
          status
        }
        tutor {
          name
          lastname
          email
          phone
        }
        university {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        vacancyPrioritiesLocked
        workExperience {
          jobName
          iniDate
          endDate
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          desc
          company
        }
        device
        previousReturnNotificationSent
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      amount
      currency
      quotaName
      status
      type
      receiptFile
      dateBefore
      dateDone
      paymentIntentId
      code
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        student {
          id
          birthday
          code
          contactEmail
          country {
            id
            code
            createdAt
            updatedAt
          }
          currentStudy {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          digitalExperience {
            name
            level
            certificate
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          email
          emergencyContacts {
            name
            email
            phone
            relationship
          }
          euNationality
          facebookProfileURL
          favourites {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          gender
          internship {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          languageExperience {
            languageName
            speak
            write
            understand
          }
          lastname
          mobilePhone
          name
          nationalities {
            id
            code
            iva
            createdAt
            updatedAt
          }
          nationalitiesList
          number
          otherDocuments
          otherExperience
          partner {
            id
            name
            iva
            comision
            accountCountry
            accountNumber
            address
            titleHolder
            bankBIC
            createdAt
            updatedAt
          }
          passport {
            number
            birthPlace
            expiration
          }
          pastInternships {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          payments {
            id
            amount
            currency
            quotaName
            status
            type
            receiptFile
            dateBefore
            dateDone
            paymentIntentId
            code
            createdAt
            updatedAt
          }
          photo
          popupView
          prevStudies {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          priorities {
            order
          }
          recommended {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          recommendedDate
          recommendedStatus
          residence {
            id
            code
            iva
            createdAt
            updatedAt
          }
          residenceExpiration
          residenceHasExpiration
          skills
          skypeUsername
          status
          statusHistory {
            date
            status
          }
          tutor {
            name
            lastname
            email
            phone
          }
          university {
            id
            name
            createdAt
            updatedAt
          }
          vacancyPrioritiesLocked
          workExperience {
            jobName
            iniDate
            endDate
            city
            desc
            company
          }
          device
          previousReturnNotificationSent
          createdAt
          updatedAt
        }
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        amount
        currency
        quotaName
        status
        type
        receiptFile
        dateBefore
        dateDone
        paymentIntentId
        code
        createdAt
        updatedAt
      }
      nexToken
    }
  }
`;
export const getDocpattern = /* GraphQL */ `
  query GetDocpattern($id: ID!) {
    getDocpattern(id: $id) {
      id
      active
      euNationality
      studentStatus
      name
      responsible
      onExtension
      documentFile
      createdAt
      updatedAt
      order
      showToStudent
    }
  }
`;
export const listDocpatterns = /* GraphQL */ `
  query ListDocpatterns(
    $filter: ModelDocpatternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocpatterns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        euNationality
        studentStatus
        name
        responsible
        onExtension
        documentFile
        createdAt
        updatedAt
        order
        showToStudent
      }
      nexToken
    }
  }
`;
export const getPopuphelper = /* GraphQL */ `
  query GetPopuphelper($id: ID!) {
    getPopuphelper(id: $id) {
      id
      title
      desc
      screen
      studentStatus
    }
  }
`;
export const listPopuphelpers = /* GraphQL */ `
  query ListPopuphelpers(
    $filter: ModelPopuphelperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPopuphelpers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        desc
        screen
        studentStatus
      }
      nexToken
    }
  }
`;
export const getStudentInfo = /* GraphQL */ `
  query GetStudentInfo {
    getStudentInfo {
      id
      birthday
      code
      contactEmail
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      currentStudy {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      digitalExperience {
        name
        level
        certificate
      }
      documents {
        name
        documentFile
        dateEnd
        status
        statusReason
        responsible
        onExtension
        showToStudent
        docpatternId
      }
      email
      emergencyContacts {
        name
        email
        phone
        relationship
      }
      euNationality
      facebookProfileURL
      favourites {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      gender
      internship {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      languageExperience {
        languageName
        speak
        write
        understand
      }
      lastname
      mobilePhone
      name
      nationalities {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      nationalitiesList
      number
      otherDocuments
      otherExperience
      partner {
        id
        name
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        iva
        comision
        accountCountry
        accountNumber
        address
        titleHolder
        bankBIC
        createdAt
        updatedAt
      }
      passport {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        number
        birthPlace
        expiration
      }
      pastInternships {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      payments {
        id
        student {
          id
          birthday
          code
          contactEmail
          country {
            id
            code
            createdAt
            updatedAt
          }
          currentStudy {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          digitalExperience {
            name
            level
            certificate
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          email
          emergencyContacts {
            name
            email
            phone
            relationship
          }
          euNationality
          facebookProfileURL
          favourites {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          gender
          internship {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          languageExperience {
            languageName
            speak
            write
            understand
          }
          lastname
          mobilePhone
          name
          nationalities {
            id
            code
            iva
            createdAt
            updatedAt
          }
          nationalitiesList
          number
          otherDocuments
          otherExperience
          partner {
            id
            name
            iva
            comision
            accountCountry
            accountNumber
            address
            titleHolder
            bankBIC
            createdAt
            updatedAt
          }
          passport {
            number
            birthPlace
            expiration
          }
          pastInternships {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          payments {
            id
            amount
            currency
            quotaName
            status
            type
            receiptFile
            dateBefore
            dateDone
            paymentIntentId
            code
            createdAt
            updatedAt
          }
          photo
          popupView
          prevStudies {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          priorities {
            order
          }
          recommended {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          recommendedDate
          recommendedStatus
          residence {
            id
            code
            iva
            createdAt
            updatedAt
          }
          residenceExpiration
          residenceHasExpiration
          skills
          skypeUsername
          status
          statusHistory {
            date
            status
          }
          tutor {
            name
            lastname
            email
            phone
          }
          university {
            id
            name
            createdAt
            updatedAt
          }
          vacancyPrioritiesLocked
          workExperience {
            jobName
            iniDate
            endDate
            city
            desc
            company
          }
          device
          previousReturnNotificationSent
          createdAt
          updatedAt
        }
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        amount
        currency
        quotaName
        status
        type
        receiptFile
        dateBefore
        dateDone
        paymentIntentId
        code
        createdAt
        updatedAt
      }
      photo
      popupView
      prevStudies {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      priorities {
        order
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
      }
      recommended {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      recommendedDate
      recommendedStatus
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      residenceExpiration
      residenceHasExpiration
      skills
      skypeUsername
      status
      statusHistory {
        date
        status
      }
      tutor {
        name
        lastname
        email
        phone
      }
      university {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      vacancyPrioritiesLocked
      workExperience {
        jobName
        iniDate
        endDate
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        desc
        company
      }
      device
      previousReturnNotificationSent
      createdAt
      updatedAt
    }
  }
`;
export const getStudentNotifications = /* GraphQL */ `
  query GetStudentNotifications {
    getStudentNotifications {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
export const getStudentPayments = /* GraphQL */ `
  query GetStudentPayments {
    getStudentPayments {
      id
      student {
        id
        birthday
        code
        contactEmail
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        currentStudy {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        digitalExperience {
          name
          level
          certificate
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        email
        emergencyContacts {
          name
          email
          phone
          relationship
        }
        euNationality
        facebookProfileURL
        favourites {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        gender
        internship {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        languageExperience {
          languageName
          speak
          write
          understand
        }
        lastname
        mobilePhone
        name
        nationalities {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        nationalitiesList
        number
        otherDocuments
        otherExperience
        partner {
          id
          name
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          iva
          comision
          accountCountry
          accountNumber
          address
          titleHolder
          bankBIC
          createdAt
          updatedAt
        }
        passport {
          country {
            id
            code
            createdAt
            updatedAt
          }
          number
          birthPlace
          expiration
        }
        pastInternships {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        payments {
          id
          student {
            id
            birthday
            code
            contactEmail
            email
            euNationality
            facebookProfileURL
            gender
            lastname
            mobilePhone
            name
            nationalitiesList
            number
            otherDocuments
            otherExperience
            photo
            popupView
            recommendedDate
            recommendedStatus
            residenceExpiration
            residenceHasExpiration
            skills
            skypeUsername
            status
            vacancyPrioritiesLocked
            device
            previousReturnNotificationSent
            createdAt
            updatedAt
          }
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          amount
          currency
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          code
          createdAt
          updatedAt
        }
        photo
        popupView
        prevStudies {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        priorities {
          order
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
        }
        recommended {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        recommendedDate
        recommendedStatus
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        residenceExpiration
        residenceHasExpiration
        skills
        skypeUsername
        status
        statusHistory {
          date
          status
        }
        tutor {
          name
          lastname
          email
          phone
        }
        university {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        vacancyPrioritiesLocked
        workExperience {
          jobName
          iniDate
          endDate
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          desc
          company
        }
        device
        previousReturnNotificationSent
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      amount
      currency
      quotaName
      status
      type
      receiptFile
      dateBefore
      dateDone
      paymentIntentId
      code
      createdAt
      updatedAt
    }
  }
`;
