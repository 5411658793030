import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Controller } from 'react-hook-form';
import { EditorState, convertToRaw, ContentState, Modifier, convertFromHTML, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './editor.css';

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const useStyles = makeStyles(theme => ({
  editorWrapper: {
    backgroundColor: 'white',
    margin: '20px',
    padding: '20px',
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column'
  },
  editorContainer: { flex: 1 }
}));

const insertChildren = (parsedHtml, paragraph, children, childIndex) => {
  if (childIndex >= children.length) return;

  const child = children[childIndex];
  paragraph.parentNode.insertBefore(child, paragraph);
  insertChildren(parsedHtml, paragraph, children, childIndex + 1);
};

const processParagraphs = (parsedHtml, paragraphs, index) => {
  if (index >= paragraphs.length) return;

  const paragraph = paragraphs[index];
  const br = parsedHtml.createElement('br');
  const children = Array.from(paragraph.childNodes);

  insertChildren(parsedHtml, paragraph, children, 0);
  paragraph.parentNode.insertBefore(br, paragraph);
  paragraph.parentNode.removeChild(paragraph);

  processParagraphs(parsedHtml, paragraphs, index + 1);
};

const replaceParagraphsWithLineBreaks = (html) => {
  const parsedHtml = new DOMParser().parseFromString(html, 'text/html');
  const paragraphs = Array.from(parsedHtml.getElementsByTagName('p'));

  processParagraphs(parsedHtml, paragraphs, 0);
  return parsedHtml.body.innerHTML;
};


const FormRichTextField = ({ entity, errors, field, register, type = 'text', control, rules, disabled, watch, setValue, values }) => {
  const classes = useStyles();
  const { label, name } = field;
  console.log('CDC ~ file: FormRichTextField.js ~ line 33 ~ FormRichTextField ~ field', field);
  const keyValues = Object.keys(values);
  const valueContent = keyValues.find(value => value.startsWith('richText') || value.startsWith('answer') || (entity === 'notification' && value === 'content'))
  const richTextContent = values[valueContent];
  const errorMessage = errors && errors.type && ERROR_MESSAGES[errors.type];

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isAnswerUsed, setIsAnswerUsed] = useState(false);

  const setEditorValue = (richTextContentValue) => {
    const editorValue = watch(name, richTextContentValue) || '<p></p>';
    const { contentBlocks, entityMap } = htmlToDraft(editorValue)
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  };

  // Only set the initial editor state value once when get "answer" from the API
  // otherwise would create a new initial editor state each time the value changes
  useEffect(() => {
    if (richTextContent && !isAnswerUsed) {
      setEditorValue(richTextContent);
      setIsAnswerUsed(true);
    }
  }, [richTextContent]);

  useEffect(() => {
    register({ name }, rules);
  }, []);

  // Set the "answer" value on change from the Editor
  const handleOnChange = (newEditorState) => {
    setEditorState(newEditorState);
    const value = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    const newValue = replaceParagraphsWithLineBreaks(value);
    setValue(name, newValue);
  };

  const handlePastedText = (text, html, editorState) => {
    var content = editorState.getCurrentContent();
    var selection = editorState.getSelection();

    var newContent = Modifier.insertText(
      content,
      selection,
      text
    )

    var newEditorState = EditorState.push(editorState, newContent, 'insert-characters')
    handleOnChange(newEditorState)
    return true
  };

  return (
    <Controller
      as={
        <div className={classes.editorWrapper}>
          <label>{label}</label>
          <Editor
            editorClassName={classes.editorContainer}
            editorState={editorState}
            onEditorStateChange={handleOnChange}
            handlePastedText={handlePastedText}
          />
        </div>
      }
      control={control}
      defaultValue={null}
      error={!!errors}
      fullWidth
      helperText={errorMessage}
      label={label || name}
      labelId={name}
      name={name}
      rules={rules}
      type={type}
      variant="outlined"
    />
  )
}

export default FormRichTextField;