import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import palette from 'theme/palette';
import { getChartOptions } from '../../helpers/chartOptions';
import { getEntityNames } from '../../../../entities/services/entitiesCustom';


const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const ByResidence = props => {
  const { periodLabel, studentData, loading } = props;
  const classes = useStyles();
  const options = getChartOptions();

  const [residences, setResidences] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [error, setError] = useState('');

  const getChartData = () => {   
    const { data, previousData } = studentData; 
    const newData = {
      labels: residences,
      datasets: [
        {
          label: periodLabel,
          backgroundColor: palette.primary.main,
          data: []
        },
        {
          label: `${periodLabel} anterior`,
          backgroundColor: palette.neutral,
          data: []
        }
      ]
    };
    residences.forEach((name) => {
      const count = data.filter((item) => item?.residence?.nameLang?.es === name)?.length;
      const previousCount = previousData.filter((item) => item?.residence?.nameLang?.es === name)?.length;
      newData.datasets[0].data.push(count);
      newData.datasets[1].data.push(previousCount);
    });
    setChartData(newData);
  }

  const fetchData = async () => {
    const { success, msg, data } = await getEntityNames('Residence');
    if (success) {
      const residencesNames = data.map((item) => item?.nameLang?.es).sort();
      setResidences(residencesNames);
    } else setError(msg);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setChartData(null);
    getChartData();
  }, [studentData]);


  return (
    <Card className={classes.root}>
      <CardHeader title="Aplicaciones por residencia" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={chartData}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

ByResidence.propTypes = {
  loading: PropTypes.bool.isRequired,
  periodLabel: PropTypes.string.isRequired,
  studentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
};

export default ByResidence;
