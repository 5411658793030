export const listUsersPayments = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        lastname
        number
        payments {
          id
          code
          amount
          currency
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          student {}
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCustomPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        amount
        currency
        service {
          id
          key
          nameLang {
            es
            en
          }
        }
        quotaName
        status
        type
        receiptFile
        dateBefore
        dateDone
        createdAt
        updatedAt
        student {
          code
          country {
            nameLang {
              es
              en
            }
            id
            code
          }
          gender
          internship {
            departmentsLooking
            initDate
            endDate
          }
          nationalitiesList
          number
          partner {
            id
            name
          }
          pastInternships {
            departmentsLooking
            initDate
            endDate
          }
          residence {
            nameLang {
              es
              en
            }
            id
            code
            iva
          }
        }
      }
      nexToken
    }
  }
`;

export const listDashboardStudentData = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        country {
          nameLang {
            es
            en
          }
        }
        device
        euNationality
        gender
        internship {
          departmentsLooking
          initDate
          endDate
          vacancy {
            department
          }
          service {
            nameLang {
              es
              en
            }
          }
          isExtension
        }
        nationalities {
          nameLang {
            es
            en
          }
        }
        nationalitiesList
        number
        partner {
          name
          country {
            code
          }
        }
        passport {
          country {
            code
          }
        }
        pastInternships {
          departmentsLooking
          initDate
          endDate
          service {
            key
          }
          vacancy {
            department
          }
        }
        payments {
          id
          amount
          code
          currency
          dateBefore
          dateDone
          paymentIntentId
          quotaName
          receiptFile
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          status
          type
          createdAt
          updatedAt
        }
        residence {
          nameLang {
            es
            en
          }
        }
        status
        statusHistory {
          date
          status
        }
        university {
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCountrysNames = /* GraphQL */ `
  query ListCountrys(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        nameLang {
          es
          en
        }
      }
      nextToken
    }
  }
`;

export const listResidencesNames = /* GraphQL */ `
  query ListResidences(
    $filter: ModelResidenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        nameLang {
          es
          en
        }
      }
      nextToken
    }
  }
`;

export const listServicesNames = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        nameLang {
          es
          en
        }
      }
      nextToken
    }
  }
`;
