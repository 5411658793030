import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Popover,
  Typography,
  Modal,
  Grid
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import FileIcon from '@material-ui/icons/FileCopy';

import Row from './Row';
import DraggableComponent from './DraggableComponent';
import DroppableComponent from './DroppableComponent';
import { removeEntity, saveEntity } from '../../../services/entities';
import SchemaManager, { getEntityLabel } from '../../../schema';
import { getFileURL, getPdfURL } from '../../../services/storage';
import HSpacer from '../../HSpacer';
import { StatusChips, TrueIcon, FalseIcon } from '../../../../components';
import AdminUserContext from 'contexts/adminUser';

const useStyles = makeStyles(theme => ({
  inner: {
    paddingTop: 20,
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  popoverContainer: {
    padding: theme.spacing(1)
  },
  rowImage: {
    maxWidth: '50px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageNumber: {
    padding: theme.spacing(2)
  },
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
}));

const GenericTable = ({ data, entity, error, loading, onRefreshData, onEditData, relatedEntities, tableFields, onRemoveItem, noEdit, canOrder, setLoading, page, onNextPage, onPrevPage, hasNextPage, isReadOnly }) => {
  const classes = useStyles();
  const history = useHistory();
  const { adminUser } = useContext(AdminUserContext)
  const isPartner = adminUser.group === 'partner';

  const [removingItem, setRemovingItem] = useState({});
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [tooltipsOpened, setTooltipsOpened] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const filteredTableFields = tableFields.filter(f => {
    if (f.name === 'id' && !SchemaManager.ENTITIES_WITH_MANUAL_IDS.includes(entity)) return false;
    return !SchemaManager.TABLE_FIELDS_EXCLUDES.includes(f.type)
  });

  const handleRemoveEntity = (entity, id, index) => {
    setRemovingItem({ id, index })
    setShowRemoveModal(true);
  };

  const handleConfirmRemove = async () => {
    const { id, index } = removingItem;
    if (onRemoveItem) {
      onRemoveItem(index);
      handleCloseModal();
      return;
    }
    await removeEntity(entity, id);
    setShowRemoveModal(false);
    onRefreshData();
  }

  const handleEditItem = (data, index) => {
    if (onEditData) return onEditData({ data, index });
    history.push(`/${entity.toLowerCase()}/${data.id}`)
  }

  const handleCloseModal = () => {
    setShowRemoveModal(false);
  }

  const openTooltip = (event, i) => {
    setAnchorEl(event.currentTarget);
    setTooltipsOpened({...tooltipsOpened, [i]: true})
  }

  const closeTooltip = (i) => {
    setAnchorEl(null);
    setTooltipsOpened({...tooltipsOpened, [i]: false})
  }

  const renderFieldValue = (field, value, i) => {
    if (field.name === 'status') return <StatusChips status={value} />;
    if (value === null || value === '' || (Array.isArray(value) && value.every(v => v === null))) return '';
    if (field.type === 'float' || field.type === 'int') return value;
    if (field.type === 'boolean') {
      return value ? <TrueIcon /> : <FalseIcon />;
    }
    if (field.type === 'enumSelect') return SchemaManager.getLabelOverride(value) || value;
    if (field.type === 'timestamp') return moment(value).format('DD/MM/YYYY');
    if (field.type === 'date') return moment(value).format('DD/MM/YYYY');
    if (field.type === 'translation') return value?.es?.substr(0, 80) ?? '';
    if (field.type === 'imageUpload' && value) {
      const imgPath = `${entity.toLowerCase()}/${field.name}/${value}`;
      return <img src={getFileURL(imgPath)} className={classes.rowImage} />
    }
    if (field.type === 'fileUpload' && value) {
      const pdfPath = `${entity.toLowerCase()}/${field.name}/${value}`;
      return (
        <a href={getPdfURL(pdfPath)} target="_blank" rel="noopener noreferrer">
          <FileIcon />
        </a>
      )
    }
    if (field.type === 'string') {
      if (field.name.slice(-3) === 'URL' && value) {
        return (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <LinkIcon />
          </a>
        )
      }
      return value;
    }
    if (field.type === 'entitySelect') {
      if (relatedEntities[field.name] && value) {
        const filteredRelatedEntities = relatedEntities[field.name].filter(re => re.id === value.id);
        if (filteredRelatedEntities[0]) return getEntityLabel(filteredRelatedEntities[0]);
      }
      return getEntityLabel(value);
    }
    if (field.type === 'entitySelectMultiple') {
      if (relatedEntities[field.name] && value) {
        const filteredRelatedEntities = relatedEntities[field.name].filter(re => value.filter(v => v?.id === re?.id).length > 0);
        const popoverId = `${field.name}-${i}`;
        return (
          <>
            <Popover
              anchorEl={anchorEl}
              id={popoverId}
              onClose={() => closeTooltip(i)}
              open={!!tooltipsOpened[i]}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <div className={classes.popoverContainer}>
                {filteredRelatedEntities.map(re => (
                  <Typography key={re.id}>{getEntityLabel(re)}</Typography>
                ))}
              </div>
            </Popover>
            <Button  aria-describedby={popoverId} onClick={(e) => openTooltip(e, i)}>{filteredRelatedEntities.length}</Button>
          </>
        )
      }
      return getEntityLabel(value);
    }
  }

  const renderTable = (data, tableFields, noEdit) => {
    return data.map((d, i) => {
      const Draggable = canOrder ? DraggableComponent(d.id, i) : undefined;
      return (
        <TableRow
          className={classes.tableRow}
          component={Draggable} 
          hover
          key={`${d.id}-${i}`}
          // selected={selectedUsers.indexOf(user.cognitoId) !== -1}
        >
          {tableFields.map(tableField => (
            <TableCell key={tableField.name}>{renderFieldValue(tableField, d[tableField.name], i)}</TableCell>
          ))}
          {!isReadOnly && !isPartner && (
            <TableCell>
              {!noEdit && (
                <IconButton
                  color="inherit"
                  onClick={() => handleEditItem(d, i)}
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                color="inherit"
                onClick={() => handleRemoveEntity(entity, d.id, i)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      )
    })
  }

  const getTableContent = ({loading, error, data, noEdit, tableFields}) => {
    if (loading) return <Row value="Cargando" />;
    if (error) return <Row value={`Error: ${error}`} />;
    return renderTable(data, tableFields, noEdit);
  }
  
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source: { index: originIndex }, destination: { index: destIndex } } = result;
    if (originIndex === destIndex) return;

    const movingItem = data[originIndex];
    const destOrdredData = data.filter(item => item.id !== movingItem.id);
    if (destIndex === 0) {
      movingItem.order = destOrdredData[0].order - 1;
    } else if (destIndex === destOrdredData.length) {
      movingItem.order = destOrdredData[destOrdredData.length - 1].order + 1;
    } else {
      const prevOrder = destOrdredData[destIndex - 1].order;
      const nextOrder = destOrdredData[destIndex].order;
      movingItem.order = (prevOrder + nextOrder) / 2;
    }
    // console.log('onDragEnd -> movingItem', movingItem)
    setLoading(true);
    const { success } = await saveEntity({
      entity,
      values: { id: movingItem.id, order: movingItem.order },
      id: movingItem.id 
    });
    // TODO: handle errors
    if (success) onRefreshData();
  }
  const Droppable = canOrder ? DroppableComponent(onDragEnd) : undefined;

  return (
    <>
      <Card className={clsx(classes.root)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.length === users.length}
                        color="primary"
                        indeterminate={
                          selectedUsers.length > 0 &&
                          selectedUsers.length < users.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell> */}
                    {
                      filteredTableFields.map((field) => {
                        const { name, label } = field;

                        // if (filter?.type === 'select' && filter.from === 'entity') {
                        //   return <TableCell key={name}><FilterSelectEntity field={field} filters={filters} setFilters={setFilters} /></TableCell>;
                        // }
                        // if (filter?.type === 'select' && filter.from === 'enum') {
                        //   return <TableCell key={name}><FilterSelectEnum field={field} filters={filters} setFilters={setFilters} /></TableCell>;
                        // }
                        // if (filter?.type === 'string') return <TableCell key={name}><FilterString field={field} filters={filters} setFilters={setFilters} /></TableCell>;
                        // if (filter?.type === 'range') return <TableCell key={name}><FilterRange field={field} filters={filters} setFilters={setFilters} /></TableCell>;

                        return <TableCell key={name}>{label}</TableCell>
                      })
                    }
                    {!isReadOnly && !isPartner && <TableCell>Opciones</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody component={Droppable}>
                  {getTableContent({loading, error, data, tableFields: filteredTableFields, noEdit})}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        {/* <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions> */}
        <Modal
          className={classes.modal}
          open={showRemoveModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.modalPaper}>
            <Typography>¿Quieres borrar este item?</Typography>
            <HSpacer />
            <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  fullWidth
                  onClick={handleCloseModal}
                  size="large"
                  type="button"
                  variant="contained"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleConfirmRemove}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Card>
      <HSpacer />
      {(page > 0 || hasNextPage) && (
        <Card className={clsx(classes.root)} p={4}>
          <CardContent className={classes.content}>
            <Grid container item justifyContent="flex-end" align="center" xs={12} spacing={2}>
              {page > 0 && (
                <Button
                  color="primary"
                  onClick={onPrevPage}
                  type="submit"
                  variant="contained"
                >
                  Anterior
                </Button>
              )}
              <Typography className={classes.pageNumber}>{page + 1}</Typography>
              {hasNextPage && (
                <Button
                  color="primary"
                  onClick={onNextPage}
                  type="submit"
                  variant="contained"
                >
                  Siguiente
                </Button>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};

GenericTable.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.string.isRequired
};

export default GenericTable;
