import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import StoreIcon from '@material-ui/icons/Store';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import WorkIcon from '@material-ui/icons/Work';
import PublicIcon from '@material-ui/icons/Public';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SchoolIcon from '@material-ui/icons/School';
import EuroIcon from '@material-ui/icons/Euro';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NoteIcon from '@material-ui/icons/Note';


import { SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Estudiantes',
      href: '/users',
      icon: <PeopleIcon />
    },
    // {
    //   title: 'Prácticas',
    //   href: '/internships',
    //   icon: <AssignmentIndIcon />
    // },
    {
      title: 'Vacantes',
      href: '/vacancy',
      icon: <StoreIcon />
    },
    {
      title: 'Pagos',
      href: '/payment',
      icon: <AttachMoneyIcon />
    },
    // {
    //   title: 'Mensajería',
    //   href: '/messages',
    //   icon: <MessageIcon />
    // },
    {
      title: 'Servicios',
      href: '/service',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Precios',
      href: '/price',
      icon: <EuroIcon />
    },
    {
      title: 'Universidades',
      href: '/university',
      icon: <SchoolIcon />
    },
    {
      title: 'Partners',
      href: '/partner',
      icon: <WorkIcon />
    },
    {
      title: 'FAQs',
      href: '/faq',
      icon: <QuestionAnswerIcon />
    },
    {
      title: 'Países destino',
      href: '/country',
      icon: <PublicIcon />
    },
    {
      title: 'Países residencia',
      href: '/residence',
      icon: <HomeIcon />
    },
    {
      title: 'Documentos genéricos',
      href: '/docpattern',
      icon: <FileCopyIcon />
    },
    {
      title: 'Contenido',
      href: '/content',
      icon: <AssignmentIcon />
    },
    //{
    //  title: 'Notificaciones',
    //  href: '/newshelper',
    //  icon: <AnnouncementIcon/>
    //},
    {
      title: 'Pop-ups',
      href: '/popuphelper',
      icon: <NoteIcon />
    },
    // {
    //   title: 'Traducciones',
    //   href: '/settings',
    //   icon: <LanguageIcon />
    // },
    // {
    //   title: 'Ajustes',
    //   href: '/settings',
    //   icon: <SettingsIcon />
    // }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        {/* <Profile /> */}
        {/* <Divider className={classes.divider} /> */}
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
