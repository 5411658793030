import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import TabletMacIcon from '@material-ui/icons/TabletMac';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));


const UsersByDevice = props => {
  const { studentData, loading } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  
  const defaultDevices = [
    {
      title: 'Ordenador',
      value: '0',
      icon: <LaptopMacIcon />,
      color: theme.palette.primary.main
    },
    {
      title: 'Tableta',
      value: '0',
      icon: <TabletMacIcon />,
      color: theme.palette.error.main
    },
    {
      title: 'Móvil',
      value: '0',
      icon: <PhoneIphoneIcon />,
      color: theme.palette.warning.main
    }
  ];
  const [devices, setDevices] = useState(defaultDevices);

  const getChartData = () => {
    const { data } = studentData;
    const newData = {
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: [
            theme.palette.primary.main,
            theme.palette.error.main,
            theme.palette.warning.main
          ],
          borderWidth: 8,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white
        }
      ],
      labels: ['Ordenador', 'Tableta', 'Móvil']
    };
    const desktopCount = data.filter((item) => item?.device === 'desktop')?.length;
    const tabletCount = data.filter((item) => item.device === 'tablet')?.length;
    const mobileCount = data.filter((item) => item.device === 'mobile')?.length;
    newData.datasets[0].data = [desktopCount, tabletCount, mobileCount];
    setChartData(newData);
    const newDevices = [...defaultDevices];
    const totalCount = data.length;
    const desktopPercent = desktopCount > 0 ? desktopCount * 100 / totalCount : 0;
    const tabletPercent = tabletCount > 0 ?  tabletCount * 100 / totalCount : 0;
    const mobilePercent = mobileCount > 0 ? mobileCount * 100 / totalCount : 0;
    newDevices[0].value = desktopPercent.toFixed(0);
    newDevices[1].value = tabletPercent.toFixed(0);
    newDevices[2].value = mobilePercent.toFixed(0);
    setDevices(newDevices);
  };

  useEffect(() => {
    // setChartData(null);
    getChartData();
  }, [studentData]);

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        // action={
        //   <IconButton size="small">
        //     <RefreshIcon />
        //   </IconButton>
        // }
        title="Usuarios por dispositivo"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={chartData}
            options={options}
          />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

UsersByDevice.propTypes = {
  loading: PropTypes.bool.isRequired,
  studentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
};

export default UsersByDevice;
