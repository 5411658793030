import moment from 'moment';

import { getMonthsBetween } from '../../UserEdit/helpers';
export { getMonthsBetween };

export const getFilterDates = (dates, period) => {
  if (!dates.dateInit || !dates.dateEnd) {
    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const monthNumber = todayDate.getMonth() + 1;
    const month = monthNumber > 9 ? monthNumber : `0${monthNumber}`;
    
    const dayNumber = todayDate.getDate() + 1;
    const day = dayNumber > 9 ? dayNumber : `0${dayNumber}`;
    const todayWithoutHours = new Date(`${year}/${month}/${day}`);
    const today = moment(todayWithoutHours);

    if (period === 'day') {
      const yesterday = today.clone().add(-1, 'day');
      const previous = yesterday.clone().add(-1, 'day');
      return {
        fromDate: yesterday,
        toDate: today,
        fromPreviousDate: previous,
        toPreviousDate: yesterday,
      };
    }
    if (period === 'week') {
      const lastWeek = today.clone().add(-1, 'week');
      const previousWeek = lastWeek.clone().add(-1, 'week');
      return {
        fromDate: lastWeek,
        toDate: today,
        fromPreviousDate: previousWeek,
        toPreviousDate: lastWeek,
      };
    }
    if (period === 'month') {
      const lastMonth = today.clone().add(-1, 'month');
      const previousMonth = lastMonth.clone().add(-1, 'month');
      return {
        fromDate: lastMonth,
        toDate: today,
        fromPreviousDate: previousMonth,
        toPreviousDate: lastMonth,
      };
    }
    if (period === 'year') {
      const lastYear = today.clone().add(-1, 'year');
      const previousYear = lastYear.clone().add(-1, 'year');
      return {
        fromDate: lastYear,
        toDate: today,
        fromPreviousDate: previousYear,
        toPreviousDate: lastYear,
      };
    }
  }
  
  const fromDate = moment(dates.dateInit);
  const toDate = moment(dates.dateEnd);
  const difference = fromDate.diff(toDate, 'days');
  const fromPreviousDate = fromDate.clone().add(difference, 'days');

  return {
    fromDate,
    toDate,
    fromPreviousDate,
    toPreviousDate: fromDate,
  };
};

export const checkMonthsNumber = (dates) => {
  const { fromDate, toDate } = dates;
  if (!fromDate || !toDate) return false;
  const difference = getMonthsBetween(fromDate, toDate);
  return difference;
};

export const monthDictionary = {
  0: 'Enero',
  1: 'Febrero',
  2: 'Marzo',
  3: 'Abril',
  4: 'Mayo',
  5: 'Junio',
  6: 'Julio',
  7: 'Agosto',
  8: 'Septiembre',
  9: 'Octubre',
  10: 'Noviembre',
  11: 'Diciembre',
};

export const monthReverseDictionary = {
  'Enero': 0,
  'Febrero': 1,
  'Marzo': 2,
  'Abril': 3,
  'Mayo': 4,
  'Junio': 5,
  'Julio': 6,
  'Agosto': 7,
  'Septiembre': 8,
  'Octubre': 9,
  'Noviembre': 10,
  'Diciembre': 11,
};
