import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  chip: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  }
}));

const periodDictionary = {
  day: 'Día',
  week: 'Semana',
  month: 'Mes',
  year: 'Año',
  custom: 'Personalizado',
};

const DatesFilters = (props) => {
  const { period, setPeriod, dates, setDates } = props;
  const { dateInit, dateEnd } = dates;
  const classes = useStyles();

  const handleChangePeriod = (value) => {
    setDates({ dateInit: null, dateEnd: null });
    setPeriod(value);
  }

  return (
    <>
      <Grid item sm={6} xs={12}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  PERÍODO
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around">
              {
                Object.keys(periodDictionary).map((key) => {
                  const label = periodDictionary[key];
                  const active = key === period;

                  return (
                    <Grid item key={key}>
                      <Chip
                        label={label}
                        clickable
                        color={active ? 'primary' : 'default'}
                        onClick={() => handleChangePeriod(key)}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
          </CardContent> 
        </Card>
      </Grid>
      
      <Grid item sm={6} xs={12}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  INTERVALO
                </Typography>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Fecha inicial"
                    format="DD/MM/yyyy"
                    value={dateInit}
                    onChange={(value) => setDates({ ...dates, dateInit: value })}
                    KeyboardButtonProps={{'aria-label': 'change date'}}
                    disabled={period !== 'custom'}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Fecha final"
                    format="DD/MM/yyyy"
                    value={dateEnd}
                    onChange={(value) => setDates({ ...dates, dateEnd: value })}
                    KeyboardButtonProps={{'aria-label': 'change date'}}
                    disabled={period !== 'custom'}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

DatesFilters.propTypes = {
  dates: PropTypes.shape({
    dateInit: PropTypes.object,
    dateEnd: PropTypes.object,
  }).isRequired,
  period: PropTypes.string.isRequired,
  setDates: PropTypes.func.isRequired,
  setPeriod: PropTypes.func.isRequired,
};

export default DatesFilters;
