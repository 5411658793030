export const labelsOverride = {
  accepted: 'Aceptado',
  accountCountry: 'País de la cuenta',
  accountNumber: 'Número de cuenta',
  active: 'Activo',
  address: 'Dirección',
  advanced: 'Avanzado',
  amount: 'Total',
  answerLang: 'Respuesta',
  benefitEconomy: 'Ayuda económica',
  benefitHouse: 'Alojamiento',
  benefitMealLang: 'Comidas',
  bgImage: 'Imagen',
  blockImage: 'Imagen',
  blockListImage: 'Listado de imágenes',
  card: 'Tarjeta',
  city: 'Ciudad',
  code: 'Código',
  company: 'Compañía',
  companyAddress: 'Dirección empresa',
  companyIdentityDocument: 'Nº identidad compañía',
  companyLegalRep: 'Representante legal',
  companyLegalRepId: 'Nº legal',
  companyName: 'Nombre de la compañía',
  conditionsLang: 'Condiciones',
  contactEmail: 'Email del contacto',
  contactName: 'Nombre del contacto',
  contactPhone: 'Teléfono del contacto',
  country: 'País',
  createdAt: 'Creado',
  currency: 'Moneda',
  date: 'Fecha',
  dateBefore: 'Fecha límite',
  dateDone: 'Fecha pago',
  dateEnd: 'Fecha final',
  dateIni: 'Fecha inicio',
  datesOpen: 'Fechas apertura',
  department: 'Departamento',
  deposit: 'Depósito',
  descriptionTrans: 'Descripción',
  descriptionLang: 'Descripción',
  destination: 'Destino',
  dni: 'Necesita DNI',
  document: 'Documento',
  documents: 'Documentos',
  documentFile: 'Documento',
  done: 'Pagado',
  error: 'Error',
  euNationality: 'UE',
  expert: 'Experto',
  extension: 'Prórroga',
  featured: 'Destacado',
  female: 'Mujer',
  firstPayment: 'Primer pago',
  firstPaymentLang: 'Primer pago',
  flex1: 'Flex 1',
  flex2: 'Flex 2',
  format: 'Formato',
  full: 'Full',
  general: 'General',
  graduate: 'Graduado',
  hotel: 'Hotel',
  includes: 'Incluye',
  inReview: 'En revisión',
  internshipEnded: 'Práctica finalizada',
  insurance: 'Seguro',
  intermediate: 'Intermedio',
  internshipAsigned: 'Asignada vacante (pendiente de que se efectúe el pago del programa)',
  interviewerName: 'Nombre',
  interviewerPosition: 'Posición',
  interviewerSkypeUsername: 'Usuario de skype',
  bankBIC: 'SWIFT/BIC',
  limited: 'Básico',
  link: 'Enlace',
  locationURL: 'Link mapa',
  logoImage: 'Logotipo',
  male: 'Hombre',
  menu: 'Inicio',
  moreInfoURL: 'Link más información',
  myAccount: 'Mi cuenta',
  myPractices: 'Mi práctica',
  name: 'Nombre',
  nameLang: 'Nombre',
  native: 'Nativo',
  nexus: 'Nexus',
  none: 'Ninguno',
  notIncludes: 'No incluye',
  onExtension: 'Dentro de "Mi práctica"',
  onInternship: 'Proceso de Práctica (desde que llega a lugar de la Práctica hasta que se va)',
  onIntership: 'En práctica',
  other: 'Otro',
  otherCurrencyPrices: 'Otras divisas',
  padron: 'Necesita padrón',
  payment: 'Pago',
  paymentHistory: 'Historial de pagos',
  paidExtension: 'Pago Prórroga',
  paidRegistration: 'Inscrito (pagada inscripción)',
  pending: 'Pendiente',
  pendingInternshipDocumentation: 'Pago y Documentación',
  price: 'Pago',
  prioritiesLocked: 'Bloqueadas vacantes (Se inicia el proceso de asignación vacante)',
  professional: 'Profesional',
  processes: 'Mis procesos',
  profile: 'Perfil',
  prorroga: 'Prórroga',
  profileCompleted: 'Completado el perfil',
  questionLang: 'Pregunta',
  quotaName: 'Título',
  receiptFile: 'Recibo',
  received: 'Recibido',
  registered: 'Registrado',
  rejected: 'Rechazado',
  residence: 'Residencia',
  residenceAuth: 'Proceso Autorización de Residencia (Desde que se presenta hasta que se obtiene resolución)',
  responsible: 'Responsable',
  restaurant: 'Restaurante',
  richTextLang: 'Contenido',
  richTextAnswerLang: 'Respuesta',
  richTextObsLang: 'Contenido',
  screen: 'Pantalla',
  secondPayment: 'Segundo pago',
  secondPaymentLang: 'Segundo pago',
  service: 'Servicio',
  showOnBoarding: 'Mostrar en onboarding',
  showToStudent: 'Mostrar en "Vista Estudiante',
  stars: 'Estrellas',
  status: 'Estado',
  statusReason: 'Razón',
  student: 'Estudiante',
  studentStatus: 'Estado del estudiante',
  time: 'Hora',
  titleHolder: 'Titular',
  type: 'Tipo',
  ueNieCertificate: 'Necesita certificado UE/NIE',
  universities: 'Universidades',
  university: 'Universidad',
  updatedAt: 'Modificado',
  user: 'Usuario',
  vacancyFinder: 'Vacantes',
  vacancyPrioritiesLocked: 'Priorities locked',
  visa: 'Proceso Visado (Desde que se obtiene la autorización hasta que se obtiene el visado)',
  websiteURL: 'Web',
  wireTransfer: 'Transferencia',
  zonaGeografica: 'Zona geográfica',
};
