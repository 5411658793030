import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import HSpacer from '../../../../entities/components/HSpacer';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
}));

const ModalWarningEdit = ({ showModal, setShowModal }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalPaper}>
        <Typography>El estudiante ha editado estos documentos mientras tu los estabas consultando.</Typography>
        <HSpacer />
        <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              color="secondary"
              fullWidth
              onClick={() => setShowModal(false)}
              size="large"
              type="button"
              variant="contained"
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              onClick={() => history.go(0)}
              size="large"
              type="submit"
              variant="contained"
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default ModalWarningEdit;
