import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import AdminUserContext from '../../contexts/adminUser';

import LoginForm from './LoginForm';
import SetInitialPassword from './SetInitialPassword';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const SignIn = props => {
  const { setAdminUser } = useContext(AdminUserContext);
  const [loginUser, setLoginUser] = useState(null);
  const [newPassword, setNewPassword] = useState(false);
  const classes = useStyles();
  const { history } = props;

  const continueLogin = async (data) => {
    setAdminUser({
      username: data.username,
      group: data.group,
      country: data.attributes.name
    });
    history.push('/users');
  }

  const handleSignIn = async (user) => {
    setLoginUser(user);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setNewPassword(true);
    } else {
      continueLogin(user);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid className={classes.content} item lg={12} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              {newPassword 
                ? <SetInitialPassword onChangePasswordSuccess={continueLogin} user={loginUser} />
                : <LoginForm onLoginSuccess={handleSignIn} />
              }
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};


export default withRouter(SignIn);
