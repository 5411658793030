import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';

const appUrl = process.env.REACT_APP_FRONT_URL_BASE;

const VacancyUrl = (props) => {
  const vacancy = props?.watch();
  const vacancyId = vacancy?.id || '';
  const link = `${appUrl}/vacancy-preview/${vacancyId}`;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Dirección de visualización de la vacante" />
          <Divider />
          <CardContent>
            <Typography>
              {
                vacancyId ? (
                  <a href={link} target="_blank" rel="noreferrer noopener">
                    {link}
                  </a>
                ) : 'Guardar la vacante para obtener la dirección'
              }
              
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

VacancyUrl.propTypes = {
  watch: PropTypes.func.isRequired,
};

export default VacancyUrl;
