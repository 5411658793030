/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContent = /* GraphQL */ `
  mutation CreateContent($input: CreateContentInput!) {
    createContent(input: $input) {
      id
      key
      richTextLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent($input: UpdateContentInput!) {
    updateContent(input: $input) {
      id
      key
      richTextLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent($input: DeleteContentInput!) {
    deleteContent(input: $input) {
      id
      key
      richTextLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry($input: CreateCountryInput!) {
    createCountry(input: $input) {
      id
      code
      nameLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry($input: UpdateCountryInput!) {
    updateCountry(input: $input) {
      id
      code
      nameLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry($input: DeleteCountryInput!) {
    deleteCountry(input: $input) {
      id
      code
      nameLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq($input: CreateFaqInput!) {
    createFaq(input: $input) {
      id
      type
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      questionLang {
        es
        en
      }
      answerLang {
        es
        en
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq($input: UpdateFaqInput!) {
    updateFaq(input: $input) {
      id
      type
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      questionLang {
        es
        en
      }
      answerLang {
        es
        en
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq($input: DeleteFaqInput!) {
    deleteFaq(input: $input) {
      id
      type
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      questionLang {
        es
        en
      }
      answerLang {
        es
        en
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const createResidence = /* GraphQL */ `
  mutation CreateResidence($input: CreateResidenceInput!) {
    createResidence(input: $input) {
      id
      code
      nameLang {
        es
        en
      }
      iva
      createdAt
      updatedAt
    }
  }
`;
export const updateResidence = /* GraphQL */ `
  mutation UpdateResidence($input: UpdateResidenceInput!) {
    updateResidence(input: $input) {
      id
      code
      nameLang {
        es
        en
      }
      iva
      createdAt
      updatedAt
    }
  }
`;
export const deleteResidence = /* GraphQL */ `
  mutation DeleteResidence($input: DeleteResidenceInput!) {
    deleteResidence(input: $input) {
      id
      code
      nameLang {
        es
        en
      }
      iva
      createdAt
      updatedAt
    }
  }
`;
export const createUniversity = /* GraphQL */ `
  mutation CreateUniversity($input: CreateUniversityInput!) {
    createUniversity(input: $input) {
      id
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateUniversity = /* GraphQL */ `
  mutation UpdateUniversity($input: UpdateUniversityInput!) {
    updateUniversity(input: $input) {
      id
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteUniversity = /* GraphQL */ `
  mutation DeleteUniversity($input: DeleteUniversityInput!) {
    deleteUniversity(input: $input) {
      id
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      id
      name
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      iva
      comision
      accountCountry
      accountNumber
      address
      titleHolder
      bankBIC
      createdAt
      updatedAt
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      id
      name
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      iva
      comision
      accountCountry
      accountNumber
      address
      titleHolder
      bankBIC
      createdAt
      updatedAt
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner($input: DeletePartnerInput!) {
    deletePartner(input: $input) {
      id
      name
      country {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      iva
      comision
      accountCountry
      accountNumber
      address
      titleHolder
      bankBIC
      createdAt
      updatedAt
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      id
      key
      nameLang {
        es
        en
      }
      showOnBoarding
      price
      includes {
        es
        en
      }
      notIncludes {
        es
        en
      }
      firstPaymentLang {
        es
        en
      }
      secondPaymentLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
      key
      nameLang {
        es
        en
      }
      showOnBoarding
      price
      includes {
        es
        en
      }
      notIncludes {
        es
        en
      }
      firstPaymentLang {
        es
        en
      }
      secondPaymentLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      id
      key
      nameLang {
        es
        en
      }
      showOnBoarding
      price
      includes {
        es
        en
      }
      notIncludes {
        es
        en
      }
      firstPaymentLang {
        es
        en
      }
      secondPaymentLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPrice = /* GraphQL */ `
  mutation CreatePrice($input: CreatePriceInput!) {
    createPrice(input: $input) {
      id
      destination {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      eu
      graduate
      universities {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      price
      firstPayment
      secondPayment
      otherCurrencyPrices {
        country
        currency
        price
        firstPayment
        secondPayment
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePrice = /* GraphQL */ `
  mutation UpdatePrice($input: UpdatePriceInput!) {
    updatePrice(input: $input) {
      id
      destination {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      otherCurrencyPrices {
        country
        currency
        price
        firstPayment
        secondPayment
      }
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      eu
      graduate
      universities {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      price
      firstPayment
      secondPayment
      createdAt
      updatedAt
    }
  }
`;
export const deletePrice = /* GraphQL */ `
  mutation DeletePrice($input: DeletePriceInput!) {
    deletePrice(input: $input) {
      id
      destination {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      otherCurrencyPrices {
        country
        currency
        price
        firstPayment
        secondPayment
      }
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      eu
      graduate
      universities {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      price
      firstPayment
      secondPayment
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
      student {
        id
        birthday
        code
        contactEmail
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        currentStudy {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        digitalExperience {
          name
          level
          certificate
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        email
        emergencyContacts {
          name
          email
          phone
          relationship
        }
        euNationality
        facebookProfileURL
        favourites {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        gender
        internship {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        languageExperience {
          languageName
          speak
          write
          understand
        }
        lastname
        mobilePhone
        name
        nationalities {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        nationalitiesList
        number
        otherDocuments
        otherExperience
        partner {
          id
          name
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          iva
          comision
          accountCountry
          accountNumber
          address
          titleHolder
          bankBIC
          createdAt
          updatedAt
        }
        passport {
          country {
            id
            code
            createdAt
            updatedAt
          }
          number
          birthPlace
          expiration
        }
        pastInternships {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        payments {
          id
          student {
            id
            birthday
            code
            contactEmail
            email
            euNationality
            facebookProfileURL
            gender
            lastname
            mobilePhone
            name
            nationalitiesList
            number
            otherDocuments
            otherExperience
            photo
            popupView
            recommendedDate
            recommendedStatus
            residenceExpiration
            residenceHasExpiration
            skills
            skypeUsername
            status
            vacancyPrioritiesLocked
            device
            previousReturnNotificationSent
            createdAt
            updatedAt
          }
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          amount
          currency
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          code
          createdAt
          updatedAt
        }
        photo
        popupView
        prevStudies {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        priorities {
          order
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
        }
        recommended {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        recommendedDate
        recommendedStatus
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        residenceExpiration
        residenceHasExpiration
        skills
        skypeUsername
        status
        statusHistory {
          date
          status
        }
        tutor {
          name
          lastname
          email
          phone
        }
        university {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        vacancyPrioritiesLocked
        workExperience {
          jobName
          iniDate
          endDate
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          desc
          company
        }
        device
        previousReturnNotificationSent
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      amount
      currency
      quotaName
      status
      type
      receiptFile
      dateBefore
      dateDone
      paymentIntentId
      code
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment($input: DeletePaymentInput!) {
    deletePayment(input: $input) {
      id
      student {
        id
        birthday
        code
        contactEmail
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        currentStudy {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        digitalExperience {
          name
          level
          certificate
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        email
        emergencyContacts {
          name
          email
          phone
          relationship
        }
        euNationality
        facebookProfileURL
        favourites {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        gender
        internship {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        languageExperience {
          languageName
          speak
          write
          understand
        }
        lastname
        mobilePhone
        name
        nationalities {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        nationalitiesList
        number
        otherDocuments
        otherExperience
        partner {
          id
          name
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          iva
          comision
          accountCountry
          accountNumber
          address
          titleHolder
          bankBIC
          createdAt
          updatedAt
        }
        passport {
          country {
            id
            code
            createdAt
            updatedAt
          }
          number
          birthPlace
          expiration
        }
        pastInternships {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        payments {
          id
          student {
            id
            birthday
            code
            contactEmail
            email
            euNationality
            facebookProfileURL
            gender
            lastname
            mobilePhone
            name
            nationalitiesList
            number
            otherDocuments
            otherExperience
            photo
            popupView
            recommendedDate
            recommendedStatus
            residenceExpiration
            residenceHasExpiration
            skills
            skypeUsername
            status
            vacancyPrioritiesLocked
            device
            previousReturnNotificationSent
            createdAt
            updatedAt
          }
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          amount
          currency
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          code
          createdAt
          updatedAt
        }
        photo
        popupView
        prevStudies {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        priorities {
          order
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
        }
        recommended {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        recommendedDate
        recommendedStatus
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        residenceExpiration
        residenceHasExpiration
        skills
        skypeUsername
        status
        statusHistory {
          date
          status
        }
        tutor {
          name
          lastname
          email
          phone
        }
        university {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        vacancyPrioritiesLocked
        workExperience {
          jobName
          iniDate
          endDate
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          desc
          company
        }
        device
        previousReturnNotificationSent
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      amount
      currency
      quotaName
      status
      type
      receiptFile
      dateBefore
      dateDone
      paymentIntentId
      code
      createdAt
      updatedAt
    }
  }
`;
export const createVacancy = /* GraphQL */ `
  mutation CreateVacancy($input: CreateVacancyInput!) {
    createVacancy(input: $input) {
      id
      active
      featured
      zonaGeografica
      type
      company
      department
      stars
      descriptionLang {
        es
        en
      }
      locationURL
      moreInfoURL
      bgImage
      logoImage
      address
      city
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      websiteURL
      benefitEconomy
      benefitMealLang {
        es
        en
      }
      benefitHouse
      datesOpen {
        dateIni
        dateEnd
      }
      companyName
      companyAddress
      companyIdentityDocument
      companyLegalRep
      companyLegalRepId
      contactName
      contactPhone
      contactEmail
      richTextObsLang {
        es
        en
      }
      conditionsLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVacancy = /* GraphQL */ `
  mutation UpdateVacancy($input: UpdateVacancyInput!) {
    updateVacancy(input: $input) {
      id
      active
      featured
      zonaGeografica
      type
      company
      department
      stars
      descriptionLang {
        es
        en
      }
      locationURL
      moreInfoURL
      bgImage
      logoImage
      address
      city
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      websiteURL
      benefitEconomy
      benefitMealLang {
        es
        en
      }
      benefitHouse
      datesOpen {
        dateIni
        dateEnd
      }
      companyName
      companyAddress
      companyIdentityDocument
      companyLegalRep
      companyLegalRepId
      contactName
      contactPhone
      contactEmail
      richTextObsLang {
        es
        en
      }
      conditionsLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVacancy = /* GraphQL */ `
  mutation DeleteVacancy($input: DeleteVacancyInput!) {
    deleteVacancy(input: $input) {
      id
      active
      featured
      zonaGeografica
      type
      company
      department
      stars
      descriptionLang {
        es
        en
      }
      locationURL
      moreInfoURL
      bgImage
      logoImage
      address
      city
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      websiteURL
      benefitEconomy
      benefitMealLang {
        es
        en
      }
      benefitHouse
      datesOpen {
        dateIni
        dateEnd
      }
      companyName
      companyAddress
      companyIdentityDocument
      companyLegalRep
      companyLegalRepId
      contactName
      contactPhone
      contactEmail
      richTextObsLang {
        es
        en
      }
      conditionsLang {
        es
        en
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      id
      birthday
      code
      contactEmail
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      currentStudy {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      digitalExperience {
        name
        level
        certificate
      }
      documents {
        name
        documentFile
        dateEnd
        status
        statusReason
        responsible
        onExtension
        showToStudent
        docpatternId
      }
      email
      emergencyContacts {
        name
        email
        phone
        relationship
      }
      euNationality
      facebookProfileURL
      favourites {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      gender
      internship {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      languageExperience {
        languageName
        speak
        write
        understand
      }
      lastname
      mobilePhone
      name
      nationalities {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      nationalitiesList
      number
      otherDocuments
      otherExperience
      partner {
        id
        name
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        iva
        comision
        accountCountry
        accountNumber
        address
        titleHolder
        bankBIC
        createdAt
        updatedAt
      }
      passport {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        number
        birthPlace
        expiration
      }
      pastInternships {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      payments {
        id
        student {
          id
          birthday
          code
          contactEmail
          country {
            id
            code
            createdAt
            updatedAt
          }
          currentStudy {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          digitalExperience {
            name
            level
            certificate
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          email
          emergencyContacts {
            name
            email
            phone
            relationship
          }
          euNationality
          facebookProfileURL
          favourites {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          gender
          internship {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          languageExperience {
            languageName
            speak
            write
            understand
          }
          lastname
          mobilePhone
          name
          nationalities {
            id
            code
            iva
            createdAt
            updatedAt
          }
          nationalitiesList
          number
          otherDocuments
          otherExperience
          partner {
            id
            name
            iva
            comision
            accountCountry
            accountNumber
            address
            titleHolder
            bankBIC
            createdAt
            updatedAt
          }
          passport {
            number
            birthPlace
            expiration
          }
          pastInternships {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          payments {
            id
            amount
            currency
            quotaName
            status
            type
            receiptFile
            dateBefore
            dateDone
            paymentIntentId
            code
            createdAt
            updatedAt
          }
          photo
          popupView
          prevStudies {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          priorities {
            order
          }
          recommended {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          recommendedDate
          recommendedStatus
          residence {
            id
            code
            iva
            createdAt
            updatedAt
          }
          residenceExpiration
          residenceHasExpiration
          skills
          skypeUsername
          status
          statusHistory {
            date
            status
          }
          tutor {
            name
            lastname
            email
            phone
          }
          university {
            id
            name
            createdAt
            updatedAt
          }
          vacancyPrioritiesLocked
          workExperience {
            jobName
            iniDate
            endDate
            city
            desc
            company
          }
          device
          previousReturnNotificationSent
          createdAt
          updatedAt
        }
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        amount
        currency
        quotaName
        status
        type
        receiptFile
        dateBefore
        dateDone
        paymentIntentId
        code
        createdAt
        updatedAt
      }
      photo
      popupView
      prevStudies {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      priorities {
        order
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
      }
      recommended {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      recommendedDate
      recommendedStatus
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      residenceExpiration
      residenceHasExpiration
      skills
      skypeUsername
      status
      statusHistory {
        date
        status
      }
      tutor {
        name
        lastname
        email
        phone
      }
      university {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      vacancyPrioritiesLocked
      workExperience {
        jobName
        iniDate
        endDate
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        desc
        company
      }
      device
      previousReturnNotificationSent
      createdAt
      updatedAt
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent($input: DeleteStudentInput!) {
    deleteStudent(input: $input) {
      id
      birthday
      code
      contactEmail
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      currentStudy {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      digitalExperience {
        name
        level
        certificate
      }
      documents {
        name
        documentFile
        dateEnd
        status
        statusReason
        responsible
        onExtension
        showToStudent
        docpatternId
      }
      email
      emergencyContacts {
        name
        email
        phone
        relationship
      }
      euNationality
      facebookProfileURL
      favourites {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      gender
      internship {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      languageExperience {
        languageName
        speak
        write
        understand
      }
      lastname
      mobilePhone
      name
      nationalities {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      nationalitiesList
      number
      otherDocuments
      otherExperience
      partner {
        id
        name
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        iva
        comision
        accountCountry
        accountNumber
        address
        titleHolder
        bankBIC
        createdAt
        updatedAt
      }
      passport {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        number
        birthPlace
        expiration
      }
      pastInternships {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      payments {
        id
        student {
          id
          birthday
          code
          contactEmail
          country {
            id
            code
            createdAt
            updatedAt
          }
          currentStudy {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          digitalExperience {
            name
            level
            certificate
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          email
          emergencyContacts {
            name
            email
            phone
            relationship
          }
          euNationality
          facebookProfileURL
          favourites {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          gender
          internship {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          languageExperience {
            languageName
            speak
            write
            understand
          }
          lastname
          mobilePhone
          name
          nationalities {
            id
            code
            iva
            createdAt
            updatedAt
          }
          nationalitiesList
          number
          otherDocuments
          otherExperience
          partner {
            id
            name
            iva
            comision
            accountCountry
            accountNumber
            address
            titleHolder
            bankBIC
            createdAt
            updatedAt
          }
          passport {
            number
            birthPlace
            expiration
          }
          pastInternships {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          payments {
            id
            amount
            currency
            quotaName
            status
            type
            receiptFile
            dateBefore
            dateDone
            paymentIntentId
            code
            createdAt
            updatedAt
          }
          photo
          popupView
          prevStudies {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          priorities {
            order
          }
          recommended {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          recommendedDate
          recommendedStatus
          residence {
            id
            code
            iva
            createdAt
            updatedAt
          }
          residenceExpiration
          residenceHasExpiration
          skills
          skypeUsername
          status
          statusHistory {
            date
            status
          }
          tutor {
            name
            lastname
            email
            phone
          }
          university {
            id
            name
            createdAt
            updatedAt
          }
          vacancyPrioritiesLocked
          workExperience {
            jobName
            iniDate
            endDate
            city
            desc
            company
          }
          device
          previousReturnNotificationSent
          createdAt
          updatedAt
        }
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        amount
        currency
        quotaName
        status
        type
        receiptFile
        dateBefore
        dateDone
        paymentIntentId
        code
        createdAt
        updatedAt
      }
      photo
      popupView
      prevStudies {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      priorities {
        order
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
      }
      recommended {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      recommendedDate
      recommendedStatus
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      residenceExpiration
      residenceHasExpiration
      skills
      skypeUsername
      status
      statusHistory {
        date
        status
      }
      tutor {
        name
        lastname
        email
        phone
      }
      university {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      vacancyPrioritiesLocked
      workExperience {
        jobName
        iniDate
        endDate
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        desc
        company
      }
      device
      previousReturnNotificationSent
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      name
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      name
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      id
      name
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      url
      featuredVideo
      clientLogoImage
      clientLogoBlackImage
      clientName
      title
      mainImage
      mainVideo
      mainVideoLink
      shortDesc
      seoTitle
      seoDesc
      category {
        id
        name
      }
      tags
      related {
        id
        url
        featuredVideo
        clientLogoImage
        clientLogoBlackImage
        clientName
        title
        mainImage
        mainVideo
        mainVideoLink
        shortDesc
        seoTitle
        seoDesc
        category {
          id
          name
        }
        tags
        related {
          id
          url
          featuredVideo
          clientLogoImage
          clientLogoBlackImage
          clientName
          title
          mainImage
          mainVideo
          mainVideoLink
          shortDesc
          seoTitle
          seoDesc
          category {
            id
            name
          }
          tags
          related {
            id
            url
            featuredVideo
            clientLogoImage
            clientLogoBlackImage
            clientName
            title
            mainImage
            mainVideo
            mainVideoLink
            shortDesc
            seoTitle
            seoDesc
            tags
            isBasProject
            createdAt
            updatedAt
          }
          isBasProject
          content {
            order
            type
            bgColor
            caption
            challenge
            context
            goal
            blockListImage
            blockImage
            text
            title
            url
            videoSubtype
          }
          createdAt
          updatedAt
        }
        isBasProject
        content {
          order
          type
          bgColor
          caption
          challenge
          context
          goal
          blockListImage
          blockImage
          text
          title
          url
          videoSubtype
        }
        createdAt
        updatedAt
      }
      isBasProject
      content {
        order
        type
        bgColor
        caption
        challenge
        context
        goal
        blockListImage
        blockImage
        text
        title
        url
        videoSubtype
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      url
      featuredVideo
      clientLogoImage
      clientLogoBlackImage
      clientName
      title
      mainImage
      mainVideo
      mainVideoLink
      shortDesc
      seoTitle
      seoDesc
      category {
        id
        name
      }
      tags
      related {
        id
        url
        featuredVideo
        clientLogoImage
        clientLogoBlackImage
        clientName
        title
        mainImage
        mainVideo
        mainVideoLink
        shortDesc
        seoTitle
        seoDesc
        category {
          id
          name
        }
        tags
        related {
          id
          url
          featuredVideo
          clientLogoImage
          clientLogoBlackImage
          clientName
          title
          mainImage
          mainVideo
          mainVideoLink
          shortDesc
          seoTitle
          seoDesc
          category {
            id
            name
          }
          tags
          related {
            id
            url
            featuredVideo
            clientLogoImage
            clientLogoBlackImage
            clientName
            title
            mainImage
            mainVideo
            mainVideoLink
            shortDesc
            seoTitle
            seoDesc
            tags
            isBasProject
            createdAt
            updatedAt
          }
          isBasProject
          content {
            order
            type
            bgColor
            caption
            challenge
            context
            goal
            blockListImage
            blockImage
            text
            title
            url
            videoSubtype
          }
          createdAt
          updatedAt
        }
        isBasProject
        content {
          order
          type
          bgColor
          caption
          challenge
          context
          goal
          blockListImage
          blockImage
          text
          title
          url
          videoSubtype
        }
        createdAt
        updatedAt
      }
      isBasProject
      content {
        order
        type
        bgColor
        caption
        challenge
        context
        goal
        blockListImage
        blockImage
        text
        title
        url
        videoSubtype
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      id
      url
      featuredVideo
      clientLogoImage
      clientLogoBlackImage
      clientName
      title
      mainImage
      mainVideo
      mainVideoLink
      shortDesc
      seoTitle
      seoDesc
      category {
        id
        name
      }
      tags
      related {
        id
        url
        featuredVideo
        clientLogoImage
        clientLogoBlackImage
        clientName
        title
        mainImage
        mainVideo
        mainVideoLink
        shortDesc
        seoTitle
        seoDesc
        category {
          id
          name
        }
        tags
        related {
          id
          url
          featuredVideo
          clientLogoImage
          clientLogoBlackImage
          clientName
          title
          mainImage
          mainVideo
          mainVideoLink
          shortDesc
          seoTitle
          seoDesc
          category {
            id
            name
          }
          tags
          related {
            id
            url
            featuredVideo
            clientLogoImage
            clientLogoBlackImage
            clientName
            title
            mainImage
            mainVideo
            mainVideoLink
            shortDesc
            seoTitle
            seoDesc
            tags
            isBasProject
            createdAt
            updatedAt
          }
          isBasProject
          content {
            order
            type
            bgColor
            caption
            challenge
            context
            goal
            blockListImage
            blockImage
            text
            title
            url
            videoSubtype
          }
          createdAt
          updatedAt
        }
        isBasProject
        content {
          order
          type
          bgColor
          caption
          challenge
          context
          goal
          blockListImage
          blockImage
          text
          title
          url
          videoSubtype
        }
        createdAt
        updatedAt
      }
      isBasProject
      content {
        order
        type
        bgColor
        caption
        challenge
        context
        goal
        blockListImage
        blockImage
        text
        title
        url
        videoSubtype
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDocpattern = /* GraphQL */ `
  mutation CreateDocpattern($input: CreateDocpatternInput!) {
    createDocpattern(input: $input) {
      id
      active
      euNationality
      studentStatus
      name
      responsible
      onExtension
      documentFile
      createdAt
      updatedAt
      order
      showToStudent
    }
  }
`;
export const updateDocpattern = /* GraphQL */ `
  mutation UpdateDocpattern($input: UpdateDocpatternInput!) {
    updateDocpattern(input: $input) {
      id
      active
      euNationality
      studentStatus
      name
      responsible
      onExtension
      documentFile
      createdAt
      updatedAt
      order
      showToStudent
    }
  }
`;
export const deleteDocpattern = /* GraphQL */ `
  mutation DeleteDocpattern($input: DeleteDocpatternInput!) {
    deleteDocpattern(input: $input) {
      id
      active
      euNationality
      studentStatus
      name
      responsible
      onExtension
      documentFile
      createdAt
      updatedAt
      order
      showToStudent
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput) {
    createNotification(input: $input) {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput) {
    updateNotification(input: $input) {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput) {
    deleteNotification(input: $input) {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
export const createPopuphelper = /* GraphQL */ `
  mutation CreatePopuphelper($input: CreatePopuphelperInput!) {
    createPopuphelper(input: $input) {
      id
      title
      desc
      screen
      studentStatus
    }
  }
`;
export const updatePopuphelper = /* GraphQL */ `
  mutation UpdatePopuphelper($input: UpdatePopuphelperInput!) {
    updatePopuphelper(input: $input) {
      id
      title
      desc
      screen
      studentStatus
    }
  }
`;
export const deletePopuphelper = /* GraphQL */ `
  mutation DeletePopuphelper($input: DeletePopuphelperInput!) {
    deletePopuphelper(input: $input) {
      id
      title
      desc
      screen
      studentStatus
    }
  }
`;
export const updateStudentInfo = /* GraphQL */ `
  mutation UpdateStudentInfo($input: UpdateStudentInput!) {
    updateStudentInfo(input: $input) {
      id
      birthday
      code
      contactEmail
      country {
        id
        code
        nameLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      currentStudy {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      digitalExperience {
        name
        level
        certificate
      }
      documents {
        name
        documentFile
        dateEnd
        status
        statusReason
        responsible
        onExtension
        showToStudent
        docpatternId
      }
      email
      emergencyContacts {
        name
        email
        phone
        relationship
      }
      euNationality
      facebookProfileURL
      favourites {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      gender
      internship {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      languageExperience {
        languageName
        speak
        write
        understand
      }
      lastname
      mobilePhone
      name
      nationalities {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      nationalitiesList
      number
      otherDocuments
      otherExperience
      partner {
        id
        name
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        iva
        comision
        accountCountry
        accountNumber
        address
        titleHolder
        bankBIC
        createdAt
        updatedAt
      }
      passport {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        number
        birthPlace
        expiration
      }
      pastInternships {
        internshipHours
        departmentsLooking
        initDate
        endDate
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        whatToBring
        interviews {
          companyName
          date
          status
          timeLang {
            es
            en
          }
          formatLang {
            es
            en
          }
          link
          interviewerName
          interviewerPosition
          interviewerSkypeUsername
        }
        isExtension
        visaInitDate
        visaEndDate
        authorizationResidencyInitDate
        authorizationResidencyEndDate
      }
      payments {
        id
        student {
          id
          birthday
          code
          contactEmail
          country {
            id
            code
            createdAt
            updatedAt
          }
          currentStudy {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          digitalExperience {
            name
            level
            certificate
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          email
          emergencyContacts {
            name
            email
            phone
            relationship
          }
          euNationality
          facebookProfileURL
          favourites {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          gender
          internship {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          languageExperience {
            languageName
            speak
            write
            understand
          }
          lastname
          mobilePhone
          name
          nationalities {
            id
            code
            iva
            createdAt
            updatedAt
          }
          nationalitiesList
          number
          otherDocuments
          otherExperience
          partner {
            id
            name
            iva
            comision
            accountCountry
            accountNumber
            address
            titleHolder
            bankBIC
            createdAt
            updatedAt
          }
          passport {
            number
            birthPlace
            expiration
          }
          pastInternships {
            internshipHours
            departmentsLooking
            initDate
            endDate
            whatToBring
            isExtension
            visaInitDate
            visaEndDate
            authorizationResidencyInitDate
            authorizationResidencyEndDate
          }
          payments {
            id
            amount
            currency
            quotaName
            status
            type
            receiptFile
            dateBefore
            dateDone
            paymentIntentId
            code
            createdAt
            updatedAt
          }
          photo
          popupView
          prevStudies {
            city
            address
            cp
            region
            campus
            iniDate
            endDate
            gradDate
            ended
            name
            institutionName
          }
          priorities {
            order
          }
          recommended {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          recommendedDate
          recommendedStatus
          residence {
            id
            code
            iva
            createdAt
            updatedAt
          }
          residenceExpiration
          residenceHasExpiration
          skills
          skypeUsername
          status
          statusHistory {
            date
            status
          }
          tutor {
            name
            lastname
            email
            phone
          }
          university {
            id
            name
            createdAt
            updatedAt
          }
          vacancyPrioritiesLocked
          workExperience {
            jobName
            iniDate
            endDate
            city
            desc
            company
          }
          device
          previousReturnNotificationSent
          createdAt
          updatedAt
        }
        service {
          id
          key
          nameLang {
            es
            en
          }
          showOnBoarding
          price
          includes {
            es
            en
          }
          notIncludes {
            es
            en
          }
          firstPaymentLang {
            es
            en
          }
          secondPaymentLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        amount
        currency
        quotaName
        status
        type
        receiptFile
        dateBefore
        dateDone
        paymentIntentId
        code
        createdAt
        updatedAt
      }
      photo
      popupView
      prevStudies {
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        address
        cp
        region
        campus
        iniDate
        endDate
        gradDate
        ended
        name
        institutionName
      }
      priorities {
        order
        vacancy {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
      }
      recommended {
        id
        active
        featured
        zonaGeografica
        type
        company
        department
        stars
        descriptionLang {
          es
          en
        }
        locationURL
        moreInfoURL
        bgImage
        logoImage
        address
        city
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        websiteURL
        benefitEconomy
        benefitMealLang {
          es
          en
        }
        benefitHouse
        datesOpen {
          dateIni
          dateEnd
        }
        companyName
        companyAddress
        companyIdentityDocument
        companyLegalRep
        companyLegalRepId
        contactName
        contactPhone
        contactEmail
        richTextObsLang {
          es
          en
        }
        conditionsLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      recommendedDate
      recommendedStatus
      residence {
        id
        code
        nameLang {
          es
          en
        }
        iva
        createdAt
        updatedAt
      }
      residenceExpiration
      residenceHasExpiration
      skills
      skypeUsername
      status
      statusHistory {
        date
        status
      }
      tutor {
        name
        lastname
        email
        phone
      }
      university {
        id
        country {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        name
        createdAt
        updatedAt
      }
      vacancyPrioritiesLocked
      workExperience {
        jobName
        iniDate
        endDate
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        city
        desc
        company
      }
      device
      previousReturnNotificationSent
      createdAt
      updatedAt
    }
  }
`;
export const readNotification = /* GraphQL */ `
  mutation ReadNotification($timestamp: Long) {
    readNotification(timestamp: $timestamp) {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      student {
        id
        birthday
        code
        contactEmail
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        currentStudy {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        digitalExperience {
          name
          level
          certificate
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        email
        emergencyContacts {
          name
          email
          phone
          relationship
        }
        euNationality
        facebookProfileURL
        favourites {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        gender
        internship {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        languageExperience {
          languageName
          speak
          write
          understand
        }
        lastname
        mobilePhone
        name
        nationalities {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        nationalitiesList
        number
        otherDocuments
        otherExperience
        partner {
          id
          name
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          iva
          comision
          accountCountry
          accountNumber
          address
          titleHolder
          bankBIC
          createdAt
          updatedAt
        }
        passport {
          country {
            id
            code
            createdAt
            updatedAt
          }
          number
          birthPlace
          expiration
        }
        pastInternships {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        payments {
          id
          student {
            id
            birthday
            code
            contactEmail
            email
            euNationality
            facebookProfileURL
            gender
            lastname
            mobilePhone
            name
            nationalitiesList
            number
            otherDocuments
            otherExperience
            photo
            popupView
            recommendedDate
            recommendedStatus
            residenceExpiration
            residenceHasExpiration
            skills
            skypeUsername
            status
            vacancyPrioritiesLocked
            device
            previousReturnNotificationSent
            createdAt
            updatedAt
          }
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          amount
          currency
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          code
          createdAt
          updatedAt
        }
        photo
        popupView
        prevStudies {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        priorities {
          order
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
        }
        recommended {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        recommendedDate
        recommendedStatus
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        residenceExpiration
        residenceHasExpiration
        skills
        skypeUsername
        status
        statusHistory {
          date
          status
        }
        tutor {
          name
          lastname
          email
          phone
        }
        university {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        vacancyPrioritiesLocked
        workExperience {
          jobName
          iniDate
          endDate
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          desc
          company
        }
        device
        previousReturnNotificationSent
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      amount
      currency
      quotaName
      status
      type
      receiptFile
      dateBefore
      dateDone
      paymentIntentId
      code
      createdAt
      updatedAt
    }
  }
`;
export const payPayment = /* GraphQL */ `
  mutation PayPayment($input: UpdatePaymentInput) {
    payPayment(input: $input) {
      id
      student {
        id
        birthday
        code
        contactEmail
        country {
          id
          code
          nameLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        currentStudy {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        digitalExperience {
          name
          level
          certificate
        }
        documents {
          name
          documentFile
          dateEnd
          status
          statusReason
          responsible
          onExtension
          showToStudent
          docpatternId
        }
        email
        emergencyContacts {
          name
          email
          phone
          relationship
        }
        euNationality
        facebookProfileURL
        favourites {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        gender
        internship {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        languageExperience {
          languageName
          speak
          write
          understand
        }
        lastname
        mobilePhone
        name
        nationalities {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        nationalitiesList
        number
        otherDocuments
        otherExperience
        partner {
          id
          name
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          iva
          comision
          accountCountry
          accountNumber
          address
          titleHolder
          bankBIC
          createdAt
          updatedAt
        }
        passport {
          country {
            id
            code
            createdAt
            updatedAt
          }
          number
          birthPlace
          expiration
        }
        pastInternships {
          internshipHours
          departmentsLooking
          initDate
          endDate
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
          documents {
            name
            documentFile
            dateEnd
            status
            statusReason
            responsible
            onExtension
            showToStudent
            docpatternId
          }
          whatToBring
          interviews {
            companyName
            date
            status
            link
            interviewerName
            interviewerPosition
            interviewerSkypeUsername
          }
          isExtension
          visaInitDate
          visaEndDate
          authorizationResidencyInitDate
          authorizationResidencyEndDate
        }
        payments {
          id
          student {
            id
            birthday
            code
            contactEmail
            email
            euNationality
            facebookProfileURL
            gender
            lastname
            mobilePhone
            name
            nationalitiesList
            number
            otherDocuments
            otherExperience
            photo
            popupView
            recommendedDate
            recommendedStatus
            residenceExpiration
            residenceHasExpiration
            skills
            skypeUsername
            status
            vacancyPrioritiesLocked
            device
            previousReturnNotificationSent
            createdAt
            updatedAt
          }
          service {
            id
            key
            showOnBoarding
            price
            createdAt
            updatedAt
          }
          amount
          currency
          quotaName
          status
          type
          receiptFile
          dateBefore
          dateDone
          paymentIntentId
          code
          createdAt
          updatedAt
        }
        photo
        popupView
        prevStudies {
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          address
          cp
          region
          campus
          iniDate
          endDate
          gradDate
          ended
          name
          institutionName
        }
        priorities {
          order
          vacancy {
            id
            active
            featured
            zonaGeografica
            type
            company
            department
            stars
            locationURL
            moreInfoURL
            bgImage
            logoImage
            address
            city
            websiteURL
            benefitEconomy
            benefitHouse
            companyName
            companyAddress
            companyIdentityDocument
            companyLegalRep
            companyLegalRepId
            contactName
            contactPhone
            contactEmail
            createdAt
            updatedAt
          }
        }
        recommended {
          id
          active
          featured
          zonaGeografica
          type
          company
          department
          stars
          descriptionLang {
            es
            en
          }
          locationURL
          moreInfoURL
          bgImage
          logoImage
          address
          city
          country {
            id
            code
            createdAt
            updatedAt
          }
          websiteURL
          benefitEconomy
          benefitMealLang {
            es
            en
          }
          benefitHouse
          datesOpen {
            dateIni
            dateEnd
          }
          companyName
          companyAddress
          companyIdentityDocument
          companyLegalRep
          companyLegalRepId
          contactName
          contactPhone
          contactEmail
          richTextObsLang {
            es
            en
          }
          conditionsLang {
            es
            en
          }
          createdAt
          updatedAt
        }
        recommendedDate
        recommendedStatus
        residence {
          id
          code
          nameLang {
            es
            en
          }
          iva
          createdAt
          updatedAt
        }
        residenceExpiration
        residenceHasExpiration
        skills
        skypeUsername
        status
        statusHistory {
          date
          status
        }
        tutor {
          name
          lastname
          email
          phone
        }
        university {
          id
          country {
            id
            code
            iva
            createdAt
            updatedAt
          }
          name
          createdAt
          updatedAt
        }
        vacancyPrioritiesLocked
        workExperience {
          jobName
          iniDate
          endDate
          country {
            id
            code
            createdAt
            updatedAt
          }
          city
          desc
          company
        }
        device
        previousReturnNotificationSent
        createdAt
        updatedAt
      }
      service {
        id
        key
        nameLang {
          es
          en
        }
        showOnBoarding
        price
        includes {
          es
          en
        }
        notIncludes {
          es
          en
        }
        firstPaymentLang {
          es
          en
        }
        secondPaymentLang {
          es
          en
        }
        createdAt
        updatedAt
      }
      amount
      currency
      quotaName
      status
      type
      receiptFile
      dateBefore
      dateDone
      paymentIntentId
      code
      createdAt
      updatedAt
    }
  }
`;
