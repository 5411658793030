import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';


const StudentPaymentLink = (props) => {
  const item = props?.watch();
  const { paymentStudentId } = item;
  const userLink = paymentStudentId ? `/users/${paymentStudentId}` : '#';

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Enlace al estudiante" />
          <Divider />
          <CardContent>
            <Typography>
              {
                paymentStudentId ? (
                  <Link to={userLink}>
                    Enlace a los datos del estudiante
                  </Link>
                ) : 'Añadir estudiante para obtener el enlace'
              }
              
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

StudentPaymentLink.propTypes = {
  watch: PropTypes.func.isRequired,
};

export default StudentPaymentLink;
