import AWS from 'aws-sdk';

import SchemaManager from '../schema';
import { response } from './lib/response';
import { getCredentials } from './auth';

// const s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: { Bucket: bucket }
// });

export function getFileURL(fileLocation) {
  const { awsConfig: { Storage } } = SchemaManager;
  const baseURL = Storage.imagesCloudfront ? `${Storage.imagesCloudfront}/200` : `${Storage.bucket}.s3.${Storage.region}.amazonaws.com`;
  return `https://${baseURL}/${fileLocation}`;
}

export function getPdfURL(fileLocation) {
  const { awsConfig: { Storage } } = SchemaManager;
  const baseURL = `${Storage.bucket}.s3.${Storage.region}.amazonaws.com`;
  return `https://${baseURL}/${fileLocation}`;
}

export async function uploadFile(file, path) {
  const { awsConfig: { Storage } } = SchemaManager;
  const { region, bucket } = Storage;

  try {
    const credentials = await getCredentials();
    AWS.config.update({ credentials, region: region });

    await new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucket,
        Key: path,
        Body: file,
        ACL: 'public-read'
      }
    }).promise();
    return response(true);
  } catch (error) {
    console.log("uploadFile -> error", error)
    return response(false, error);
  }
}