import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';


import StudentData from './StudentData';
import StudentProcess from './StudentProcess';
import {
  Internship,
  Notifications,
  PastInternships,
} from './components';

import SchemaManager from '../../entities/schema';
import useGraphGet from '../../entities/hooks/useGraphGet';
import { saveEntity } from '../../entities/services/entities';
import addDocpatterns from '../../entities/services/addDocpatterns';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// eslint-disable-next-line react/no-multi-comp
const UserEdit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { fields } = useMemo(() => SchemaManager.getEntity('student'), [id]);
  const { loading, error, data: userData, rawData: rawUser } = useGraphGet('student', id, fields);

  const [user, setUser] = useState({});
  const [data, setData] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [newStatus, setNewStatus] = useState('');

  const { statusHistory = [] } = user;

  useEffect(() => {
    if (rawUser) setUser(rawUser);
    if (userData) setData(userData);
  }, [userData, id, rawUser]);

  const onUpdate = (newData) => setUser(newData);

  const handlePushNewState = async () => {
    if (!newStatus) return;
    const newStatusHistory = [...statusHistory, { date: (new Date()), status: newStatus }];
    const newDocs = await addDocpatterns(user, newStatus);

    const { success, data } = await saveEntity({
      entity: 'student',
      values: { 
        statusHistory: newStatusHistory,
        status: newStatus,
        documents: newDocs,
        id: user.id
      },
      id: user.id
    });
    if (success) {
      addDocpatterns(data, newStatus);
      onUpdate(data);
    }
  };

  if (loading) return null;
 
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} aria-label="simple tabs example">
          <Tab label="Datos estudiante" {...a11yProps(0)} />
          <Tab label="Proceso" {...a11yProps(1)} />
          <Tab label="Práctica actual" {...a11yProps(2)} />
          <Tab label="Prácticas pasadas" {...a11yProps(3)} />
          <Tab label="Notificaciones" {...a11yProps(4)} />
        </Tabs>
      </AppBar>

      <TabPanel value={tabIndex} index={0}>
        <StudentData
          user={user}
          data={data}
          loading={loading}
          error={error}
          rawUser={rawUser}
          onUpdate={onUpdate}
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <StudentProcess
          user={user}
          loading={loading}
          onUpdate={onUpdate}
          handlePushNewState={handlePushNewState}
          setNewStatus={setNewStatus}
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <Internship loading={loading} user={user} onUpdate={onUpdate} />
      </TabPanel>

      <TabPanel value={tabIndex} index={3}>
        <PastInternships user={user} />
      </TabPanel>

      <TabPanel value={tabIndex} index={4}>
        <Notifications loading={loading} user={user} onUpdate={onUpdate} />
      </TabPanel>
    </div>
  );
};

export default UserEdit;
