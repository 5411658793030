import React, { useState, useEffect } from 'react';
import { Fab, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { uploadFile, getFileURL } from '../../../services/storage';
import HSpacer from '../../HSpacer';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  imgUploaded: {
    maxWidth: '98%'
  }
}));

const FormVideoUploadField = ({ errors, field, register, rules, setValue, watch, entity, values }) => {
  const [loading, setLoading] = useState(false)
  const { name, label } = field
  const entityURL = entity.toLowerCase();
  const classes = useStyles();
  
  useEffect(() => {register({ name }, rules)}, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const timestamp = +new Date();
      const filename = `${timestamp}-${file.name}`.replace(/\s/g, '-').replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi,'-');
      const fileLocation = `${entityURL}/${name}/${filename}`
      const { success } = await uploadFile(file, fileLocation);
      setValue(name, success ? filename : '');
      setLoading(false);
    }
  }

  const handleFileRemove = () => {
    setValue(name, '');
  }

  const value = watch(name, '');
  const fileLocation = getFileURL(`${entityURL}/${name}/${value}`);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={11}>
        {loading && <Typography>Loading...</Typography>}
        {value && <video className={classes.imgUploaded} src={fileLocation} controls/>}
      </Grid>
      <Grid item xs={1}>
        <div>
          <input 
            accept="video/*"
            className={classes.input}
            id={`${name}-image-upload`}
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor={`${name}-image-upload`}>
            <Fab component="span" color="primary">
              <AddPhotoAlternateIcon />
            </Fab>
          </label>
        </div>
        <HSpacer />
        <div>
          {value && (
            <Fab component="span" color="secondary" onClick={handleFileRemove}>
              <DeleteForeverIcon />
            </Fab>
          )}
        </div>
      </Grid>
    </Grid>
  )
}
 
export default FormVideoUploadField;