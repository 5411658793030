import Auth from '@aws-amplify/auth';
import API, { graphqlOperation } from '@aws-amplify/api';
import AWS from 'aws-sdk';

import { response } from './lib/response';
import SchemaManager from '../schema';

function getUserWithGroup(user){
  const group = user?.signInUserSession?.accessToken?.payload && user?.signInUserSession?.accessToken?.payload['cognito:groups']
    ? user?.signInUserSession?.accessToken?.payload['cognito:groups'][0] 
    : '';
  return { ...user, group };
}

export async function login(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    return response(true, '', user.challengeName === 'NEW_PASSWORD_REQUIRED' ? user : getUserWithGroup(user) );
  } catch (error) {
    console.log("login -> error", error)
    return response(false, error.code);
  }
}

export async function setNewPassword(user, password) {
  try {
    const result = await Auth.completeNewPassword(user, password);
    // console.log("setNewPassword -> result", result)
    return response(true);
  } catch (error) {
    console.log("login -> error", error)
    return response(false, error.code);
  }
}

export async function getCurrentUser() {
  try {
    const user =  await Auth.currentAuthenticatedUser();
    return response(true, '', getUserWithGroup(user));
  } catch (error) {
    return response(false, error.code);
  }
}

export async function signout(){
  try {
    await Auth.signOut();
    return response(true);
  } catch (error) {
    return response(false, error.code);
  }
}

export async function getCredentials() {
  const session = await Auth.currentSession();
  const { awsConfig } = SchemaManager;
  const loginPool = `cognito-idp.${awsConfig.Auth.region}.amazonaws.com/${awsConfig.Auth.userPoolId}`;

  return new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsConfig.Auth.identityPoolId,
    Logins: {
      [loginPool]: session.getIdToken().getJwtToken()
    }
  }, { region: awsConfig.Auth.region });
}

export async function resetUserPassword({ userId, newPassword }) {
  const { mutations } = SchemaManager;
  try {
    const { data } = await API.graphql(graphqlOperation(mutations.setUserPassword, { userId, newPassword }));
    return response(data.setUserPassword);
  } catch (error) {
    console.log("🚀 ~ file: auth.js ~ line 73 ~ resetUserPassword ~ error", error)
    return response(false);
  }
}