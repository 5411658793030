import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Button, Fab, Modal, } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EditIcon from '@material-ui/icons/Edit';
import ReactPlayer from 'react-player';

import GenericTable from '../../table/GenericTable';
import FormField from '../FormField';
import SchemaManager from '../../../schema';
import HSpacer from '../../HSpacer';
import { getFileURL } from '../../../services/storage';
import SubObjectForm from '../../SubObjectForm';

const useStyles = makeStyles(theme => ({
  subEntityForm: {
    width: '100%'
  },
  contentBlock: {
    position: 'relative',
    padding: '20px',
    borderBottom: '1px solid white'
  },
  contentBlockTitle:{
    fontSize: 20,
    fontWeight: 700,
  },
  contentBlockButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex'
  },
  errorMessage: {
    color: 'red',
    marginRight: '20px'
  },
  blockContent: {
    display: 'flex',
    marginTop: '20px',
  },
  blockTitle: {
    fontSize: 16,
    fontWeight: 700,
    flexBasis: '100px'
  },
  blockValue: {
    flex: 1
  },
  imgUploaded: {
    maxWidth: '200px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none',
    maxHeight: '90vh',
  },
}));

const BlockTitleValue = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.blockContent}>
      <Typography className={classes.blockTitle}>{title}</Typography>
      <div className={classes.blockValue}>{value}</div>
    </div>
  )
}

const FormSubObjectListField = ({ entity, field, id, register, watch, setValue, rules }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [editingData, setEditingData] = useState(null);
  const classes = useStyles();
  const formRef = useRef();
  const { fields, tableFields } = SchemaManager.getEntity(field.relatedEntity);
  const data = watch(field.name, []);
  const entityURL = entity.toLowerCase();

  useEffect(() => {register({ name: field.name }, rules)}, []);

  const { handleSubmit, reset, errors, setValue: nestedSetValue, control, register: nestedRegister, getValues, watch: nestedWatch } = useForm();

  const handleFormSubmit = (values, event) => {
    if (formRef.current !== event.target) return null;
    setErrorMessage('');

    const validation = SchemaManager.getSubobjectValidations(entity, field.name);
    if (validation && !validation.validate(data, values)) {
      setErrorMessage(validation.errorMessage);
      return;
    }

    const newData = [...data, values];
    setValue(field.name, newData);
    // reset();
    fields.forEach(f => nestedSetValue({[f.name]: f.type === 'string' ? '' : null}))
    // if (field.name === 'content') {
    //   nestedSetValue('type', null);
    // }
  }

  const handleRemoveItem = (index) => {
    const newData = data.filter((d, i) => i !== index);
    setValue(field.name, newData);
  }

  const handleMoveContentBlock = (currentIndex, newIndex) => {
    const newData = [...data];
    const movingItem = data[currentIndex];
    newData.splice(currentIndex, 1);
    newData.splice(newIndex, 0, movingItem);
    setValue(field.name, newData);
  }

  const handleSaveSubObject = (values, index) => {
    const newData = [...data];
    newData[index] = { ...values }
    setValue(field.name, newData);
    setEditingData(null);
  }

  const genericTableEntity = entity === 'internship' ? entity : field.relatedEntity;
  
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">{field.label}</Typography>
        </Grid>
        <HSpacer />
        <Grid item xs={12}>
          {field.name === 'content'
            ? (
              data.map((block, index) => (
                <div className={classes.contentBlock} key={`${block.type}-${index}`}>
                  <Typography className={classes.contentBlockTitle}>{SchemaManager.getEnumLabel(block.type)}</Typography>
                  {block.title && <Typography>Título: {block.title}</Typography>}
                  {block.videoSubtype && <Typography>Video subtype: {block.videoSubtype}</Typography>}
                  {block.url && (
                    <>
                      {block.type === 'video_embed' && (<ReactPlayer width="640px" height="360px" url={block.url} controls/>)}
                      {block.type === 'video_link' && (<div><a href={block.url} target="_blank" rel="noopener noreferrer">Link</a></div>)}
                      {block.type === 'link' && (
                        <div>
                          <a href={block.url} target="_blank" rel="noopener noreferrer">
                            <Typography><span dangerouslySetInnerHTML={{__html: block.text}} /></Typography>
                          </a>
                        </div>
                      )}
                      {block.type === 'tweet' && (<div><a href={block.url} target="_blank" rel="noopener noreferrer">See Tweet</a></div>)}
                    </>
                  )}
                  {block.bgColor && (
                    <BlockTitleValue
                      title="BG Color"
                      value={<Typography>{block.bgColor}</Typography>}
                    />
                  )}
                  {block.caption && (
                    <BlockTitleValue
                      title="Caption"
                      value={<Typography>{block.caption}</Typography>}
                    />
                  )}
                  {block.subcaption && (
                    <BlockTitleValue
                      title="Caption 2"
                      value={<Typography>{block.subcaption}</Typography>}
                    />
                  )}
                  {block.challenge && (
                    <BlockTitleValue
                      title="Challenge"
                      value={<Typography><span dangerouslySetInnerHTML={{__html: block.challenge}} /></Typography>}
                    />
                  )}
                  {block.context && (
                    <BlockTitleValue
                      title="Context"
                      value={<Typography><span dangerouslySetInnerHTML={{__html: block.context}} /></Typography>}
                    />
                  )}
                  {block.goal && (
                    <BlockTitleValue
                      title="Goal"
                      value={<Typography><span dangerouslySetInnerHTML={{__html: block.goal}} /></Typography>}
                    />
                  )}
                  {block.text && (
                    <>
                      {block.type !== 'link' && <Typography><span dangerouslySetInnerHTML={{__html: block.text}} /></Typography>}
                    </>
                  )}
                  {block.blockListImage && block.blockListImage.length > 0 && (
                    block.blockListImage.map(img => (
                      <img key={img} className={classes.imgUploaded} src={getFileURL(`${entityURL}/blockListImage/${img}`)} />
                    ))
                  )}
                  {block.blockImage && (
                    <img key={block.blockImage} className={classes.imgUploaded} src={getFileURL(`${entityURL}/blockImage/${block.blockImage}`)} />
                  )}
                  <div className={classes.contentBlockButtons}>
                    {index > 0 && (
                      <Fab component="span" color="secondary" onClick={() => handleMoveContentBlock(index, index - 1)}>
                        <ArrowUpwardIcon />
                      </Fab>
                    )}
                    {index < data.length - 1 && (
                      <Fab component="span" color="secondary" onClick={() => handleMoveContentBlock(index, index + 1)}>
                        <ArrowDownwardIcon />
                      </Fab>
                    )}
                    <Fab component="span" color="secondary" onClick={() => setEditingData({ data: block, index })}>
                      <EditIcon />
                    </Fab>
                    <Fab component="span" color="secondary" onClick={() => handleRemoveItem(index)}>
                      <DeleteForeverIcon />
                    </Fab>
                  </div>
                </div>
              ))
            )
            : (
              <GenericTable
                data={data || []}
                entity={genericTableEntity}
                onEditData={setEditingData}
                onRemoveItem={handleRemoveItem}
                tableFields={tableFields} 
              />
            )
          }  
        </Grid>
        <HSpacer />
        <HSpacer />
        <Grid item xs={12}>
          <Typography>Añadir nuevo</Typography>
        </Grid>
        <HSpacer />
        <form ref={formRef} className={classes.subEntityForm} onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid container item xs={12} spacing={4}>
            {fields.map(subEntityField => {
              const fieldProps = {
                entity,
                field: subEntityField,
                control,
                getValues,
                register: nestedRegister,
                setValue: nestedSetValue,
                subObject: field.name,
                errors: errors[field.name],
                rules: { required: field.required },
                watch: nestedWatch,
              }
              return <FormField key={subEntityField.name} id={id} {...fieldProps} />
            })}
          </Grid>
          <HSpacer />
          <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
            <Typography align="right" className={classes.errorMessage}>{errorMessage}</Typography>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </form>
      </Grid>
      <Modal
        className={classes.modal}
        open={!!editingData}
        onClose={() => setEditingData(null)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <SubObjectForm
            allData={data}
            onCancel={() => setEditingData(null)}
            onSave={handleSaveSubObject}
            data={editingData?.data}
            dataIndex={editingData?.index}
            fields={fields}
            entity={entity}
            subObject={field}
            id={id}
          />
        </div>
      </Modal>
    </>
  )
}
 
export default FormSubObjectListField;
