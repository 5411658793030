import React from 'react';
import { makeStyles } from '@material-ui/styles';

import GenericEditForm from '../../entities/components/GenericEditForm';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const GenericEdit = ({ entity }) => {  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GenericEditForm entity={entity} />
    </div>
  );
};

export default GenericEdit;
