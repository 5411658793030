const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#6cb3cd',
    main: '#6cb3cd',
    light: '#e7f8ff'
  },
  secondary: {
    contrastText: '#6cb3cd',
    dark: white,
    main: white,
    light: white
  },
  success: {
    contrastText: white,
    dark: '#48ba6c',
    main: '#48ba6c',
    light: '#48ba6c'
  },
  info: {
    contrastText: white,
    dark: '#757575',
    main: '#757575',
    light: '#757575'
  },
  warning: {
    contrastText: white,
    dark: '#fbb827',
    main: '#fbb827',
    light: '#fbb827'
  },
  error: {
    contrastText: white,
    dark: '#f21c54',
    main: '#f21c54',
    light: '#f21c54'
  },
  text: {
    primary: '#10293d',
    secondary: '#10293d',
    link: '#10293d'
  },
  background: {
    default: '#ececec',
    paper: white
  },
  icon: '#10293d',
  divider: '#10293d'
};
