import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';
import useGraphList from '../../entities/hooks/useGraphList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [userFilters, setUserFilters] = useState({});

  const {
    canOrder,
    data,
    error,
    handleNextPage,
    handlePrevPage,
    handleRefreshData,
    hasNextPage,
    loading,
    page,
    relatedEntities,
    setFilters,
    setLoading,
    tableFields,
  } = useGraphList({ entity: 'Student', related: true });

  useEffect(() => {
    const orderedData = data.sort((a, b) => {
      if(canOrder) return a.order - b.order;
      return +new Date(b.createdAt) - +new Date(a.createdAt);
    });
    setTableData(orderedData);
  }, [data, canOrder]);

  useEffect(() => {
    if (userFilters) setFilters(userFilters);
  }, [userFilters]);

  return (
    <div className={classes.root}>
      <UsersToolbar
        filters={userFilters}
        setFilters={setUserFilters}
        tableFields={tableFields}
      />
      <div className={classes.content}>
        <UsersTable
          error={error}
          hasNextPage={hasNextPage}
          loading={loading}
          onNextPage={handleNextPage}
          onPrevPage={handlePrevPage}
          onRefreshData={handleRefreshData}
          page={page}
          relatedEntities={relatedEntities}
          setLoading={setLoading}
          tableFields={tableFields}
          users={tableData}
        />
      </div>
    </div>
  );
};

export default UserList;
