import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import EntityTable from '../../entities/components/table/EntityTable';
import { GenericToolbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const GenericList = ({ entity }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(null);
  const [tableFields, setTableFields] = useState([]);
  // console.log("🚀 ~ file: GenericList.js ~ line 20 ~ GenericList ~ filters", filters)

  return (
    <div className={classes.root}>
      <GenericToolbar
        entity={entity}
        filters={filters}
        setFilters={setFilters}
        tableFields={tableFields}
      />
      <div className={classes.content}>
        <EntityTable
          entity={entity}
          filters={filters}
          setTableFields={setTableFields}
        />
      </div>
    </div>
  );
};

GenericList.propTypes = {
  entity: PropTypes.string
};

export default GenericList;
