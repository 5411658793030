import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import set from 'lodash/set';
import { ExcelRenderer } from 'react-excel-renderer'

import SchemaManager from '../../../../entities/schema';
import { saveEntity } from '../../../services/entities';

const sleep = (n) => new Promise(resolve => setTimeout(resolve, n))

const useStyles = makeStyles(theme => ({
  importButton: {
    marginRight: theme.spacing(1)
  }
}));


const TableImport = ({ entity }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleFileUploadImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const { fields } = SchemaManager.getEntity(entity); 
      const dictionary = fields.reduce((acc, field) => {
        const { label, name, type, subfields } = field;

        if (type === 'translation' && subfields) {
          const tempObj = name.includes('richTextObsLang') || name.includes('conditionsLang')
            ? { [label]: name }
            : Object.fromEntries(
              subfields.map((subfield) => {
                const subfieldName = subfield.name;
                const subfieldLabel = label + (subfieldName === 'en' ? ' Inglés' : ' Español');
                return [subfieldLabel, `${name}.${subfieldName}`];
              })
            );

          return { ...acc, ...tempObj };
        }

        return { ...acc, [label]: name };
      }, {});

      ExcelRenderer(file, async (err, resp) => {
        if (err) {
          console.log('Error:', err);
        } else {
          const esHeader = resp.rows[0];
          const header = esHeader.map((item) => {
            const name = dictionary[item];
            if (item.includes('richTextObsLang') || item.includes('conditionsLang')) return `${name}.es`; 
            if (item.includes('datesOpen')) return item;
            if (name === 'country') return `${entity.toLowerCase()}CountryId`;
            return name;
          });
          const objsImport = resp.rows
            .map((row, index) => {
              if (index === 0) return;
              if (row.length === 0) return null;

              const obj = header
                .reduce((objAcc, field, i) => {
                  const fieldValueString = typeof row[i] === 'string' ? row[i].trim() : row[i];
                  const fieldValue = typeof fieldValueString === 'string' && ['TRUE', 'YES', 'SI', 'VERDADERO', 'FALSO', 'FALSE', 'NO'].includes(fieldValueString.toUpperCase())
                    ? (['TRUE', 'YES', 'SI', 'VERDADERO'].includes(fieldValueString.toUpperCase()) ? true : false)
                    : fieldValueString

                  const finalValue = fieldValue === '' || fieldValue === undefined ? null : fieldValue;

                  const fieldName = field ? field.replace(/"/g, '').trim() : null;
                  set(objAcc, fieldName, finalValue);
                  return { ...objAcc }
                }, {});

              obj.datesOpen = !obj.datesOpen
                ? [{ dateIni: '01-01', dateEnd: '12-31' }]
                : obj.datesOpen
                  .map(({ dateIni, dateEnd }, i) => i > 0 && (!dateIni || !dateEnd)
                    ? null
                    : ({
                      dateIni: dateIni ? dateIni : '01-01',
                      dateEnd: dateEnd ? dateEnd : '12-31'
                    })
                  )
                  .filter(obj => !!obj);
              return obj;
            })
            .filter(obj => !!obj);

          for (let i = 0; i < objsImport.length; i += 1) {
            const obj = objsImport[i];
            const { success } = await saveEntity({ entity, values: obj, id: obj.id })
            if (!success) setErrors((prev) => [...prev, `Hay un error con el item ${obj.id}, porfavor revisa los campos que esten bien`]);
            await sleep(20);
            console.log('IMPORTED: ', i + 1, ' out of: ', objsImport.length);
          }
        }
        setLoading(false);
        setSuccess(true);
      });
    }
  }

  return (
    <>
      {success && errors.length === 0 && <div style={{marginRight: '30px'}}>Datos importados con éxito</div>}
      {success && errors.length > 0 && <div style={{marginRight: '30px'}}>Todos los datos importados con éxito excepto los que tienen errores</div>}
      {errors && ( 
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {errors.map((error) => ( <div style={{marginRight: '30px'}}>{error}</div>))}
        </div>
      )}
      <Button className={classes.importButton}>
        {
          loading ? (
            <>
              <input
                style={{ display: 'none' }}
                id="import-csv-upload"
                type="file"
                onChange={() => { }}
                disabled
              />
              <label htmlFor="import-csv-upload">...importando</label>
            </>
          ) : (
            <>
              <input
                style={{ display: 'none' }}
                id="import-csv-upload"
                type="file"
                onChange={handleFileUploadImport}
              />
              <label htmlFor="import-csv-upload">Importar</label>
            </>
          )
        }
      </Button>
    </>
  )
}

export default TableImport;