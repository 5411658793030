import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import GenericTable from '../GenericTable';
import useGraphList from '../../../hooks/useGraphList';

const EntityTable = ({ entity, filters, setTableFields }) => {
  const [tableData, setTableData] = useState([]);
  const {
    canOrder,
    data,
    error,
    handleNextPage,
    handlePrevPage,
    handleRefreshData,
    hasNextPage,
    loading,
    page,
    relatedEntities,
    setFilters,
    setLoading,
    tableFields,
  } = useGraphList({ entity, related: true });

  useEffect(() => {
    const orderedData = data.sort((a, b) => {
      if(canOrder) return a.order - b.order;
      return +new Date(b.createdAt) - +new Date(a.createdAt);
    });
    setTableData(orderedData);
  }, [data, canOrder]);

  useEffect(() => {
    if (tableFields.length) setTableFields(tableFields)
  }, [tableFields]);

  useEffect(() => {
    if (filters) setFilters(filters);
  }, [filters]);

  return (
    <GenericTable
      canOrder={canOrder}
      data={tableData}
      entity={entity}
      error={error}
      hasNextPage={hasNextPage}
      loading={loading}
      onNextPage={handleNextPage}
      onPrevPage={handlePrevPage}
      onRefreshData={handleRefreshData}
      page={page}
      relatedEntities={relatedEntities}
      setLoading={setLoading}
      tableFields={tableFields} 
    />
  );
};

EntityTable.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.string.isRequired,
  filters: PropTypes.shape(),
  setTableFields: PropTypes.func,
};

export default EntityTable;
