import React, { useEffect } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const FormBooleanField = ({ errors, register, field, rules, setValue, watch }) => {
  const { label, name } = field
  const errorMessage = errors && errors.type && ERROR_MESSAGES[errors.type];

  useEffect(() => {
    register({ name }, rules)
  }, []);

  const checked = watch(name, false);

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={checked}
          onChange={() => setValue(name, !checked)}
          name={name}
        />}
      label={label || name}
    />
  )
}
 
export default FormBooleanField;