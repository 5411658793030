import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import UpDownData from '../UpDownData';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
}));


const ByProrroga = props => {
  const { periodLabel, studentData, loading } = props;
  const classes = useStyles();

  const [internshipCount, setInternshipCount] = useState(0);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    if (!studentData) return;
    const { data, previousData } = studentData;
    let newInternships = 0;
    let previousInternships = 0;

    data.forEach((item) => {
      const { internship, pastInternships } = item;
      if (internship && internship.isExtension) newInternships += 1;
      if (pastInternships) {
        const list = pastInternships.filter((el) => el.isExtension === true);
        if (list.length > 0) newInternships += list.length;
      }
    });

    previousData.forEach((item) => {
      const { internship, pastInternships } = item;
      if (internship && internship.isExtension) newInternships += 1;
      if (pastInternships) {
        const list = pastInternships.filter((el) => el.isExtension === true);
        if (list.length > 0) newInternships += list.length;
      }
    });

    const difference = (newInternships - previousInternships) * 100 / (newInternships || 1);
    setDifference(Number(difference.toFixed(0)));
    setInternshipCount(newInternships);
  }, [studentData]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              PRÓRROGAS
            </Typography>
            <Typography variant="h3">{loading ? '' : internshipCount}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AddToPhotosIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {loading ? '' : <UpDownData difference={difference} />}
          <Typography
            className={classes.caption}
            variant="caption"
          >
            {periodLabel} anterior
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

ByProrroga.propTypes = {
  loading: PropTypes.bool.isRequired,
  periodLabel: PropTypes.string.isRequired,
  studentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
};

export default ByProrroga;
