import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

import UpDownData from '../UpDownData';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
}));


const TotalUsers = (props) => {
  const { periodLabel, studentData, loading } = props;
  const classes = useStyles();

  const [amount, setAmount] = useState(0);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    const { data, previousData } = studentData;
    const newAmount = data.length;
    const previousAmount = previousData.length;
    const difference = (newAmount - previousAmount) * 100 / (newAmount || 1);
    setAmount(newAmount);
    setDifference(Number(difference.toFixed(0)));
  }, [studentData]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              USUARIOS
            </Typography>
            <Typography variant="h3">{loading ? '' : amount}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {loading ? '' : <UpDownData difference={difference} />}
          <Typography
            className={classes.caption}
            variant="caption"
          >
            {periodLabel} anterior
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

TotalUsers.propTypes = {
  loading: PropTypes.bool.isRequired,
  periodLabel: PropTypes.string.isRequired,
  studentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
};

export default TotalUsers;
