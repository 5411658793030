import React, { useEffect, useState } from 'react';
import _get from 'lodash.get';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import useGraphList from '../../../hooks/useGraphList';


const FilterSelectEntity = ({ field, filters, setFilters }) => {
  const { label, filter, filter: { schemaField } } = field;
  const ALL_OPTIONS = { value: 'all', label: 'Todo' };

  const [selectList, setSelectList] = useState([]);

  const { data, loading } = useGraphList({ entity: filter.entity, getAll: true, sorted: true });

  useEffect(() => {
    if (!data) return;
    const newSelectList = data.map((el) => {
      const value = el[filter.value];
      let label = '';
      if (Array.isArray(filter?.label)) {
        filter.label.forEach((item) => {
          label += _get(el, `${item}`, '');
          label += ' ';
        });
      } else label = _get(el, `${filter.label}`, '');
      return ({ value, label });
    });
    setSelectList([ALL_OPTIONS, ...newSelectList]);
  }, [data]);

  const onChange = (e) => {
    const newFilters = { ...filters, [schemaField]: {contains: e.target.value} };
    setFilters(newFilters);
  }

  const getFilterValue = () => {
    if (!filters || !filters[schemaField]) return 'all';
    return filters[schemaField].contains;
  }

  return (
    <TextField
      label={label}
      id="name"
      select
      value={getFilterValue()}
      onChange={onChange}
      fullWidth
      // className={classes.root}
      // InputProps={{ className: classes.label, disableUnderline: true }}
    >
      {
        selectList.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))
      }
    </TextField>
  );
};

export default FilterSelectEntity;
