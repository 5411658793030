import React from 'react';

import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '0 0 0 25px',
    fontWeight: 500,
    '&::placeholder': {
      fontWeight: 500,
      opacity: 1,
    },
  },
}));


const FilterString = ({ field, filters, setFilters }) => {
  const { label, filter: { schemaField } } = field;
  const classes = useStyles();
  const value = filters[schemaField] || '';

  const onChange = (e) => {
    const newFilters = { ...filters, [schemaField]: e.target.value };
    setFilters(newFilters);
  }

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={label}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default FilterString;
