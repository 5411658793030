import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


const FilterSelectEnum = ({ field, filters, setFilters }) => {
  const { label, filter, filter: { schemaField } } = field;
  const ALL_OPTIONS = { value: 'all', label: 'Todo' };

  const list = filter?.type === 'boolean' ? [{ value: true, label: 'Sí' }, { value: false, label: 'NO' }] : field.options;
  const selectList = [ALL_OPTIONS, ...list];

  const onChange = (e) => {
    const newValue = e.target.value;
    const newFilterValue = filter?.type === 'boolean' ? { [schemaField]: { eq: newValue } } : { [schemaField]: { contains: newValue } };
    const newFilters = { ...filters, ...newFilterValue };
    setFilters(newFilters);
  }

  const getFilterValue = () => {
    if (!filters || !filters[schemaField]) return 'all';
    const key = Object.keys(filters[schemaField])[0];
    return filters[schemaField][key];
  }

  return (
    <TextField
      label={label}
      id="name"
      select
      value={getFilterValue()}
      onChange={onChange}
      fullWidth
      // className={classes.root}
      // InputProps={{ className: classes.label, disableUnderline: true }}
    >
      {
        selectList.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))
      }
    </TextField>
  );
};

export default FilterSelectEnum;
