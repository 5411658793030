import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { PaymentsToolbar, PaymentsTable } from './components';
import { getPayments } from '../../entities/services/entitiesCustom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const PaymentList = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});

  const fetchData = async () => {
    const limit = 100000;
    const { success, msg, data, paginationToken } = await getPayments(paginationTokens[page], limit);
    if (success) {
      setTableData(data.sort((a,b) => (a.number < b.number) ? 1 : ((b.number < a.number) ? -1 : 0)));

      const currentPaginationTokens = [...paginationTokens];
      currentPaginationTokens[page + 1] = paginationToken;
      setPaginationTokens(currentPaginationTokens);
    } else {
      setError(msg);
    }
    setLoading(false);
  }
  

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const handleNextPage = () => setPage(page + 1);
  const handlePrevPage = () => setPage(page - 1);
  
  const hasNextPage = !!paginationTokens[page + 1];

  return (
    <div className={classes.root}>
      <PaymentsToolbar
        filters={filters}
        setFilters={setFilters}
      />
      <div className={classes.content}>
        <PaymentsTable
          error={error}
          hasNextPage={hasNextPage}
          loading={loading}
          onNextPage={handleNextPage}
          onPrevPage={handlePrevPage}
          onRefreshData={fetchData}
          page={page}
          setLoading={setLoading}
          users={tableData}
        />
      </div>
    </div>
  );
};

export default PaymentList;
