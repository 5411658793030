import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  Budget,
  AmountByResidence,
  AmountByService,
  ByCategory,
  ByCountry,
  ByDevice,
  ByProrroga,
  ByResidence,
  ByService,
  CalendarPayments,
  CalendarStudents,
  DatesFilters,
  TotalUsers,
} from './components';

import { getDashboardStudentData, getDashboardPaymentData } from '../../entities/services/entitiesCustom';
import { getFilterDates, checkMonthsNumber } from './helpers/getFilterDates';
import ErrorBoundary from '../../components/ErrorBoundary';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const periodDictionary = {
  day: 'Día',
  week: 'Semana',
  month: 'Mes',
  year: 'Año',
  custom: 'Personalizado',
};


const Dashboard = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState('day');
  const [dates, setDates] = useState({ dateInit: null, dateEnd: null });
  const [filterdates, setFilterDates] = useState({ fromDate: null, toDate: null, fromPreviousDate: null, toPreviousDate: null });
  const [studentData, setStudentData] = useState({ data: [], previousData: [] });
  const [paymentData, setPaymentData] = useState({ data: [], previousData: []});
  const [error, setError] = useState('');

  const fetchStudentData = async () => {
    const limit = 500;
    const { success, msg, data } = await getDashboardStudentData({ dates: filterdates, limit });
    const previousResponse = await getDashboardStudentData({ dates: { fromDate: filterdates.fromPreviousDate, toDate: filterdates.toPreviousDate }, limit });
    const { success: previousSuccess, msg: previousMsg, data: previousData } = previousResponse;
    if (success && previousSuccess) setStudentData({ data, previousData });
    else setError(msg || previousMsg);
    setLoading(false);
  };

  const fetchPaymentsData = async () => {
    const limit = 100000;
    const { success, msg, data } = await getDashboardPaymentData({ dates: filterdates, limit });
    const previousResponse = await getDashboardPaymentData({ dates: { fromDate: filterdates.fromPreviousDate, toDate: filterdates.toPreviousDate }, limit });
    const { success: previousSuccess, msg: previousMsg, data: previousData } = previousResponse;
    if (success && previousSuccess) setPaymentData({ data, previousData });
    else setError(msg || previousMsg);
    setLoading(false);
  };

  useEffect(() => {
    const newFilterDates = getFilterDates(dates, period);
    setFilterDates(newFilterDates);
  }, [dates, period]);

  useEffect(() => {
    if (filterdates.fromDate === null || filterdates.toDate === null) return;
    setLoading(true);
    fetchStudentData();
    fetchPaymentsData();
  }, [filterdates]);

  const periodLabel = periodDictionary[period];
  const monthsCount = checkMonthsNumber(filterdates);
  const isSeveralMonths = period === 'year' ? true : monthsCount > 1;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>

        <ErrorBoundary>
          <DatesFilters period={period} setPeriod={setPeriod} dates={dates} setDates={setDates} />
        </ErrorBoundary>

        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ErrorBoundary>
            <Budget
              filterdates={filterdates}
              periodLabel={periodLabel}
              paymentData={paymentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ErrorBoundary>
            <TotalUsers
              filterdates={filterdates}
              periodLabel={periodLabel}
              studentData={studentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ErrorBoundary>
            <ByProrroga
              filterdates={filterdates}
              periodLabel={periodLabel}
              studentData={studentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <ErrorBoundary>
          {
            isSeveralMonths && (
              <>
                <Grid item xs={12}>
                  <CalendarStudents
                    filterdates={filterdates}
                    periodLabel={periodLabel}
                    studentData={studentData}
                    loading={loading}
                    monthsCount={monthsCount}
                  />
                </Grid>
      
                <Grid item xs={12}>
                  <CalendarPayments
                    filterdates={filterdates}
                    periodLabel={periodLabel}
                    paymentData={paymentData}
                    loading={loading}
                    monthsCount={monthsCount}
                  />
                </Grid>
              </>
            )
          }
        </ErrorBoundary>

        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <ErrorBoundary>
            <ByDevice
              filterdates={filterdates}
              periodLabel={periodLabel}
              studentData={studentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item lg={8} sm={12} xl={8} xs={12}>
          <ErrorBoundary>
            <ByCountry
              filterdates={filterdates}
              periodLabel={periodLabel}
              studentData={studentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={12} />

        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <ErrorBoundary>
            <ByResidence
              filterdates={filterdates}
              periodLabel={periodLabel}
              studentData={studentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <ErrorBoundary>
            <AmountByResidence
              filterdates={filterdates}
              periodLabel={periodLabel}
              paymentData={paymentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={12} />

        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <ErrorBoundary>
            <ByService
              filterdates={filterdates}
              periodLabel={periodLabel}
              studentData={studentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <ErrorBoundary>
            <AmountByService
              filterdates={filterdates}
              periodLabel={periodLabel}
              paymentData={paymentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={12}>
          <ErrorBoundary>
            <ByCategory
              filterdates={filterdates}
              periodLabel={periodLabel}
              studentData={studentData}
              loading={loading}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
