import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    companyName: 'Majestic Hotel & Spa Barcelona 5* GL',
    category: 'Food & Beverage',
    city: 'Barcelona',
    contact: 'Pedro Martins',
    phone: '+34936569972',
    email: 'food_beverage@majestic.com',
    dates: 'all year',
    benefitEconomy: 300,
    benefitMeal: '2 meals'
  },
  {
    id: uuid(),
    companyName: 'Majestic Hotel & Spa Barcelona 5* GL',
    category: 'Reception',
    city: 'Barcelona',
    contact: 'Laura Roure',
    phone: '+34936569972',
    email: 'reception@majestic.com',
    dates: 'mayo - octubre',
    benefitEconomy: 300,
    benefitMeal: '2 meals'
  },
  {
    id: uuid(),
    companyName: 'Majestic Hotel & Spa Barcelona 5* GL',
    category: 'Food & Beverage',
    city: 'Barcelona',
    contact: 'Pedro Martins',
    phone: '+34936569972',
    email: 'food_beverage@majestic.com',
    dates: 'all year',
    benefitEconomy: 300,
    benefitMeal: '2 meals'
  }
];
