import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  MenuItem,
  TextField,
} from '@material-ui/core';

import useGraphList from '../../../../entities/hooks/useGraphList';
import { saveEntity } from '../../../../entities/services/entities';


const Partner = (props) => {
  const { partner, studentId } = props;

  const { data, loading } = useGraphList({ entity: 'Partner', getAll: true, sorted: true });

  const handleOnChange = async (e) => {
    const value = e.target.value;
    const response = await saveEntity({ entity: 'student', values: { studentPartnerId: value, id: studentId }, id: studentId });
  };

  return (
    <Card>
      <CardContent>
        <TextField
          select
          variant="outlined"
          label="Partner"
          defaultValue={partner?.id || ''}
          name="partner"
          fullWidth
          onChange={handleOnChange}
        >
          {
            data.length > 0 && data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))
          }
        </TextField>
      </CardContent>
    </Card>
  );
};

Partner.propTypes = {
  partner: PropTypes.shape({}),
  studentId: PropTypes.string.isRequired,
};

Partner.defaultProps = {
  partner: {},
};

export default Partner;
