import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TableRow } from '@material-ui/core';

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && { background: 'rgb(235,235,235)' })
})

const DraggableComponent = (id, index) => (props) => (
  <Draggable draggableId={id} index={index}>
    {(provided, snapshot) => (
      <TableRow
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        {...props}
      >
        {props.children}
      </TableRow>
    )}
  </Draggable>
);

export default DraggableComponent;
