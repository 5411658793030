import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Modal,
  Grid
} from '@material-ui/core';

import SearchVacancy from './SearchVacancy';
import { saveEntity } from '../../../../entities/services/entities';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    padding: theme.spacing(6),
  },
}));

const RecomendationModal = (props) => {
  const { user, onUpdate } = props;
  const { id, recommended } = user;

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [newRecommended, setNewRecommended] = useState(recommended);

  const handleConfirm = async () => {
    const values = {
      studentRecommendedId: newRecommended.id,
      recommendedDate: (new Date()).toISOString(),
      id
    };
    const response = await saveEntity({ entity: 'student', values , id });
    const { success, msg, data } = response;
    if (success) {
      onUpdate(data);
      setIsOpen(false);
    }
  };

  const handleChange = (newValue) => {
    setNewRecommended(newValue);
  };

  return (
    <>
      <Button size="small" color="primary" onClick={() => setIsOpen(true)}>
        Recomendar nueva
      </Button>
      <Modal
        className={classes.modal}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.modalPaper}>
          <CardHeader title="Recomendar práctica" />
          <CardContent>
            <SearchVacancy
              field={{ label: 'Vacante recomendada', relatedEntity: 'Vacancy' }}
              handleChange={handleChange}
              newRecommended={newRecommended}
              fullWidth
            />
          </CardContent>

          <CardActions>
            <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  fullWidth
                  onClick={() => setIsOpen(false)}
                  size="large"
                  type="button"
                  variant="contained"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleConfirm}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </CardActions>

        </Card>
      </Modal>
    </>
  );
};

RecomendationModal.propTypes = {};

RecomendationModal.defaultProps = {};

export default RecomendationModal;
