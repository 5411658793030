/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// eslint-disable-next-line no-unused-vars
import styles from './styles.css';
import { labelsOverride } from '../../../../labelsOverride';
import { getFileURL } from '../../../../entities/services/storage';
import nexusLogo from './nexus_logo.png';

const getYearRange = (initDate, endDate) => {
  if (initDate && !endDate) return new Date(initDate).getFullYear().toString();
  if (!initDate && endDate) return new Date(endDate).getFullYear().toString();
  if (!initDate || !endDate) return '';

  const currentDate = new Date();
  const fullEndDate = new Date(endDate);
  const initYear = new Date(initDate).getFullYear();
  const gradYear = new Date(endDate).getFullYear();

  if (fullEndDate > currentDate) return `${initYear} - En curso`;

  if (initYear === gradYear) return initYear.toString();

  return `${initYear} - ${gradYear}`;
};

const CVpdf = ({ student }) => {
  const {
    name,
    lastname,
    photo,
    mobilePhone,
    nationalitiesList,
    languageExperience,
    skills,
    workExperience,
    phone,
    email,
    residence,
    currentStudy,
    prevStudies,
    otherExperience,
    digitalExperience,
  } = student;

  const photoSrc = photo ? getFileURL(photo) : '';
  const formatedSkills = skills ? skills.replaceAll('\n', '<br />') : '';

  return (
    <div className="body" id="print-cv">
      <div className="header logo">
        <div className="nexus-logo">
          <img src={nexusLogo} />
        </div>
      </div>
      <div className="header">
        <div className="title">
          {name} <br />
          {lastname}
        </div>

        <div className="photo">
          <img className="img" src={photoSrc} alt={name} />
        </div>
      </div>

      <div className="gap" />

      <div className="content">
        <div className="left">

          <div className="section">
            <div className="featured">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width="30"
                  height="30"
                >
                  <path fill="#595959" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/>
                </svg>
              </div>
              {mobilePhone || phone || '' }
            </div>
            <div className="featured">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width="30"
                  height="30"
                >
                  <path fill="#595959" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" />
                </svg>
              </div>
              {email || ''}
            </div>
            <div className="featured">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width="30"
                  height="30"
                >
                  <path fill="#595959" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                </svg>
              </div>
              {residence?.nameLang?.es || ''}
            </div>
            <div className="featured">
              <div className="icon">
                <svg
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  width="30"
                  height="30"
                >
                  <path fill="#595959" d="m213.33 119.47h85.333c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533h-85.333c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533z"/>
                  <path d="m179.2 153.6h153.6c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533h-153.6c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533z"/>
                  <path fill="#595959" d="m256 204.8c-65.877 0-119.47 53.589-119.47 119.47s53.59 119.47 119.47 119.47 119.47-53.589 119.47-119.47-53.59-119.47-119.47-119.47zm30.669 83.686c-9.02 1.007-19.115 1.647-30.669 1.647-11.563 0-21.658-0.64-30.669-1.647 6.921-34.116 22.434-54.494 30.669-63.309 8.243 8.833 23.748 29.202 30.669 63.309zm2.44 16.922c0.606 5.982 1.024 12.194 1.024 18.859s-0.418 12.868-1.016 18.842c-9.771-1.084-20.617-1.775-32.981-1.775-12.467 0-23.415 0.7-33.246 1.8-0.606-5.982-1.024-12.194-1.024-18.867 0-6.664 0.418-12.877 1.024-18.859 9.796 1.092 20.693 1.792 33.109 1.792s23.306-0.7 33.11-1.792zm-54.22-81.331c-9.481 12.919-20.702 33.289-26.377 61.858-21.973-4.343-34.142-11.017-39.765-14.993 14.489-23.612 38.186-40.977 66.142-46.865zm-81.289 100.19c0-13.38 2.654-26.138 7.347-37.862 8.141 5.385 22.272 12.203 45.056 16.538-0.708 6.767-1.203 13.79-1.203 21.325 0 7.543 0.495 14.575 1.212 21.342-22.784 4.344-36.915 11.17-45.065 16.546-4.685-11.735-7.347-24.501-7.347-37.889zm15.164 53.35c5.683-4.019 17.886-10.658 39.748-14.993 5.683 28.553 16.905 48.913 26.377 61.833-27.947-5.888-51.636-23.237-66.125-46.84zm56.576-17.553c9.045-1.015 19.183-1.664 30.797-1.664 11.494 0 21.555 0.64 30.532 1.63-6.921 34.133-22.434 54.511-30.669 63.326-8.235-8.824-23.748-29.201-30.66-63.292zm51.78 64.393c9.472-12.919 20.693-33.306 26.377-61.884 21.922 4.318 34.125 10.957 39.791 14.95-14.482 23.655-38.196 41.037-66.168 46.934zm81.28-100.19c0 13.355-2.654 26.086-7.313 37.794-8.184-5.385-22.323-12.186-45.09-16.495 0.708-6.758 1.203-13.773 1.203-21.299 0-7.535-0.503-14.558-1.212-21.316 22.75-4.318 36.907-11.119 45.09-16.495 4.668 11.707 7.322 24.447 7.322 37.811zm-54.912-38.332c-5.675-28.561-16.896-48.939-26.368-61.858 27.964 5.888 51.669 23.27 66.167 46.908-5.742 4.036-17.979 10.641-39.799 14.95z"/>
                  <path fill="#595959" d="m401.07 34.133h-8.533v-8.533c0-14.114-11.486-25.6-25.6-25.6h-273.07c-14.114 0-25.6 11.486-25.6 25.6v477.87c0 4.71 3.823 8.533 8.533 8.533h324.27c23.927 0 42.667-18.739 42.667-42.667v-392.53c-1e-3 -23.526-19.141-42.667-42.667-42.667zm25.6 435.2c0 14.353-11.238 25.6-25.6 25.6h-315.73v-469.33c0-4.702 3.831-8.533 8.533-8.533h273.07c4.71 0 8.533 3.831 8.533 8.533v8.533h-264.53c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533h290.13c13.875 0 25.6 11.725 25.6 25.6v392.53z"/>
                </svg>
              </div>
              {nationalitiesList?.length > 0 && nationalitiesList.join(', ')}
            </div>
          </div>

          <div className="section">
            <div className="sec_title">
              IDIOMAS
            </div>
            <div>
              {
                languageExperience?.length > 0 && (
                  languageExperience.map((lang) => {
                    const { languageName, speak, write, understand } = lang;
                    return (
                      <div className="featured">
                        <span className="lang">
                          {languageName}:
                        </span>
                        <span>{labelsOverride?.[speak] || ''}</span>
                      </div>
                    )
                  })
                )
              }
            </div>
          </div>

          {
            digitalExperience?.length > 0 && (
              <div className="section">
                <div className="sec_title">
                  COMPETENCIAS DIGITALES
                </div>
                <div>
                  {
                    digitalExperience.map((lang) => {
                      const { name, level } = lang;
                      return (
                        <div className="featured">
                          <span className="lang">
                            {name}:
                          </span>
                          <span>{labelsOverride?.[level] || ''}</span>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          }     
        </div>

        <div className="right">
          {
            workExperience?.length > 0 && (
              <>
                <div className="sec_title">
                  EXPERIENCIA PROFESIONAL
                </div>
                {
                  workExperience.map((work) => {
                    const { jobName, country, city, desc, company, iniDate, endDate } = work;

                    const countryName = country?.nameLang?.es || '';
                    const dateIni = moment(iniDate).format('DD/MM/YYYY');
                    const dateEnd = moment(endDate).format('DD/MM/YYYY');
                    const descUl = desc.replaceAll('\n', '</li><li>');
                    const descList = `<li>${descUl}</li>`;

                    return (
                      <div className="section_columns">
                        <div className="section_columns-left">
                          De {dateIni} <br />
                          a {dateEnd} <br />
                          ({city} - {countryName})
                        </div>

                        <div className="section_columns-right">
                          <div className="section_columns-title">{company}</div>
                          <div className="section_columns-subtitle">{jobName}</div>
                          <div><strong>Tareas realizadas:</strong></div>
                          <ul
                            dangerouslySetInnerHTML={{
                              __html: descList
                            }}
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </>
            )
          }
         

          <div style={{ height: '20px' }} />

          <div className="sec_title">
            FORMACIÓN
          </div>

          <div className="section_columns">
            <div className="section_columns-left">
              <span className="section_columns-subtitle">
                {getYearRange(currentStudy?.iniDate, currentStudy?.endDate)}
              </span><br />
              {currentStudy?.city} - {currentStudy?.country?.nameLang?.es}
            </div>

            <div className="section_columns-right">
              <div className="section_columns-subtitle">{currentStudy?.name}</div>
              <div><strong><em>{currentStudy?.institutionName}</em></strong></div>
            </div>
          </div>

          {
            prevStudies?.length > 0 && (
              prevStudies.map((item) => {
                const { endDate, gradDate, iniDate, name, institutionName } = item;

                return (
                  <div className="section_columns">
                    <div className="section_columns-left">
                      <span className="section_columns-subtitle">
                        {getYearRange(iniDate, gradDate ||endDate)}
                      </span><br />
                    </div>

                    <div className="section_columns-right">
                      <div className="section_columns-subtitle">{name}</div>
                      <div><strong><em>{institutionName}</em></strong></div>
                    </div>
                  </div>
                )
              })
            )
          }

          <div style={{ height: '20px' }} />

          {
            formatedSkills && (
              <div>
                <div className="sec_title">
                  APTITUDES Y HABILIDADES
                </div>
                <div
                  className="featured"
                  dangerouslySetInnerHTML={{
                    __html: formatedSkills
                  }}
                />
              </div>
            )
          }  

          <div style={{ height: '40px' }} />

          {
            otherExperience && otherExperience.length > 0 && (
              <>
                <div className="sec_title">
                  OTRA FORMACIÓN Y DIPLOMAS
                </div>
                <ul>
                  {
                    otherExperience.map((exp) => <li>{exp}</li>)
                  }
                </ul>
              </>
            )
          }

          <div style={{ height: '20px' }} />

        </div>
      </div>
    </div>
  );
};

CVpdf.propTypes = {
  student: PropTypes.shape({}).isRequired,
};

export default CVpdf;
