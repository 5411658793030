import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
} from '@material-ui/core';

import FormatData from '../FormatData';
import { getNotifications } from '../../../../entities/services/entitiesCustom';
import NewNotificationModal from './NewNotificationModal';


const Notifications = (props) => {
  const { user } = props;
  const { id } = user;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const { success, msg, data } = await getNotifications(id);
    if (success) {
      setData(data.sort((a,b) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0)));
    } else setError(msg);

    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  
  if (!user) return null;

  return (
    <Card>
      <CardHeader title="Listado de notificaciones del estudiante" />
      <Divider />
      <CardContent>
        <FormatData data={data} field="notifications" onUpdate={fetchData} user={user} />
      </CardContent>
      <CardActions>
        <NewNotificationModal user={user} title="Añadir notificación" onUpdate={fetchData} />
      </CardActions>
    </Card>
  );
};

Notifications.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default Notifications;
