import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Select, FormControl, InputLabel, MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  }  
}));

const DEFAULT_OPTIONS = [];
const DEFAULT_VALUE = [];


const FormFreeStringList = ({ errors, entityFieldName, field, register, rules, setValue, watch }) => {
  const [open, setOpen] = useState(false);
  const { label, name, options } = field
  const labelId = `label-select-${name}`;
  const errorMessage = errors && errors.type && ERROR_MESSAGES[errors.type];
  const classes = useStyles();

  useEffect(() => {register({ name: entityFieldName }, rules)}, []);

  const handleAutocompleteChange = (event, value) => {
    const valuesDeduplicated = value && value.length ? value.filter((val, i) => value.indexOf(val) === i) : [];
    return setValue(entityFieldName, valuesDeduplicated);
  }
  const value = watch(entityFieldName, DEFAULT_VALUE);
  const notNullValue = value === null ? DEFAULT_VALUE : value;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Autocomplete
        freeSolo
        id={labelId}
        multiple        
        onChange={handleAutocompleteChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        options={DEFAULT_OPTIONS}
        value={notNullValue}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errors}
            fullWidth
            helperText={errorMessage}
            label={label}
            variant="outlined"
            InputProps={params.InputProps}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
      />
    </FormControl>
  )
}
 
export default FormFreeStringList;
