import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import FormField from '../formFields/FormField';
import { saveEntity, updateStudentPayments } from '../../services/entities';
import SchemaManager from '../../schema';
import useGraphGet from '../../hooks/useGraphGet';

import SetPasswordForm from './SetPasswordForm';

const useStyles = makeStyles(theme => ({
  groupCard: {
    marginBottom: theme.spacing(4)
  }
}))

const GenericEditForm = ({ entity }) => {
  const formRef = useRef();
  const history = useHistory();
  const { fields, hasOrder } = useMemo(() => SchemaManager.getEntity(entity), [entity]);
  const { id } = useParams();
  const { loading, error, data } = useGraphGet(entity, id, fields);
  const classes = useStyles();

  const { handleSubmit, errors, setValue, control, register, getValues, watch } = useForm();

  useEffect(() => {
    // console.log('IMTCHLG ~ file: GenericEditForm.js ~ line 145 ~ useEffect ~ data', data);

    if (data) setValue(data);
  }, [data, entity, id]);

  const handleFormSubmit = async (values, event) => {
    if (formRef.current !== event.target) return null;
    const { data } = await saveEntity({ entity, values, id, hasOrder });
    if (entity === 'Payment') {
      updateStudentPayments(data);
    }
  }
  const handleFormSubmitAndExit = async () => {
    history.goBack();
  }
  const handlePreview = async (event, values) => {
    event.preventDefault();
    const previewEntity = `${entity}preview`;
    const { success, data } = await saveEntity({ entity: previewEntity, values, id });
    // TODO: handle errors
    if (success) window.open(`${SchemaManager.previewBaseUrl}/${previewEntity.toLocaleLowerCase()}/${data.url}`, '_blank');
  }
  
  const handleBack = () => history.goBack();
  const isUserEntity = SchemaManager.userEntity === entity;
  const extraComponents = SchemaManager.getExtraComponents(entity);
  const hasPereview = SchemaManager.entityHasPreview(entity);
  const fieldsGrouped = SchemaManager.getFieldsGrouped(entity, fields);
  const values = getValues();
  // console.log('IMTCHLG ~ file: GenericEditForm.js ~ line 67 ~ GenericEditForm ~ values', values);

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit(handleFormSubmit)} >
        <Grid container spacing={4}>
          <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
            {hasPereview && (
              <Grid item xs={2}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={(event) => handlePreview(event, values)}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Preview
                </Button>
              </Grid>
            )}
            <Grid item xs={2}>
              <Button
                color="secondary"
                fullWidth
                onClick={handleBack}
                size="large"
                type="button"
                variant="contained"
              >
                Volver
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                fullWidth
                onClick={handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                fullWidth
                onClick={handleFormSubmitAndExit}
                size="large"
                type="submit"
                variant="contained"
              >
                Guardar y volver
              </Button>
            </Grid>
          </Grid>
          {extraComponents.length > 0 && (
            <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
              {extraComponents.map((Component, i) => <Component key={i} watch={watch} />)}
            </Grid>
          )}
          <Grid item xs={12}>
            {(loading || error) && (
              <Card>
                <CardHeader title="Edit" />
                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={8}>
                      {loading && <Typography>Cargando</Typography>}
                      {error && <Typography>{error}</Typography>}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {fieldsGrouped.map((fieldGroup, fieldGroupIndex) => (
              <Card key={`${fieldGroupIndex}-${fieldGroup.title}`} className={classes.groupCard}>
                {fieldGroup.title && (
                  <>
                    <CardHeader title={fieldGroup.title} />
                    <Divider />
                  </>
                )}
                <CardContent>
                  <Grid container item xs={12} spacing={4}>
                    {fieldGroup.fields.map(field => {
                      const defaultProps = {
                        control,
                        entity,
                        field,
                        getValues,
                        register,
                        setValue,
                        errors: errors[field.name],
                        rules: { required: field.required },
                        watch,
                      }
                      return <FormField id={id} key={field.name} {...defaultProps} />
                    })}
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </form>
      {isUserEntity && <SetPasswordForm id={id} />}
    </>
  )
}

GenericEditForm.propTypes = {
  entity: PropTypes.string.isRequired,
};

export default GenericEditForm;
