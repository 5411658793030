import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Modal,
  TextField,
} from '@material-ui/core';

import { saveEntity } from '../../../../entities/services/entities';
import { updateNotification } from '../../../../entities/services/entitiesCustom';
import FormRichTextField from '../../../../entities/components/formFields/FormRichTextField';
import FormEnumSelectField from '../../../../entities/components/formFields/FormEnumSelectField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '150px',
    marginTop: theme.spacing(2)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    padding: theme.spacing(6),
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  pdfLink: {
    display: 'block',
    margin: theme.spacing(2),
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    }
  }
}));

const NewNotificationModal = (props) => {
  const { user, notification, index = -1, title, onUpdate } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      title: notification?.title || '',
      content: notification?.content || '',
      type: notification?.type || '', // Usar una cadena para selección única
    },
  });

  useEffect(() => {
    reset({
      title: notification?.title || '',
      content: notification?.content || '',
      type: notification?.type || '',
    });
  }, [notification]);

  const contentValue = watch('content');
  const typeValue = watch('type');

  const onSubmit = async (data) => {
    const { id } = user;
    const forceCreate = !notification?.studentId;
    const newValues = { ...data };
    delete newValues.fecha;
    delete newValues.isRead;
    newValues.read = false;

    const valuesToSave = forceCreate 
      ? { ...newValues, studentId: id } 
      : { ...newValues, studentId: notification.studentId, timestamp: notification.timestamp };
    if (forceCreate) delete valuesToSave.read;

    const response = await (forceCreate 
      ? saveEntity({ entity: 'notification', values: valuesToSave, id, forceCreate }) 
      : updateNotification(valuesToSave));

    const { success, data: responseData } = response;
    if (success) {
      onUpdate(responseData);
      setIsOpen(false);
    }
  };

  if (!user) return null;

  return (
    <>
      <Button size="small" color="primary" onClick={() => setIsOpen(true)}>
        {title}
      </Button>
      <Modal
        className={classes.modal}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.modalPaper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader
              title={index === -1 ? 'Añadir notificación' : 'Editar notificación'}
            />
            <CardContent>
              <Controller
                as={
                  <TextField
                    fullWidth
                    label="Título"
                    type="text"
                    variant="outlined"
                    className={classes.textField}
                  />
                }
                control={control}
                name="title"
                rules={{ required: true }}
              />

              <FormRichTextField
                control={control}
                errors={errors}
                entity="notification"
                field={{
                  label: 'Contenido',
                  name: 'content',
                }}
                register={register}
                rules={{ required: true }}
                setValue={setValue}
                values={{ content: contentValue }}
                watch={watch}
              />

              <FormEnumSelectField
                control={control}
                entity="notification"
                errors={errors}
                field={{
                  label: 'Tipo',
                  name: 'type',
                  options: [
                    { value: 'web', label: 'Web' },
                    { value: 'email', label: 'Correo' },
                    { value: 'web_correo', label: 'Web y Correo' },
                  ],
                }}
                type="text"
                rules={{ required: true }}
              />
            </CardContent>

            <CardActions>
              <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => setIsOpen(false)}
                    size="large"
                    type="button"
                    variant="contained"
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </Card>
      </Modal>
    </>
  );
};

NewNotificationModal.propTypes = {
  index: PropTypes.number.isRequired,
  notification: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    type: PropTypes.string, // Cambiar a string para selección única
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

NewNotificationModal.defaultProps = {};

export default NewNotificationModal;
