import API, { graphqlOperation } from '@aws-amplify/api';
import moment from 'moment';

import SchemaManager from '../schema';
import {
  listUsersPayments,
  listCustomPayments,
  listDashboardStudentData,
} from '../../graphqlCustomQueries/customQueries';
import * as customQueries from '../../graphqlCustomQueries/customQueries';


const response = ({ success, msg = null, data = null, paginationToken = null }) => {
  return {
    success,
    message: !success && !msg ? 'UnexpectedError' : msg,
    data,
    paginationToken
  }
}


export async function getInsuranceItem() {
  try {
    const { queries } = SchemaManager;
    const { data } = await API.graphql({ query: queries.listServices, variables: { filter: { key: { eq: 'Seguro' } } } });
    return response({ success: true, msg: 'success', data: data.listServices });
  } catch (error) {
    console.log('getInsuranceItem error -> ', error);
    return response({ success: false, msg: error.code });
  }
}


export async function getPayments(paginationToken, limit = 100000) {
  try {
    const graphqlOperationOptions = {
      limit,
      ...(paginationToken ? { nextToken: paginationToken } : null)
    };
    
    const { data } = await API.graphql(graphqlOperation(listUsersPayments, graphqlOperationOptions));
    const { items } = data.listStudents;
    let token = data.listStudents.nextToken;

    return response({ success: true, data: items, paginationToken: token });
  } catch (error) {
    console.log('🚀 ~ file: entities.js ~ line 163 ~ getPayments ~ error', error)
    const { data, errors } = error;
    console.log('listUsersPayments -> error', errors)
    if (data && data.listUsersPayments) return response({ success: true, data: data.listUsersPayments.items });
    return response({ success: false });
  }
}


export async function getNotifications(id) {
  try {
    const { queries } = SchemaManager;
    const { data } = await API.graphql({ query: queries.listStudentNotifications, variables: { studentId: id } });
    const items = data.listStudentNotifications;
    return response({ success: true, data: items });
  } catch (error) {
    console.log('entities, getNotifications -> ', error);
    const { data } = error;
    if (data?.listStudentNotifications) return response({ success: true, data: data.listStudentNotifications.items });
    return response({ success: false });
  }
}

export async function updateNotification(notification) {
  try {
    const { mutations } = SchemaManager;
    const { data } = await API.graphql(graphqlOperation(mutations.updateNotification, { input: notification }));
    return response({ success: true, msg: 'success', data: data.updateNotification });
  } catch (error) {
    console.log('entities, updateNotification error -> ', error);
    return response({ success: false, msg: error.code });
  }
}

export async function deleteNotification(notification) {
  const { studentId, timestamp } = notification;
  try {
    const { mutations } = SchemaManager;
    const { data } = await API.graphql(graphqlOperation(mutations.deleteNotification, { input: { studentId, timestamp } }));
    return response({ success: true, msg: 'success', data: data.deleteNotification });
  } catch (error) {
    console.log('deleteNotification -> error', error);
    return response({ success: false, msg: error.code });
  }
}

export async function getDashboardStudentData({ dates, paginationToken, limit = 500 }) {
  const fromDate = moment(dates.fromDate).toISOString();
  const toDate = moment(dates.toDate).toISOString();

  try {
    const graphqlOperationOptions = {
      limit,
      ...(paginationToken ? { nextToken: paginationToken } : null),
      ...(dates ? { filter: { createdAt: { between: [fromDate, toDate] } } } : null)
    };
    
    const result = await API.graphql(graphqlOperation(listDashboardStudentData, graphqlOperationOptions));
    const { data } = result;
    const { items } = data.listStudents;
    let token = data.listStudents.nextToken;
 
    return response({ success: true, data: items, paginationToken: token });
  } catch (error) {
    console.log('🚀 ~ file: entities.js ~ line 163 ~ listDashboardStudentData ~ error', error)
    const { data, errors } = error;
    if (data && data.listStudents) return response({ success: true, data: data.listStudents.items });
    return response({ success: false });
  }
}

export async function getDashboardPaymentData({ dates, paginationToken, limit = 100000 }) {
  const fromDate = new Date(dates.fromDate);
  const toDate = new Date(dates.toDate);
  const query = listCustomPayments;

  console.log("Data dates:");
  console.log(fromDate + " - " + toDate);

  let allItems = [];
  let nextToken = paginationToken || null;

  try {
    do {
      const graphqlOperationOptions = {
        limit,
        ...(nextToken ? { nextToken } : {}),
      };
      const result = await API.graphql(graphqlOperation(query, graphqlOperationOptions));
      console.log(result);

      // Acceder a los items dentro de la estructura del resultado
      const { items, nextToken: newToken } = result.data.listPayments;
      
      // Filtrar los items
      const filteredData = items.filter(item => {
        const updatedAt = new Date(item.updatedAt);
        return item.status === "done" && updatedAt >= fromDate && updatedAt <= toDate;
      });

      // Agregar los nuevos items filtrados al array de todos los pagos
      allItems = [...allItems, ...filteredData];
      nextToken = newToken; // Actualizar el token para la siguiente página

    } while (nextToken); // Seguir iterando mientras haya más datos

    return response({ success: true, data: allItems });
    
  } catch (error) {
    console.log("🚀 ~ file: entitiesCustom.js ~ line 143 ~ getDashboardPaymentData ~ error", error);
    const { data, errors } = error;
    if (data && data.listPayments) return response({ success: true, data: data.listPayments.items });
    return response({ success: false });
  }
}


export async function getEntityNames(entity, limit = 500) {
  const query = customQueries[`list${entity}sNames`];
  const dataName = `list${entity}s`;
  try {
    const graphqlOperationOptions = {
      limit,
    };
    const { data } = await API.graphql(graphqlOperation(query, graphqlOperationOptions));
    const { items } = data[dataName];
    let token = data[dataName].nextToken;
 
    return response({ success: true, data: items, paginationToken: token });
  } catch (error) {
    console.log('getEntitiesNames ~ error', error)
    const { data } = error;
    if (data && data[dataName]) return response({ success: true, data: data.dataName.items });
    return response({ success: false });
  }
}
