import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  GenericList,
  GenericEdit,
  UserList as UserListView,
  UserEdit as UserEditView,
  // VacantList as VacantListView,
  // Account as AccountView,
  // Settings as SettingsView,
  SignIn as SignInView,
  // PaymentList as PaymentListView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/users"/>

      {/* Dashboard */}
      <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" />

      {/* Estudiantes */}
      <RouteWithLayout component={UserListView} exact layout={MainLayout} path="/users" />
      <RouteWithLayout component={UserEditView} exact layout={MainLayout} path="/users/:id" />

      {/* Prácticas */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout} path="/internships" entity="Internship" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout} path="/internships/:id" entity="Internship" />

      {/* Vacantes */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/vacancy" entity="Vacancy" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/vacancy/:id" entity="Vacancy" />

      {/* Pagos */}
      {/* <RouteWithLayout component={PaymentListView} exact layout={MainLayout}  path="/payments" entity="Student" /> */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/payment" entity="Payment" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/payment/:id" entity="Payment" />

      {/* Mensajería */}

      {/* Servicios */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/service" entity="Service" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/service/:id" entity="Service" />

      {/* Precios */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/price" entity="Price" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/price/:id" entity="Price" />

      {/* Universidades */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/university" entity="University" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/university/:id" entity="University" />

      {/* Partners */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/partner" entity="Partner" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/partner/:id" entity="Partner" />

      {/* Faqs */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/faq" entity="Faq" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/faq/:id" entity="Faq" />

      {/* Países destino */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/country" entity="Country" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/country/:id" entity="Country" />

      {/* Países residencia */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/residence" entity="Residence" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/residence/:id" entity="Residence" />

      {/* Contenido */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/content" entity="Content" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/content/:id" entity="Content" />

      {/* Notificaciones */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/newshelper" entity="Newshelper" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/newshelper/:id" entity="Newshelper" />

      {/* Documentos patrón */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/docpattern" entity="Docpattern" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/docpattern/:id" entity="Docpattern" />
      
      {/* PopupHelper */}
      <RouteWithLayout component={GenericList} exact layout={MainLayout} path="/popuphelper" entity="Popuphelper" />
      <RouteWithLayout component={GenericEdit} exact layout={MainLayout} path="/popuphelper/:id" entity="Popuphelper" />

      <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/sign-in" />
      <Redirect to="/sign-in" />
      
      {/* <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/student" entity="Student" /> */}
      {/* <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/student/:id" entity="Student" /> */}
      {/* <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/category" entity="Category" /> */}
      {/* <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/category/:id" entity="Category" /> */}
      {/* <RouteWithLayout component={GenericList} exact layout={MainLayout}  path="/project" entity="Project" /> */}
      {/* <RouteWithLayout component={GenericEdit} exact layout={MainLayout}  path="/project/:id" entity="Project" /> */}
      {/* <RouteWithLayout component={VacantListView} exact layout={MainLayout} path="/vacants" /> */}
      {/* <RouteWithLayout component={ProductListView} exact layout={MainLayout} path="/products" /> */}
      {/* <RouteWithLayout component={IconsView} exact layout={MainLayout} path="/icons" /> */}
      {/* <RouteWithLayout component={AccountView} exact layout={MainLayout} path="/account" /> */}
      {/* <RouteWithLayout component={SettingsView} exact layout={MainLayout} path="/settings" /> */}
      {/* <RouteWithLayout component={SignUpView} exact layout={MinimalLayout} path="/sign-up" /> */}
    </Switch>
  );
};

export default Routes;
