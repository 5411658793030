import React, { useEffect } from 'react';
import { Fab, Grid, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { uploadFile, getFileURL } from '../../../services/storage';
import HSpacer from '../../HSpacer';

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  imgUploaded: {
    maxWidth: '98%'
  },
  imgListItemContainer: {
    position: 'relative',
    '&:hover > div': {
      display: 'block'
    }
  },
  removeButton: {
    position: 'absolute',
    display: 'none',
    top: '5px',
    left: '5px',
  }
}));

const FormImageUploadList = ({ errors, field, register, rules, setValue, watch, entity, control }) => {
  const { name, label } = field
  const errorMessage = errors && errors.type && ERROR_MESSAGES[errors.type];
  const entityURL = entity.toLowerCase();
  const classes = useStyles();
  
  useEffect(() => {register({ name }, rules)}, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const timestamp = +new Date();
      const filename = `${timestamp}-${file.name}`.replace(/\s/g, '-').replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi,'-');
      const fileLocation = `${entityURL}/${name}/${filename}`
      const { success } = await uploadFile(file, fileLocation);
      const imgList = success ? [...(value || []), filename] : value; 
      setValue(name, imgList); 
    }
  }

  const handleFileRemove = (index) => {
    const newList = [...value.slice(0, index), ...value.slice(index + 1)];
    setValue(name, newList);
  }

  //TODO: fix this ugly shit
  const hooksValue = watch(name, []);
  const value = typeof hooksValue === 'string' ? [hooksValue] : hooksValue;

  return (
    <Controller
      as={
        <Grid container>
          <Grid item xs={12}>
            <Typography>{label}</Typography>
          </Grid>
          <Grid container item xs={11}>
            {value && value.length > 0 && value.map((img, i) => (
              <div className={classes.imgListItemContainer} style={{width: `${Math.floor(100 / value.length)}%`}}>
                <img className={classes.imgUploaded} src={getFileURL(`${entityURL}/${name}/${img}`)} />
                <div className={classes.removeButton}>
                  <Fab component="span" color="secondary" onClick={() => handleFileRemove(i)}>
                    <DeleteForeverIcon />
                  </Fab>
                </div>
              </div>
            ))}
          </Grid>
          <Grid item xs={1}>
            <div>
              <input 
                accept="image/*"
                className={classes.input}
                id={`${name}-image-upload`}
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor={`${name}-image-upload`}>
                <Fab component="span" color="primary">
                  <AddPhotoAlternateIcon />
                </Fab>
              </label>
            </div>
          </Grid>
        </Grid>
      }
      control={control}
      defaultValue={[]}
      error={!!errors}
      fullWidth
      helperText={errorMessage}
      label={label || name}
      labelId={name}
      name={name}
      rules={rules}
      type="text"
      variant="outlined"
    />
  )
}
 
export default FormImageUploadList;