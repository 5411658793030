import React, { useState, useRef } from 'react';
import { Grid, Typography, Button, Fab, Modal, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';

import SchemaManager from '../../schema';
import HSpacer from '../HSpacer';
import FormField from '../formFields/FormField';

const useStyles = makeStyles(theme => ({
  subEntityForm: {
    width: '100%'
  },
  errorMessage: {
    color: 'red',
    marginRight: '20px'
  },
  scrollContainer: {
    maxHeight: '70vh',
    overflow: 'scroll',
    paddingBottom: '150px'
  }
}));

const SubObjectForm = ({onCancel, onSave, data, id, allData, dataIndex, fields, entity, subObject}) => {
  const classes = useStyles();
  const formRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');

  const { handleSubmit, reset, errors, setValue: nestedSetValue, control, register: nestedRegister, getValues, watch: nestedWatch } = useForm({ defaultValues: data });

  const handleFormSubmit = (values, event) => {
    if (formRef.current !== event.target) return null;
    setErrorMessage('');

    const validation = SchemaManager.getSubobjectValidations(entity, subObject.name);
    if (validation && !validation.validate(allData, values, dataIndex)) {
      setErrorMessage(validation.errorMessage);
      return;
    }

    onSave(values, dataIndex);
  }

  return (
    <>
      <Typography variant="h3">Edit item</Typography>
      <HSpacer />
      <form ref={formRef} className={classes.subEntityForm} onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid className={classes.scrollContainer} container item xs={12} spacing={4}>
          {fields.map(subEntityField => {
            const fieldProps = {
              entity,
              field: subEntityField,
              control,
              getValues,
              register: nestedRegister,
              setValue: nestedSetValue,
              subObject: subObject.name,
              errors: errors[subObject.name],
              rules: { required: subObject.required },
              watch: nestedWatch,
            }
            return <FormField key={subEntityField.name} id={id} {...fieldProps} />
          })}
        </Grid>
        <HSpacer />
        <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
          <Typography align="right" className={classes.errorMessage}>{errorMessage}</Typography>
        </Grid>
        <HSpacer />
        <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              color="secondary"
              fullWidth
              onClick={onCancel}
              size="large"
              type="button"
              variant="contained"
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Confirmar edición
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default SubObjectForm;
