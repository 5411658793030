import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import data from './data';

import { VacantToolbar, VacantTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const VacantList = () => {
  const classes = useStyles();

  const [vacants, setVacants] = useState(data);

  useEffect(() => {
    const fetchVacant = async () => {
      // const result = await list();
      // if (result.success) {
      //   setVacant(result.data)
      // }
    }
    fetchVacant();
  }, [])

  return (
    <div className={classes.root}>
      <VacantToolbar />
      <div className={classes.content}>
        <VacantTable vacants={vacants} />
      </div>
    </div>
  );
};

export default VacantList;
