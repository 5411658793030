import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Select, FormControl, InputLabel, MenuItem, Grid } from '@material-ui/core';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    // height: '42px',
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const VacantToolbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button className={classes.importButton}>Importar</Button>
        <Button className={classes.exportButton}>Exportar</Button>
        <Button
          color="primary"
          variant="contained"
        >
          Añadir vacante
        </Button>
      </div>
      <div className={classes.row}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={6} xs={12}>
            <SearchInput
              className={classes.searchInput}
              placeholder="Buscar vacante (empresa, contacto, email o teléfono)"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={null}
                onChange={() => {}}
              >
                <MenuItem value={10}>Food &amp; Beverage</MenuItem>
                <MenuItem value={20}>Reception</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={null}
                onChange={() => {}}
              >
                <MenuItem value={10}>Barcelona</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Fechas</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={null}
                onChange={() => {}}
              >
                <MenuItem value={10}>all year</MenuItem>
                <MenuItem value={10}>mayo - octubre</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

VacantToolbar.propTypes = {
  className: PropTypes.string
};

export default VacantToolbar;
