import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { matchSorter } from 'match-sorter';

import { getEntityLabel } from '../../../schema';
import useGraphList from '../../../hooks/useGraphList';

const ALL_NONE_OPTION = { id: null, label: 'None/All' };

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  }
}));

const filterOptions = (options, { inputValue }) => {
  const filteredOptions = matchSorter(options, inputValue, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['code', 'id', 'name', 'nameLang.es', 'company', 'department'] });
  return options.some(o => o.id === null) ? [ALL_NONE_OPTION, ...filteredOptions] : filteredOptions;
}


const getSelectedOptions = (options, value, multiple) => {
  if (multiple) {
    return value && value.length
      ? options.filter(op => value.filter(v => v === op.id).length > 0)
      : [];
  }
  return value ? options.filter(op => op.id === value)[0] : null;
}

const FormEntitySelectField = ({ errors, entityFieldName, field, multiple = false, register, rules, setValue, vacanciesPrioritiesAndRecommendations, watch }) => {
  const { label, required, relatedEntity } = field

  const errorMessage = errors && errors.type && ERROR_MESSAGES[errors.type];
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { data, loading } = useGraphList({ entity: relatedEntity, getAll: true, sorted: true });

  useEffect(() => { register({ name: entityFieldName }, rules) }, []);

  const handleAutocompleteChange = (event, value) => {
    if (multiple) {
      const idsValue = value.map(itemSelected => itemSelected.id).filter(id => !!id);
      return setValue(entityFieldName, idsValue && idsValue.length ? idsValue : null);
    }
    setValue(entityFieldName, value ? value.id : null);
  }
  const value = watch(entityFieldName, null);
  const autocompleteOptions = required || multiple ? data : [ALL_NONE_OPTION, ...data];
  const selectedOption = getSelectedOptions(autocompleteOptions, value, multiple);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Autocomplete
        filterOptions={filterOptions}
        filterSelectedOptions={multiple}
        getOptionLabel={getEntityLabel}
        getOptionSelected={(option, value) => option && value ? option.id === value.id : false}
        id="asynchronous-demo"
        loading={loading}
        multiple={multiple}
        onChange={handleAutocompleteChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        options={vacanciesPrioritiesAndRecommendations || autocompleteOptions}
        value={selectedOption}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errors}
            fullWidth
            helperText={errorMessage}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default FormEntitySelectField;
