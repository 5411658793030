import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Modal,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import uuid from 'uuid/v1';

import AddInsurance from './AddInsurance';
import FormField from '../../../../entities/components/formFields/FormField';
import { getEntity, saveEntity } from '../../../../entities/services/entities';
import ModalWarningEdit from '../ModalWarning/ModalWarningEdit';

const useStyles = makeStyles(theme => ({
  groupCard: {
    marginBottom: theme.spacing(4)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const fieldsGrouped = [
  {
    'fields': [
      {
        'type': 'int',
        'label': 'Horas requeridas',
        'name': 'internshipHours',
        'required': false
      },
      {
        'type': 'listFree',
        'label': 'Departamentos',
        'name': 'departmentsLooking',
        'required': false
      },
      {
        'type': 'date',
        'label': 'Fecha inicio',
        'name': 'initDate',
        'required': false
      },
      {
        'type': 'date',
        'label': 'Fecha final',
        'name': 'endDate',
        'required': false
      },
      {
        'type': 'entitySelect',
        'label': 'Servicio',
        'relatedEntity': 'Service',
        'name': 'service',
        'required': false
      },
      {
        'type': 'entitySelect',
        'label': 'Vacante',
        'relatedEntity': 'Vacancy',
        'name': 'vacancy',
        'required': false
      },
      {
        'type': 'date',
        'label': 'Fecha inicial visado',
        'name': 'visaInitDate',
        'required': false
      },
      {
        'type': 'date',
        'label': 'Fecha final visado',
        'name': 'visaEndDate',
        'required': false
      },
      {
        'type': 'date',
        'label': 'Fecha inicial autorización residencia',
        'name': 'authorizationResidencyInitDate',
        'required': false
      },
      {
        'type': 'date',
        'label': 'Fecha final autorización residencia',
        'name': 'authorizationResidencyEndDate',
        'required': false
      },
      {
        'type': 'subObjectList',
        'label': 'Documentos',
        'relatedEntity': 'Document',
        'name': 'documents',
        'required': false
      },
      {
        'type': 'string',
        'label': '¿Qué traer?',
        'name': 'whatToBring',
        'required': false
      },
      {
        'type': 'subObjectList',
        'label': 'Entrevistas',
        'relatedEntity': 'IntershipInterview',
        'name': 'interviews',
        'required': false
      }
    ]
  }
];

const formatData = (data) => {
  if (!data) return [];
  const internshipHours = data?.internshipHours || '';
  const departmentsLooking = data?.departmentsLooking || '';
  const initDate = data?.initDate || '';
  const endDate = data?.endDate || '';
  const service = data?.service || '';
  const vacancy = data?.vacancy || '';
  const visaInitDate = data?.visaInitDate || '';
  const visaEndDate = data?.visaEndDate || '';
  const authorizationResidencyInitDate = data?.authorizationResidencyInitDate || '';
  const authorizationResidencyEndDate = data?.authorizationResidencyEndDate || '';
  const documents = data?.documents || '';
  const whatToBring = data?.whatToBring || '';
  const interviews = data?.interviews || '';

  const formatedData = [
    ...(internshipHours && [{ internshipHours }]),
    ...(departmentsLooking && [{ departmentsLooking }]),
    ...(initDate && [{ initDate: initDate }]),
    ...(endDate && [{ endDate: endDate }]),
    ...(service && [{ internshipServiceId: service?.id }]),
    ...(vacancy && [{ internshipVacancyId: vacancy.id }]),
    ...(visaInitDate && [{ visaInitDate: visaInitDate }]),
    ...(visaEndDate && [{ visaEndDate: visaEndDate }]),
    ...(authorizationResidencyInitDate && [{ authorizationResidencyInitDate: authorizationResidencyInitDate }]),
    ...(authorizationResidencyEndDate && [{ authorizationResidencyEndDate: authorizationResidencyEndDate }]),
    ...(documents && [{ documents }]),
    ...(whatToBring && [{ whatToBring }]),
    ...(interviews && [{ interviews }]),
  ];

  return formatedData;
};


const Internship = (props) => {
  const { loading, user, onUpdate } = props;
  const { id, payments = [], priorities, recommended, internship } = user;

  const [isAddInsuranceOpen, setIsAddInsuranceOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const formRef = useRef();
  const history = useHistory();
  const classes = useStyles();

  const { handleSubmit, setValue, control, register, getValues, watch } = useForm();

  useEffect(() => {
    if (!user) return;
    const data = formatData(user?.internship);
    if (data) setValue(data);
  }, [user]);

  const handleFormSubmit = async (values, event) => {
    const { documents: newDocuments = [] } = values;

    const { data: fetchedStudentData } = await getEntity('student', user.id);
    const { internship: { documents:  fetchedDocuments = [] }, id } = fetchedStudentData;

    if (formRef.current !== event.target) return null;

    const hasChanges = newDocuments?.some(newDoc => {
      const correspondingFetchedDoc = fetchedDocuments?.find(fetchedDocument => fetchedDocument.docpatternId === newDoc.docpatternId);
      return correspondingFetchedDoc && correspondingFetchedDoc.documentFile !== newDoc.documentFile;
    });
    
    if (hasChanges) return setShowModal(true);

    const updatedDocuments = newDocuments?.map(doc => {
      const { docpatternId, responsible, status } = doc;
      return {
        ...doc,
        docpatternId: docpatternId ?? uuid(),
        status: responsible === 'nexus' ? 'accepted' : status,
      };
    }) ?? [];

    const newInternship = { 
      ...internship, 
      ...values, 
      documents: updatedDocuments,
    };

    delete newInternship.service;
    delete newInternship.vacancy;

    const { success, data } = await saveEntity({ entity: 'student', values: { internship: newInternship, id }, id });
    if (success) onUpdate(data);
  }

  const handleBack = () => history.goBack();
  // const values = getValues();
  // console.log('IMTCHLG ~ file: GenericEditForm.js ~ line 67 ~ GenericEditForm ~ values', values);

  const getHasInsurance = () => {
    // Each payment has the number of the internship
    const pastInternshipsCount = (user?.pastInternships?.length || 0) + 1;
    return payments.findIndex((item) => item?.code?.includes(`insurance/${pastInternshipsCount}`)) >= 0;
  }
  const hasInsurance = getHasInsurance();
  const isExtension = user?.internship?.isExtension;

  const vacanciesPriorities = priorities?.map(({ vacancy }) => vacancy) ?? [];
  const vacanciesPrioritiesAndRecommendations = recommended 
    ? [...vacanciesPriorities, recommended]
    : vacanciesPriorities;

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit(handleFormSubmit)} >
        <Grid container spacing={4}>
          <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
            <Grid item xs={2}>
              <Button
                color="secondary"
                fullWidth
                onClick={handleBack}
                size="large"
                type="button"
                variant="contained"
              >
                Volver
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                fullWidth
                onClick={handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {(loading) && (
              <Card>
                <CardHeader title="Edit" />
                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={8}>
                      {loading && <Typography>Cargando</Typography>}
                      {/* {error && <Typography>{error}</Typography>} */}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}

            {
              !hasInsurance && (
                <Grid item xs={12} sm={4}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => setIsAddInsuranceOpen(true)}
                    size="large"
                    variant="contained"
                  >
                    Generar pago del seguro
                  </Button>
                </Grid>
              )
            }

            <Box padding="1rem" />

            {
              isExtension && (
                <Grid item xs={12} sm={4}>
                  <Card><CardHeader title="Es prórroga" /></Card>
                  <Box padding="1rem" />
                </Grid>
              )
            }

            {fieldsGrouped.map((fieldGroup, fieldGroupIndex) => (
              <Card key={`${fieldGroupIndex}-${fieldGroup.title}`} className={classes.groupCard}>
                {fieldGroup.title && (
                  <>
                    <CardHeader title={fieldGroup.title} />
                    <Divider />
                  </>
                )}
                <CardContent>
                  <Grid container item xs={12} spacing={4}>
                    {fieldGroup.fields.map(field => {
                      const defaultProps = {
                        control,
                        entity: 'internship',
                        field,
                        getValues,
                        register,
                        setValue,
                        // errors: errors[field.name],
                        rules: { required: field.required },
                        vacanciesPrioritiesAndRecommendations,
                        watch,
                      }
                      return <FormField id={id} key={field.name} {...defaultProps} />
                    })}
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </form>

      {
        isAddInsuranceOpen && (
          <Modal
            className={classes.modal}
            open={isAddInsuranceOpen}
            onClose={() => setIsAddInsuranceOpen(false)}
          >
            <AddInsurance
              user={user}
              setIsOpen={setIsAddInsuranceOpen}
              onUpdate={onUpdate}
            />
          </Modal>
        )
      }
      <ModalWarningEdit showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}


export default Internship;
