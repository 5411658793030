import React, { Fragment, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useWatch } from 'react-hook-form';

import SchemaManager, { getRelatedEntityFieldName } from '../../../schema';
import { getCurrentUser } from '../../../services/auth';
import FormTextField from '../FormTextField';
import FormRichTextField from '../FormRichTextField';
import FormTranslationField from '../FormTranslationField';
import FormEnumSelectField from '../FormEnumSelectField';
import FormEntitySelectField from '../FormEntitySelectField';
import FormSubObjectListField from '../FormSubObjectListField';
import FormBooleanField from '../FormBooleanField';
import FormVideoUploadField from '../FormVideoUploadField';
import FormImageUploadField from '../FormImageUploadField';
import FormImageUploadList from '../FormImageUploadList';
import FormFileUploadField from '../FormFieldUploadField';
import FormFreeStringList from '../FormFreeStringList';

const useStyles = makeStyles(theme => ({
  subfieldContainer: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: theme.spacing(4)
  }
}));

const getFieldType = (type) => {
  switch (type) {
    case 'int':
    case 'float':
      return 'number'
    case 'date':
      return 'date'
    case 'timestamp':
      // return 'datetime-local';
      return 'date'
    default:
      return 'text'
  }
}

const getFieldWidth = (type) => {
  switch (type) {
    case 'int':
    case 'float':
    case 'date':
    case 'timestamp':
    case 'enumSelect':
      return 3
    default:
      return 6
  }
}

const FormField = ({ field, id, entity, watch, subObject, getValues, ...defaultProps }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const classes = useStyles();
  watch();
  useEffect(() => {
    const fetchUser = async () => {
      const { success, data } = await getCurrentUser();
      if (success) setCurrentUser(data);
    }
    fetchUser();
  }, []);

  const fieldUserGroup = SchemaManager.SPECIFIC_GROUP_FIELDS[`${entity}.${field.name}`];
  const showIdField = SchemaManager.ENTITIES_WITH_MANUAL_IDS.includes(entity);
  if (!showIdField && id === '0' && SchemaManager.CREATE_EXCLUDE_FIELDS.includes(field.name)) return null;

  const disabled = field.name === 'createdAt'
    || field.name === 'updatedAt'
    || field.name === 'order'
    || (field.name === 'id' && id !== '0');

  const values = getValues();
  const isHidden = SchemaManager.isHiddenField(values, field.name, entity, subObject);
  const isRichText = SchemaManager.isRichText(field.name);
  const breaksLineBefore = SchemaManager.fieldBreaksLineBefore(entity, field.name);
  const breaksLineAfter = SchemaManager.fieldBreaksLineAfter(entity, field.name);
  const fieldWidth = SchemaManager.isFieldFullWidth(entity, field.name)
    ? 12
    : getFieldWidth(field.type)
  const fieldType = getFieldType(field.type);
  const fieldBackground = SchemaManager.getFieldBackground(entity, field.name);

  const fieldStyles = {
    display: isHidden ? 'none' : 'block',
    backgroundColor: fieldBackground
  }
  const fieldProps = {
    id,
    entity,
    field,
    disabled,
    watch,
    type: fieldType,
    values,
    ...defaultProps
  };

  if (currentUser && currentUser.group !== 'admin' && entity !== 'Partner') {
    if (fieldUserGroup && currentUser.group !== fieldUserGroup) return null;
  }
  if (field.name === 'id') {
    return showIdField ? (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>
    ) : (
      <div style={{ display: 'none' }}>
        <FormTextField {...fieldProps} />
      </div>
    );
  }
  if (field.type === 'translation') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item xs={12}>
          <FormTranslationField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (isRichText) {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <div item xs={12} style={fieldStyles}>
          {!isHidden && <FormRichTextField {...fieldProps} />}
        </div>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>
    )
  }
  if (field.type === 'string') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'boolean') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormBooleanField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'float' || field.type === 'int') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'date') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'timestamp') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormTextField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'enumSelect') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormEnumSelectField {...fieldProps} options={field.options} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'entitySelect') {
    const entityFieldName = getRelatedEntityFieldName(entity, field.name);
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormEntitySelectField {...fieldProps} entityFieldName={entityFieldName} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'entitySelectMultiple') {
    const entityFieldName = getRelatedEntityFieldName(entity, field.name);
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormEntitySelectField {...fieldProps} multiple entityFieldName={entityFieldName} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'listFree') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormFreeStringList {...fieldProps} entityFieldName={field.name} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'subObjectList') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid className={classes.subfieldContainer} key={field.name} item xs={12} style={fieldStyles}>
          <FormSubObjectListField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'videoUpload') {
    return (
      <Fragment key={field.name}>
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormVideoUploadField {...fieldProps} />
        </Grid>
        {!isHidden && breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'imageUpload') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={fieldWidth} xs={12} style={fieldStyles}>
          <FormImageUploadField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }
  if (field.type === 'imgUploadList') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={12} xs={12} style={fieldStyles}>
          <FormImageUploadList {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }

  if (field.type === 'fileUpload') {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={12} xs={12} style={fieldStyles}>
          <FormFileUploadField {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }

  const CustomComponent = SchemaManager.getCustomComponent(entity, field.name);

  if (CustomComponent) {
    return (
      <Fragment key={field.name}>
        {breaksLineBefore && <Grid item xs={12} />}
        <Grid item md={12} xs={12} style={fieldStyles}>
          <CustomComponent {...fieldProps} />
        </Grid>
        {breaksLineAfter && <Grid item xs={12} />}
      </Fragment>);
  }

  return field.type;
}

export default FormField;