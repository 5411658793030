import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hspacer: {
    width: '100%',
    height: theme.spacing(2)
  }  
}));

const HSpacer = () => {
  const classes = useStyles();
  return (
    <div className={classes.hspacer} />
  )
}
 
export default HSpacer;