import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import SchemaManager from '../../../schema';

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  }  
}));

const FormEnumSelectField = ({ entity, errors, field, type='text', control, rules }) => {
  const { label, name, options } = field
  const labelId = `label-select-${name}`;
  const errorMessage = errors && errors.type && ERROR_MESSAGES[errors.type];
  const classes = useStyles();

  const fieldDefaultValue = SchemaManager.getDefaultValue(entity, name) || '';

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select label="Selecciona...">
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        }
        control={control}
        defaultValue={fieldDefaultValue}
        error={!!errors}
        fullWidth
        helperText={errorMessage}
        label={label || name}
        labelId={labelId}
        name={name}
        rules={rules}
        type={type}
        variant="outlined"
      />
    </FormControl>
  )
}
 
export default FormEnumSelectField;
