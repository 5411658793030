import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReactExport from 'react-export-excel';

import SchemaManager from '../../../../entities/schema';
import useGraphList from '../../../hooks/useGraphList';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const translate = {
  Price: 'precios',
  Vacancy: 'vacantes',
  Country: 'paises',
}

const useStyles = makeStyles(theme => ({
  exportButton: {
    marginRight: theme.spacing(1)
  },
  exportButtonLink: {
    color: theme.palette.primary.main
  }
}));

const TableExport = ({ entity, fieldsTransform }) => {
  const classes = useStyles();
  const { data } = useGraphList({ entity, related: true, getAll: true });
  const [dataExport, setDataExport] = useState([])

  useEffect(() => {
    const dataParsed = fieldsTransform ? data.map((item) => fieldsTransform(item, entity)) : data.map((item) => {
      const transformedItem = {};
      Object.keys(item).forEach(key => {
        transformedItem[key] = typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key];
      });
      return transformedItem;
    });
    setDataExport(dataParsed);
  }, [data]);

  if (dataExport.length === 0) return null;

  const getColumns = () => {
    const { fields } = SchemaManager.getEntity(entity);
    if (fieldsTransform) {
      const columns = fields.map((field) => ({ label: field.label, name: field.name }));
      const columnsTransformed = columns.map((item) => {
        const { label, name } = item;
        if (name?.includes('Lang')) {
          if (name?.includes('richTextObsLang') || name?.includes('conditionsLang')) return { label, name: `${name}.es` };
          const langLabels = {
            es: ' Español',
            en: ' Inglés',
          };
          return [
            { label: label + langLabels.es, name: `${name}.es` },
            { label: label + langLabels.en, name: `${name}.en` },
          ];
        }
        if (name === 'country' || name === 'datesOpen') return null;
        return item;
      }).flat().filter(Boolean);

      if (entity === 'Vacancy') {
        const extraColumns = [
          { label: 'País', name: 'vacancyCountryId' },
          { label: 'datesOpen[0].dateIni', name: 'datesOpen[0].dateIni' },
          { label: 'datesOpen[0].dateEnd', name: 'datesOpen[0].dateEnd' },
          { label: 'datesOpen[1].dateIni', name: 'datesOpen[1].dateIni' },
          { label: 'datesOpen[1].dateEnd', name: 'datesOpen[1].dateEnd' }
        ];
        return [...columnsTransformed, ...extraColumns];
      }

      return columnsTransformed;
    }

    return fields.map((field) => ({ label: field.label, name: field.name }));
  };

  const columns = getColumns() || [];

  if (columns && columns.length <= 0) return null;

  return (
    <ExcelFile
      filename={translate[entity]}
      element={(
        <Button className={classes.exportButton}>
          Exportar
        </Button>
      )}
    >
      <ExcelSheet data={dataExport} name="Export">
      {
        columns.map((el) => {
          const { label, name } = el;
          return <ExcelColumn 
                    label={label} 
                    value={(col) => typeof col[name] === 'object' ? "" : col[name]} 
                    key={name} 
                 />
        })
      }
    </ExcelSheet>
    </ExcelFile>
  )
}

export default TableExport;
