import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import palette from 'theme/palette';
import { getChartOptions } from '../../helpers/chartOptions';
import { getEntityNames } from '../../../../entities/services/entitiesCustom';


const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const AmountByResidence = props => {
  const { periodLabel, paymentData } = props;
  const classes = useStyles();
  const options = getChartOptions();

  const [residences, setResidences] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [error, setError] = useState('');

  const getChartData = () => {  
    const { data, previousData } = paymentData;  
    const newData = {
      labels: residences,
      datasets: [
        {
          label: periodLabel,
          backgroundColor: palette.success.main,
          data: []
        },
        {
          label: `${periodLabel} anterior`,
          backgroundColor: palette.neutral,
          data: []
        }
      ]
    };
    residences.forEach((name) => {
      const residenceData = data.filter((item) => item?.student?.residence?.nameLang?.es === name);
      const residencePreviousData = previousData.filter((item) => item?.student?.residence?.nameLang?.es === name);
      const amount = residenceData.reduce((acc, item) => acc + item.amount, 0);
      const previousAmount = residencePreviousData.reduce((acc, item) => acc + item.amount, 0);
      newData.datasets[0].data.push(amount);
      newData.datasets[1].data.push(previousAmount);
    });
    setChartData(newData);
  }

  const fetchData = async () => {
    const { success, msg, data } = await getEntityNames('Residence');
    if (success) {
      const residencesNames = data.map((item) => item?.nameLang?.es).sort();
      setResidences(residencesNames);
    } else setError(msg);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setChartData(null);
    getChartData();
  }, [paymentData]);


  return (
    <Card className={classes.root}>
      <CardHeader title="Total cobrado por residencia" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={chartData}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

AmountByResidence.propTypes = {
  loading: PropTypes.bool.isRequired,
  paymentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
  periodLabel: PropTypes.string.isRequired,
};

export default AmountByResidence;
