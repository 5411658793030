import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {  Grid } from '@material-ui/core';

import FilterSelectEnum from '../../../../entities/components/table/GenericTable/FilterSelectEnum';

import ErrorBoundary from '../../../../components/ErrorBoundary';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    // height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const field = {
  label: 'Estado',
  name: 'status',
  filter: {
    schemaField: 'payment.status',
    enum: [
      { value: 'pending', label: 'Pendiente' },
      { value: 'onReview', label: 'En revisión' },
      { value: 'done', label: 'Pagado' },
      { value: 'error', label: 'Error' },
    ],
  },
};


const PaymentsToolbar = (props) => {
  const { filters, setFilters } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.row}>
        <Grid container spacing={2} alignItems="center">
          <ErrorBoundary key="payment-status">
            <Grid item xs={2}>
              <FilterSelectEnum key="payment-status" field={field} filters={filters} setFilters={setFilters} />
            </Grid>
          </ErrorBoundary>
        </Grid>
      </div>
    </div>
  );
};

PaymentsToolbar.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.shape(),
  setFilters: PropTypes.func,
};

export default PaymentsToolbar;
