import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import palette from 'theme/palette';
import { getChartOptions } from '../../helpers/chartOptions';
import { monthDictionary, monthReverseDictionary } from '../../helpers/getFilterDates';


const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const CalendarStudents = props => {
  const { filterdates, studentData, loading, monthsCount } = props;
  const classes = useStyles();
  const options = getChartOptions();

  const [error, setError] = useState('');
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const getChartData = () => {  
    const { data } = studentData;
    const { fromDate } = filterdates;
    const months = [];
    const firstMonth = moment(fromDate).month();
    const firstYear = moment(fromDate).year();

    for (let i = firstMonth; i < monthsCount + firstMonth - 1; i+=1) {
      if (i < 12) {
        const monthName = monthDictionary[i];
        months.push(`${monthName}-${firstYear}`);
      } else {
        const monthName = monthDictionary[i - 12];
        months.push(`${monthName}-${firstYear + 1}`);
      }
    }

    const newData = {
      labels: months,
      datasets: [
        {
          label: 'Total aplicaciones',
          backgroundColor: palette.primary.main,
          data: []
        },
        // {
        //   label: `${periodLabel} anterior`,
        //   backgroundColor: palette.neutral,
        //   data: []
        // }
      ]
    };
    months.forEach((month) => {
      const monthYear = month.match(/([0-9])\w+/g)[0];
      const monthName = month.match(/([A-Z])\w+/g)[0];
      const monthNumber = monthReverseDictionary[monthName];
      const monthData = data.filter((item) => {
        const { createdAt } = item;
        const date = new Date(createdAt);
        const itemMonth = date.getMonth();
        const itemYear = date.getFullYear();
        return monthYear == itemYear && monthNumber == itemMonth;
      });
      const count = monthData?.length;
      newData.datasets[0].data.push(count);
    });
    setChartData(newData);
  }

  useEffect(() => {
    setChartData(null);
    getChartData();
  }, [studentData]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Aplicaciones por mes" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={chartData}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

CalendarStudents.propTypes = {
  filterdates: PropTypes.shape({
    fromDate: PropTypes.object,
    toDate: PropTypes.object,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  monthsCount: PropTypes.number.isRequired,
  studentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
};

export default CalendarStudents;
