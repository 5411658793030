import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import palette from 'theme/palette';
import { getChartOptions } from '../../helpers/chartOptions';
import { monthDictionary, monthReverseDictionary } from '../../helpers/getFilterDates';


const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const CalendarPayments = props => {
  const { filterdates, periodLabel, paymentData, loading, monthsCount } = props;
  const classes = useStyles();
  const options = getChartOptions();

  const [error, setError] = useState('');
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const getChartData = () => {   
    const { data } = paymentData;
    const { fromDate } = filterdates;
    const months = [];
    const firstMonth = moment(fromDate).month();
    const firstYear = moment(fromDate).year();
    
    for (let i = 0; i < monthsCount; i += 1) {
      const currentMonth = firstMonth + i;
      if (currentMonth < 12) {
        const monthName = monthDictionary[currentMonth];
        months.push(`${monthName}-${firstYear}`);
      } else {
        const monthName = monthDictionary[currentMonth - 12];
        months.push(`${monthName}-${firstYear + 1}`);
      }
    }
  
    console.log("DATOS TOTAL COBRADOS");
    console.log(paymentData);
    
    const newData = {
      labels: months,
      datasets: [
        {
          label: 'Total cobrado',
          backgroundColor: palette.success.main,
          data: []
        },
      ]
    };
  
    months.forEach((month, index) => {
      const [monthName, year] = month.split('-');
      const monthNumber = monthReverseDictionary[monthName]; // Obtener el número de mes del diccionario
  
      const monthData = data.filter((item) => {
        const { updatedAt, status } = item;
        const date = moment(updatedAt); // Utilizamos updatedAt en lugar de dateDone
        const itemMonth = date.month();
        const itemYear = date.year();
  
        // Filtro por status "done" y el mes/año correspondiente
        return status === "done" && parseInt(year) === itemYear && monthNumber === itemMonth;
      });
  
      const amount = monthData.reduce((acc, item) => acc + item.amount, 0);
      newData.datasets[0].data[index] = amount; // Añadir el monto al dataset
    });
  
    setChartData(newData);
  }
  
  
  useEffect(() => {
    setChartData(null);
    getChartData();
  }, [paymentData]);


  return (
    <Card className={classes.root}>
      <CardHeader title="Total cobrado por mes" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={chartData}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

CalendarPayments.propTypes = {
  filterdates: PropTypes.shape({
    fromDate: PropTypes.object,
    toDate: PropTypes.object,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  monthsCount: PropTypes.number.isRequired,
  paymentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
  periodLabel: PropTypes.string.isRequired,
};

export default CalendarPayments;
