import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';

import UpDownData from '../UpDownData';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
}));


const Budget = (props) => {
  const { periodLabel, paymentData, loading } = props;
  const classes = useStyles();

  const [amount, setAmount] = useState(0);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    const { data, previousData } = paymentData;
    const newAmount = data.reduce((acc, item) => acc + item.amount, 0);
    const previousAmount = previousData.reduce((acc, item) => acc + item.amount, 0);
    const difference = (newAmount - previousAmount) * 100 / (newAmount || 1);
    setAmount(newAmount);
    setDifference(Number(difference.toFixed(0)));
  }, [paymentData]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL COBRADO
            </Typography>
            <Typography variant="h3">{loading ? '' : amount}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <EuroIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {loading ? '' : <UpDownData difference={difference} />}
          <Typography
            className={classes.caption}
            variant="caption"
          >
            {periodLabel} anterior
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  loading: PropTypes.bool.isRequired,
  paymentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
  periodLabel: PropTypes.string.isRequired,
};

export default Budget;
