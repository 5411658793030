import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Typography,
  Modal,
  Grid
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { removeEntity } from '../../../../entities/services/entities';
import HSpacer from '../../../../entities/components/HSpacer';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { labelsOverride } from '../../../../labelsOverride';
import { formatDate } from '../../../../helpers';

const circleBase = {
  display: 'inline-block',
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  color: '#fff',
  lineHeight: '25px',
  textAlign: 'center',
};

const useStyles = makeStyles(theme => ({
  inner: {
    paddingTop: 20,
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  popoverContainer: {
    padding: theme.spacing(1)
  },
  rowImage: {
    maxWidth: '50px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageNumber: {
    padding: theme.spacing(2)
  },
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
  circle: {
    ...circleBase,
    background: theme.palette.error.main,
  },
  circleZero: {
    ...circleBase,
    background: theme.palette.info.main,
  },
}));


const UsersTable = (props) => {
  const {
    // error,
    hasNextPage,
    loading,
    onNextPage,
    onPrevPage,
    onRefreshData,
    page,
    // relatedEntities,
    // setLoading,
    users,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [removingItem, setRemovingItem] = useState({});
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleEditItem = (id) => {
    history.push(`/users/${id}`);
  };

  const handleRemoveEntity = (id) => {
    setRemovingItem({ id })
    setShowRemoveModal(true);
  };

  const handleCloseModal = () => {
    setShowRemoveModal(false);
  }

  const handleConfirmRemove = async () => {
    const { id } = removingItem;
    await removeEntity('student', id);
    setShowRemoveModal(false);
    onRefreshData();
  }

  return (
    <>
      <Card
        className={clsx(classes.root)}
      >
        <CardContent className={classes.content} style={{maxHeight: "60vh", overflow: 'auto'}}>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID interno</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Apellidos</TableCell>
                    <TableCell>Pack</TableCell>
                    <TableCell>Destino</TableCell>
                    <TableCell>Residencia</TableCell>
                    <TableCell>Documentos recibidos</TableCell>
                    <TableCell>Nacionalidades</TableCell>
                    <TableCell>EU</TableCell>
                    <TableCell>Práctica</TableCell>
                    <TableCell>Ciudad</TableCell>
                    <TableCell>Fecha inicio</TableCell>
                    <TableCell>Fecha fin</TableCell>
                    <TableCell>Universidad</TableCell>
                    <TableCell>Fase</TableCell>
                    <TableCell>Prórroga</TableCell>
                    <TableCell>Partner</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ErrorBoundary>
                    {
                      loading
                        ? <TableRow><TableCell>Cargando</TableCell></TableRow>
                        : (users.map((user) => {
                          const residence = user?.residence?.nameLang?.es;
                          const university = user?.university?.name;
                          const country = user?.country?.nameLang?.es;
                          const nationalities = user?.nationalitiesList?.length ? user.nationalitiesList.join(', ') : '';
                          const partner = user?.partner?.id;
                          const pack = user.internship.service?.key;
                          const initDate = formatDate(user?.internship?.initDate);
                          const endDate = formatDate(user?.internship?.endDate);
                          const receivedDocumentsCount = user?.documents?.filter((doc) => doc.status === 'received')?.length || 0;
                          const hasReceivedDocuments = receivedDocumentsCount > 0;

                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={user.id}
                            >
                              <TableCell>{user.code}/{user.number}</TableCell>
                              <TableCell>{user.name}</TableCell>
                              <TableCell>{user.lastname}</TableCell>
                              <TableCell>{labelsOverride[pack]}</TableCell>
                              <TableCell>{country}</TableCell>
                              <TableCell>{residence}</TableCell>
                              <TableCell align="center">
                                <div className={hasReceivedDocuments ? classes.circle : classes.circleZero}>
                                  {receivedDocumentsCount}
                                </div>
                              </TableCell>
                              <TableCell>{nationalities}</TableCell>
                              <TableCell>{user.euNationality ? 'SI' : 'NO'}</TableCell>
                              <TableCell>{user.internship?.vacancy?.company}</TableCell>
                              <TableCell>{user.internship?.vacancy?.city}</TableCell>
                              <TableCell>{initDate}</TableCell>
                              <TableCell>{endDate}</TableCell>
                              <TableCell>{university}</TableCell>
                              <TableCell>{labelsOverride[user.status]}</TableCell>
                              <TableCell>{user.extension ? 'SI' : 'NO'}</TableCell>
                              <TableCell>{partner}</TableCell>

                              <TableCell>
                                <IconButton
                                  color="inherit"
                                  onClick={() => handleEditItem(user.id)}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  color="inherit"
                                  onClick={() => handleRemoveEntity(user.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })
                        )
                    }
                  </ErrorBoundary>
                </TableBody>
              </Table>
            </div>
        </CardContent>

        <Modal
          className={classes.modal}
          open={showRemoveModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.modalPaper}>
            <Typography>¿Quieres borrar este item?</Typography>
            <HSpacer />
            <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  fullWidth
                  onClick={handleCloseModal}
                  size="large"
                  type="button"
                  variant="contained"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleConfirmRemove}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Card>

      <HSpacer />
      <ErrorBoundary>
        {(page > 0 || hasNextPage) && (
          <Card className={clsx(classes.root)} p={4}>
            <CardContent className={classes.content}>
              <Grid container item justifyContent="flex-end" align="center" xs={12} spacing={2}>
                {page > 0 && (
                  <Button
                    color="primary"
                    onClick={onPrevPage}
                    type="submit"
                    variant="contained"
                  >
                    Anterior
                  </Button>
                )}
                <Typography className={classes.pageNumber}>{page + 1}</Typography>
                {hasNextPage && (
                  <Button
                    color="primary"
                    onClick={onNextPage}
                    type="submit"
                    variant="contained"
                  >
                    Siguiente
                  </Button>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </ErrorBoundary>
    </>
  );
};

UsersTable.propTypes = {
  hasNextPage: PropTypes.bool,
  loading: PropTypes.bool,
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func,
  onRefreshData: PropTypes.func,
  page: PropTypes.number,
  users: PropTypes.array.isRequired,
};

export default UsersTable;
