import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Typography
} from '@material-ui/core';

import { setNewPassword } from '../../entities/services/auth';
import { messages } from '../../helpers';

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5)
  },
  error: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(4)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SetInitialPassword = ({ user, onChangePasswordSuccess }) => {
  const classes = useStyles();
  const [error, setError] = useState('');

  const { handleSubmit, register, errors } = useForm();

  const handleSignIn = async values => {
    const { password } = values;
    setError('');

    if (password) {
      const { success, message } = await setNewPassword(user, password);
      if (success) {
        onChangePasswordSuccess(user);
      } else {
        setError(messages.errors[message]);
      }
    }
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(handleSignIn)}
    >
      <Typography
        className={classes.title}
        variant="h2"
      >
        Debes configurar tu contraseña
      </Typography>
      <TextField
        className={classes.textField}
        error={!!errors.password}
        fullWidth
        helperText={errors.password ? messages.errors[errors.password.type] : null}
        inputRef={register({required: 'Required'})}
        label="Password"
        name="password"
        type="password"
        variant="outlined"
      />
      {error && (
        <Typography className={classes.error}>
          {error}
        </Typography>
      )}
      <Button
        className={classes.signInButton}
        color="primary"
        // disabled={!isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Guardar
      </Button>
    </form>
  );
}
 
export default SetInitialPassword;