import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    }
  },
}));


const FilterRange = ({ field, filters, setFilters }) => {
  const { label, filter: { schemaField } } = field;
  const classes = useStyles();
  const ranges = filters[schemaField] || { rangeIni: null, rangeEnd: null };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const onClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  }

  const onChange = (value) => {
    const newFilters = { ...filters, [schemaField]: value };
    setFilters(newFilters);
  }

  return (
    <>
      <div>
        <div onClick={onClick} className={classes.button}>
          <div>
            {label}
          </div>
          <div>
            {open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => setOpen(false)}
        >
          <MenuItem>
            <TextField
              fullWidth
              label="From"
              margin="none"
              size="small"
              variant="outlined"
              name="rangeIni"
              onChange={(e) => onChange({ ...ranges, rangeIni: e.target.value })}
              type="number"
              value={ranges.rangeIni}
            />
          </MenuItem>
          <MenuItem>
            <TextField
              fullWidth
              label="To"
              margin="none"
              size="small"
              name="rangeEnd"
              variant="outlined"
              onChange={(e) => onChange({ ...ranges, rangeEnd: e.target.value })}
              type="number"
              value={ranges.rangeEnd}
            />
          </MenuItem>
          <MenuItem onClick={() => setOpen(false)}>Close</MenuItem>
        </Menu>
        {
          (ranges.rangeIni || ranges.rangeEnd) && (
            <span>
              {ranges.rangeIni > 0 ? `${ranges.rangeIni}` : 0}
              {ranges.rangeEnd > 0 && ` - ${ranges.rangeEnd}`}
            </span>
          )
        }
      </div>
    </>
  );
};

export default FilterRange;
