import API from '@aws-amplify/api';

import { listDocpatterns } from 'graphql/queries';


async function findDocpatterns({ studentStatus, UE = false, responsible }) {
  try {
    const filter = {
      studentStatus: { contains: studentStatus },
      euNationality: { eq: UE },
      active: { eq: true },
      ...(responsible ? { responsible: { contains: responsible } } : null),
    };
    const { data } = await API.graphql({
      query: listDocpatterns,
      variables: { filter }
    });
    const { items } = data.listDocpatterns;
    return items;
  } catch (error) {
    console.log('addDocpatterns - findDocpatterns -> error', error);
    return [];
  }
}


const addDocpatterns = async (student, studentStatus) => {
  const ueNationality = student?.ueNationality || false;
  const documents = student?.documents || [];
  const newDocs = await findDocpatterns({ studentStatus, UE: ueNationality });
  const formatedDocs = newDocs.map((doc) => {
    const newDoc = {
      name: doc.name,
      ...(doc.documentFile && { documentFile: doc.documentFile }),
      status: doc.responsible === 'nexus' ? 'accepted' : 'pending',
      responsible: doc.responsible,
      onExtension: doc.onExtension,
      showToStudent: doc.showToStudent,
      docpatternId: doc.id
    }
    return newDoc;
  });

  const newDocuments = [...documents, ...formatedDocs];

  return newDocuments;
};

export default addDocpatterns;
