import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';

import FormField from '../formFields/FormField';
import { resetUserPassword } from '../../services/auth';

const SetPasswordForm = ({ id }) => {
  const [result, setResult] = useState('');
  const { handleSubmit, errors, setValue, control, register, getValues, watch } = useForm();

  const handleFormSubmit = async ({ newPassword }) => {
    setResult('Loading...');
    const { success } = await resetUserPassword({ userId: id, newPassword });
    setResult(success ? 'Contraseña cambiada con éxito' : 'Error');
    
  }
  const field = { label: 'New password', name: 'newPassword', required: false, type: 'string' };

  const inputProps = {
    control,
    field,
    getValues,
    register,
    setValue,
    errors: errors[field.name],
    rules: { required: field.required },
    watch,
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Reiniciar contraseña" />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={8}>
                  {result && <Typography>{result}</Typography>}
                </Grid>
              </Grid>  
              <Grid container spacing={4}>
                <FormField id={id} key={field.name} {...inputProps} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
          <Grid item xs={2}>
            <Button
              color="primary"
              fullWidth
              onClick={handleSubmit}
              size="large"
              type="submit"
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

 
export default SetPasswordForm;