import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormTextField from '../FormTextField';
import FormRichTextField from '../FormRichTextField';
import SchemaManager from '../../../schema';

const useStyles = makeStyles(() => ({
  item: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  }
}));

const FormTranslationField = (props) => {
  const { errors, field, control, rules, register, watch, setValue, values } = props;
  const classes = useStyles();
  const isRichText = SchemaManager.isRichText(field.name);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.item}>
        <Typography>{field.label}</Typography>
      </Grid>
      {field.subfields.map(subfield => {
        const subfieldName = `${field.name}.${subfield.name}`;
        return (
          <Grid key={subfield.name} item md={6} xs={12}>
            {isRichText
              ? (
                <FormRichTextField
                  errors={errors}
                  register={register}
                  control={control}
                  rules={rules}
                  watch={watch}
                  setValue={setValue}
                  values={values}
                  field={{ ...props.field, name: subfieldName, label: subfield.name }}
                />
              )
              : (
                <FormTextField
                  control={control}
                  errors={errors && errors[subfield.name]}
                  field={{ ...subfield, name: subfieldName, label: subfield.name }}
                  rules={rules}
                />
              )
            }

          </Grid>
        )
      })}
    </Grid>
  )
}

export default FormTranslationField;