import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import palette from 'theme/palette';
import { getChartOptions } from '../../helpers/chartOptions';


const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const ByCountry = props => {
  const { periodLabel, studentData, loading } = props;
  const classes = useStyles();
  const options = getChartOptions();

  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [error, setError] = useState('');

  const getChartData = () => {  
    const { data, previousData } = studentData;
    const departments = [];
    data.forEach((item) => {
      const department = item?.internship?.vacancy?.department;
      if (department) {
        const index = departments.indexOf(department);
        if (index === -1) departments.push(department);
      }
    });

    const newData = {
      labels: departments,
      datasets: [
        {
          label: periodLabel,
          backgroundColor: palette.primary.main,
          data: []
        },
        {
          label: `${periodLabel} anterior`,
          backgroundColor: palette.neutral,
          data: []
        }
      ]
    };
    departments.forEach((name) => {
      const count = data.filter((item) => item?.internship?.vacancy?.department === name)?.length;
      const previousCount = previousData.filter((item) => item?.internship?.vacancy?.department === name)?.length;
      newData.datasets[0].data.push(count);
      newData.datasets[1].data.push(previousCount);
    });
    setChartData(newData);
  }

  useEffect(() => {
    setChartData(null);
    getChartData();
  }, [studentData]);

  return (
    <Card className={classes.root}>
      <CardHeader title={`Prácticas por departamento de los usuarios dados de alta en ${periodLabel}`} />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={chartData}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

ByCountry.propTypes = {
  loading: PropTypes.bool.isRequired,
  periodLabel: PropTypes.string.isRequired,
  studentData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    previousData: PropTypes.array.isRequired,
  }),
};

export default ByCountry;
