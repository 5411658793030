import React from 'react';
import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';

import { SearchInput } from 'components';
import TableExport from '../../../../entities/components/table/TableExport';
import FilterSelectEntity from '../../../../entities/components/table/GenericTable/FilterSelectEntity';
import FilterSelectEnum from '../../../../entities/components/table/GenericTable/FilterSelectEnum';
import FilterString from '../../../../entities/components/table/GenericTable/FilterString';
import FilterRange from '../../../../entities/components/table/GenericTable/FilterRange';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import SchemaManager from 'entities/schema';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    // height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


const UsersToolbar = (props) => {
  const { filters, setFilters, tableFields } = props;

  const userFilters = SchemaManager.TABLE_FILTERS?.Student || {};
  const userListFilters = userFilters.map((el) => Object.keys(el)[0]);

  const filteredTableFields = [];
  userListFilters.forEach((el) => {
    if (el === 'search') {
      filteredTableFields.push({ name: 'search', filter: { type: 'search' } });
      return;
    }
    const newFilter = tableFields.find((f) => f.name === el);
    if (newFilter) filteredTableFields.push(newFilter);
  });

  const normalizeString = (str) => {
    return str
      .normalize('NFD') // Descompone los caracteres acentuados en sus componentes
      .replace(/[\u0300-\u036f]/g, '') // Elimina los acentos
      .toLowerCase(); // Convierte a minúsculas
  };

  
  const onChangeSearchString = useCallback((e) => {
    e.persist();
    const value = normalizeString(e.target.value);
  
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  
    debounceTimeout.current = setTimeout(() => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: value
      }));
    }, 300);
  }, []);
  
  const debounceTimeout = useRef(null);

  const classes = useStyles();

  const fieldsTransform = (data) => {
    const dataFiltered = Object.keys(data).reduce((dataAgg, field) => {
      if (typeof data[field] === 'undefined' || data[field] === null) return dataAgg;
      return { 
        ...dataAgg,
        [field]: data[field]
      }
    }, {});
  
    const dataTransformed = {
      ...dataFiltered,
      studentCountryId: data?.country?.id ?? 0,
      studentResidenceId: data?.residence?.id ?? 0,
    }

    delete dataTransformed.residence;
    delete dataTransformed.country;
    
    return dataFiltered;
  }

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button> */}
        {/* <TableExport key="Student" entity="Student" fieldsTransform={fieldsTransform} /> */}
        {/* <Button
          color="primary"
          variant="contained"
        >
          Add user
        </Button> */}
      </div>
      <div className={classes.row}>
        <Grid container spacing={2} alignItems="center">
          {
            filteredTableFields.map((field) => {
              const { name, filter } = field;
              let filterComponent = '';

              if (filter?.type === 'search') filterComponent = <SearchInput key={name} placeholder="buscar" onChange={onChangeSearchString} />
              
              if (filter?.type === 'boolean') filterComponent = <FilterSelectEnum key={name} field={field} filters={filters} setFilters={setFilters} />;

              if (filter?.type === 'select' && filter.from === 'entity') {
                filterComponent = <FilterSelectEntity key={name} field={field} filters={filters} setFilters={setFilters} />;
              }
              if (filter?.type === 'select' && filter.from === 'enum') {
                filterComponent = <FilterSelectEnum key={name} field={field} filters={filters} setFilters={setFilters} />;
              }
              if (filter?.type === 'string') filterComponent = <FilterString key={name} field={field} filters={filters} setFilters={setFilters} />;
              if (filter?.type === 'range') filterComponent = <FilterRange key={name} field={field} filters={filters} setFilters={setFilters} />;

              return (
                <ErrorBoundary key={name}>
                  <Grid item xs={3}>
                    {filterComponent}
                  </Grid>
                </ErrorBoundary>
              );
            })
          }
        </Grid>
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.shape(),
  setFilters: PropTypes.func,
  tableFields: PropTypes.array,
};

export default UsersToolbar;
