import capitalize from '@bit/iamtechnologies.iamtech-js.capitalize';
import API, { graphqlOperation } from '@aws-amplify/api';
import moment from 'moment';

import SchemaManager, { mapField } from '../schema';

const CACHED_ENTITIES = {};


const response = ({ success, msg = null, data = null, paginationToken = null }) => {
  return {
    success,
    message: !success && !msg ? 'UnexpectedError' : msg,
    data,
    paginationToken
  }
}


const parseTimestampString = (timestampString) => {
  const [date, hours] = timestampString.split('T');
  return `${date}T${hours.slice(0,5)}`;
}

const parseTimestampField = (timestamp) => {
  if (typeof timestamp === 'string') {
    return parseTimestampString(timestamp);
  }
  
  if (typeof timestamp === 'number') {
    const dateTimestamp = new Date(timestamp)
    return parseTimestampString(dateTimestamp.toISOString());
  }
  
  return timestamp;
}


const runGraphqlList = async ({ entityListQuery, graphqlOperationOptions }) => {
  const { queries } = SchemaManager;
  const { data } = await API.graphql(graphqlOperation(queries[entityListQuery], graphqlOperationOptions));
  const { items } = data[entityListQuery];
  let token = data[entityListQuery].nextToken;
  return { items, token };
}

const normalizeString = (str) => {
  return str
    .normalize('NFD') // Descompone los caracteres acentuados en sus componentes
    .replace(/[\u0300-\u036f]/g, '') // Elimina los acentos
    .toLowerCase(); // Convierte a minúsculas
};


export async function listEntity({ adminUser, entity, getAll = false, paginationToken, limit = 100000, filters }) {
  if (!entity) return response({ success: false });

  let filter = {};
  let search = filters?.search;
  let searchState = filters?.search;
  if (search) filter.or = [];
  filters && Object.keys(filters).forEach((key) => {
    if (key === 'search' && filters[key]?.length <=0) {
      delete filters.search;
      delete filters.or;
      return;
    }
    if (key === 'search') {
      limit = 1500;

        const dbStringFilters = SchemaManager.DB_STRING_FILTERS[entity];
        const pushFilter = item => entity === 'Vacancy'
        ? filter.or.push({ [item?.key]: { contains: item?.transform(search) } })
        : filter.or.push({ [item]: { contains: search } });

      dbStringFilters.forEach(pushFilter);
      delete filters.search;
    }
    else if (filters[key].contains === 'all') delete filter[key];
    else if (filters[key].eq === 'all') delete filter[key];
    else filter[key] = filters[key];
  });

  if (entity === 'Student' && adminUser.group === 'partner' && adminUser.country) {
    filter.studentPartnerId = {eq: adminUser.country}
  }
  
  let hasFilters = filter && Object.keys(filter)?.length > 0;

  if (getAll && CACHED_ENTITIES[entity] && CACHED_ENTITIES[entity].length > 0 && !hasFilters) {

    let data = CACHED_ENTITIES[entity];

    
    return response({ success: true, data: data, paginationToken: null });
  }

  const entityListQuery = `list${capitalize(entity)}s`;
  try {

    if((entity == "Student" || entity == "Vacancy") && searchState){
      getAll = true;
      hasFilters = false;
    }

    const graphqlOperationOptions = {
      limit,
      ...(paginationToken ? { nextToken: paginationToken } : null),
      ...(hasFilters && { filter }),
    }
    let { items: totalItems, token: nextToken } = await runGraphqlList({ entityListQuery, graphqlOperationOptions });
    
    console.log(totalItems);
      while (getAll && nextToken) {
        const graphqlOperationOptions = {
          nextToken,
          ...( getAll ? null : { limit }),
          ...(hasFilters && filter),
        };
        let { items, token } = await runGraphqlList({ entityListQuery, graphqlOperationOptions });
        totalItems = [...totalItems, ...items];
        nextToken = token;
      }
      console.log(totalItems);
    if (getAll && SchemaManager.CACHED_ENTITIES.includes(entity)){ CACHED_ENTITIES[entity] = totalItems;}
    
    //AGREGAR AQUI LA COMPARACION

    let resultado = totalItems;
   
    console.log(resultado);
    if (searchState) {
      if(entity === 'Vacancy'){
        resultado = totalItems.filter(item => {
          const normalizedName = item.company ? normalizeString(item.company) : '';
          const normalizedcity = item.city ? normalizeString(item.city) : '';
          const normalizeddepartment = item.department ? normalizeString(item.department) : '';
          const normalizedtype = item.type ? normalizeString(item.type) : '';
          //const normalizedLastname = item.descriptionLang.es ? normalizeString(item.descriptionLang.es) : '';
          let res = normalizedName.includes(searchState) || normalizedcity.includes(searchState) || normalizeddepartment.includes(searchState) || normalizedtype.includes(searchState);
          return res;
        });
      }else{
        resultado = totalItems.filter(item => {
          const normalizedName = item.name ? normalizeString(item.name) : '';
          const normalizedLastname = item.lastname ? normalizeString(item.lastname) : '';
          let res = normalizedName.includes(searchState) || normalizedLastname.includes(searchState);
          return res;
        });
      }
      
    }
    
    console.log(resultado);
    return response({ success: true, data: resultado, paginationToken: nextToken });
  } catch (error) {
    const { data } = error;
    console.log('services, entities, listEntity -> entity, error', entity, error)
    if (data && data[entityListQuery]){

      
    let resultado = data[entityListQuery].items;

    if (searchState) {
      resultado = resultado.filter(item => {
        const normalizedName = item.name ? normalizeString(item.name) : '';
        const normalizedLastname = item.lastname ? normalizeString(item.lastname) : '';
        let res = normalizedName.includes(searchState) || normalizedLastname.includes(searchState);
        
        return res;
      });
    }
    
    console.log('DATA SEARCH2:', resultado);

      return response({ success: true, data: resultado });
    } 
    return response({ success: false });
  }
}


export async function getEntity(entity, id) {
  const entityGetQuery = `get${capitalize(entity)}`;
  try {
    const { queries } = SchemaManager;
    const { data } = await API.graphql(graphqlOperation(queries[entityGetQuery], { id }))
    const entityData = data[entityGetQuery]
    const formatedData = {
      ...entityData,
      ...(typeof entityData.createdAt === 'string' && entityData.createdAt 
        ? { createdAt: parseTimestampField(entityData.createdAt < 1000000000000 ? entityData.createdAt * 1000 : entityData.createdAt) } 
        : null
      ),
      ...(typeof entityData.createdAt === 'string' && entityData.updatedAt
        ? { updatedAt: parseTimestampField(entityData.updatedAt < 1000000000000 ? entityData.updatedAt * 1000 : entityData.updatedAt) } 
        : null
      ),
      ...(entityData.dateBefore 
        ? { dateBefore: moment(entityData.dateBefore).format('YYYY-MM-DD') } 
        : null
      ),
      ...(entityData.dateDone 
        ? { dateDone: moment(entityData.dateDone).format('YYYY-MM-DD') } 
        : null
      ),
    };

    return response({ success: true, data: formatedData});
  } catch ({ data, errors }) {
    console.log('getEntity -> error', errors)
    if (data && data[entityGetQuery]) return response({ success: true, data: data[entityGetQuery] });
    return response({ success: false });
  }
}


export async function saveEntity({ entity, values, id, hasOrder, forceCreate = false }) {
  Object.keys(values).forEach(k => {
    const { fields } = SchemaManager.getEntityFromSchema(entity);
    const field = fields.find((f => `${entity.toLowerCase()}${capitalize(f.name)}Id` === k));
    if (field) {
      const mappedField = mapField(field);
      if ((mappedField.type === 'entitySelectMultiple' || mappedField.type === 'enumSelect') && values[k] && values[k].length === 0) {
        delete values[k];
        return;
      }
    }

    if (k === 'createdAt'
      || k === 'updatedAt'
      || values[k] === undefined
      // || values[k] === ''
      || SchemaManager.isDependentFieldHidden(values, k, entity)
      || (Array.isArray(values[k]) && values[k].length === 0)
    ) {
      delete values[k];
    }
    if (values[k] === '') values[k] = null;
    if (k === 'dateDone' || k === 'dateBefore') {
      if (!values[k]) values[k] = null;
      else values[k] = new Date(values[k]);
    }
  });

  if (hasOrder && (values.order === null || values.order === undefined)) {
    const { success, data } = await listEntity(entity);
    if (success) values.order = data.length + 1;
  }

  const mutationName = !forceCreate && id && id !== '0' ? `update${capitalize(entity)}` : `create${capitalize(entity)}`;
  try {
    const { mutations } = SchemaManager;
    const { data } = await API.graphql(graphqlOperation(mutations[mutationName], { input: values }));
    if (CACHED_ENTITIES[entity]) setTimeout(() => window.location.reload(),0);
    return response({ success: true, msg: 'success', data: data[mutationName] });
  } catch (error) {
    console.log('saveEntity -> error', error)
    return response({ success: false, msg: error.code });
  }
}


export async function removeEntity(entity, id) {
  const mutationName = `delete${capitalize(entity)}`;
  try {
    const { mutations } = SchemaManager;
    const { data } = await API.graphql(graphqlOperation(mutations[mutationName], { input: { id } }));
    if (entity === 'Payment') removeStudentPayment(data);
    return response({ success: true, msg: 'success', data: data[mutationName] });
  } catch (error) {
    console.log('removeEntity -> error', error)
    return response({ success: false, msg: error.code });
  }
}


export async function updateStudentPayments(data) {
  const studentId = data?.student?.id;
  if (!studentId) return;

  try {
    const studentPayments = data?.student?.payments || [];
    const studentPaymentsId = studentPayments.map((item) => item.id);
    const paymentId = data.id;
    if (studentPaymentsId.includes(paymentId)) return;
    saveEntity({ entity: 'Student', values: { studentPaymentsId: [...studentPaymentsId, paymentId], id: studentId }, id: studentId });
  } catch (error) {
    console.log('updateStudentPayments -> error', error)
    return response({ success: false, msg: error.code });
  }
}

export async function removeStudentPayment(data) {
  const studentId = data?.student?.id;
  if (!studentId) return;

  try {
    const studentPayments = data?.student?.payments || [];
    const studentPaymentsId = studentPayments.map((item) => item.id);
    const paymentId = data.id;
    const index = studentPaymentsId.indexof(paymentId);
    if (index === -1) return;
    studentPaymentsId.splice(index, 1);
    saveEntity({ entity: 'Student', values: { studentPaymentsId, id: studentId }, id: studentId });
  } catch (error) {
    console.log('updateStudentPayments -> error', error)
    return response({ success: false, msg: error.code });
  }
}
