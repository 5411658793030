import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';

const appUrl = process.env.REACT_APP_FRONT_URL_BASE;

const PartnerUrl = (props) => {
  const partner = props?.watch();
  const partnerId = partner?.id || '';
  const link = `${appUrl}/on-boarding?code=${partnerId}`;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Dirección del onboarding para el partner" />
          <Divider />
          <CardContent>
            <Typography>
              {
                partnerId ? (
                  <a href={link} target="_blank" rel="noreferrer noopener">
                    {link}
                  </a>
                ) : 'Completar los datos para obtener la dirección'
              }
              
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

PartnerUrl.propTypes = {
  watch: PropTypes.func.isRequired,
};

export default PartnerUrl;
