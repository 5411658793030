import React, { useState } from 'react';
import { matchSorter } from 'match-sorter';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useGraphList from '../../../../entities/hooks/useGraphList';

const ALL_NONE_OPTION = { id: null, company: 'None/All' };

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  }  
}));

const filterOptions = (options, { inputValue }) => {
  const filteredOptions = matchSorter(options, inputValue, { threshold: matchSorter.rankings.WORD_STARTS_WITH, keys: ['company', 'department'] });
  return options.some(o => o.id === null) ? [ALL_NONE_OPTION, ...filteredOptions] : filteredOptions;
}
  
const getSelectedOptions = (options, value) => {
  return value ? options.filter(op => op.id === value.id)[0] : null;
};

const SearchVacancy = ({ field, handleChange, newRecommended }) => {
  const { label, relatedEntity } = field
  
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const { data, loading } = useGraphList({entity: relatedEntity, getAll: true, sorted: true});

  const handleAutocompleteChange = (event, value) => {
    handleChange(value || null);
  };

  const getOptionLabel = (option) => {
    return `${option.company} - ${option.department}`;
  };

  const autocompleteOptions = [ALL_NONE_OPTION, ...data];
  const selectedOption = getSelectedOptions(autocompleteOptions, newRecommended);
  
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Autocomplete
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, value) => option && value ? option.id === value.id : false}
        id="asynchronous-demo"
        loading={loading}
        onChange={handleAutocompleteChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        options={autocompleteOptions}
        value={selectedOption}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  )
}
 
export default SearchVacancy;
