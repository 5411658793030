import React from 'react';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import SchemaManager from '../../../schema';

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const FormTextField = ({ entity, errors, field, type='text', control, rules, disabled }) => {
  const { label, name } = field
  const errorMessage = errors && errors.type && ERROR_MESSAGES[errors.type];
  const multiline = field.name.slice(0, 8) === 'richText';
  const fieldDefaultValue = SchemaManager.getDefaultValue(entity, name);
  
  if (name === 'docpatternId') return null;

  // AWS dates can not be empty strings
  let defaultValue = fieldDefaultValue;
  if (!defaultValue && type === 'number') defaultValue = 0;
  else if (!defaultValue && type === 'date') defaultValue = null;
  else defaultValue = '';

  return (
    <Controller
      as={TextField}
      control={control}
      defaultValue={defaultValue}
      disabled={disabled}
      error={!!errors}
      fullWidth
      helperText={errorMessage}
      label={label || name}
      multiline={multiline}
      name={name}
      rows={8}
      rules={rules}
      type={type}
      variant="outlined"
      InputLabelProps={type === 'date' && { shrink: true }} // Firefox date label bug
    />
  )
}
 
export default FormTextField;