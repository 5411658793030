import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';


const PastInternships = (props) => {
  const { user } = props;
  const pastInternships = user?.pastInternships || [];

  return (
    <Grid container spacing={4}>
      {
        pastInternships.map((item, index) => {
          const { vacancy = {}, initDate, endDate } = item;
          const { id, company, companyName, country, department, descriptionLang, type, zonaGeografica } = vacancy;
          const dateInit = moment(new Date(initDate)).format('DD/MM/YYYY');
          const dateEnd = moment(new Date(endDate)).format('DD/MM/YYYY');

          return (
            <Grid item sm={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="overline" display="block" gutterBottom>
                    <Link to={`/vacancy/${id}`}>
                      Enlace a la vacante
                    </Link>
                  </Typography>

                  <Typography variant="h5"gutterBottom>
                    {type} - {company} - {companyName}
                  </Typography>

                  <Typography variant="overline" display="block" gutterBottom>
                    {department}
                  </Typography>

                  <Typography variant="body1" component="p"gutterBottom>
                    {initDate ? dateInit : ''} - { endDate ? dateEnd : '' }
                  </Typography>

                  <Typography variant="body1" component="p"gutterBottom>
                    {country} - {zonaGeografica}
                  </Typography>

                  <Typography variant="body1" component="p"gutterBottom>
                    {descriptionLang.es}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        })
      }
    </Grid>
  );
};

PastInternships.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default PastInternships;
