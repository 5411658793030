const getMonthsBetween = (dateInit, dateEnd) => {
  const initDate = new Date(dateInit);
  const endDate = new Date(dateEnd);

  const yearsDifference = endDate.getFullYear() - initDate.getFullYear();
  const monthsDifference = endDate.getMonth() - initDate.getMonth();
  // const monthCorrection = endDate.getDate() > initDate.getDate() ? 1 : 0;

  return yearsDifference * 12 + monthsDifference + 1;
};


const getInsurancePayment = (user, initDate, endDate, insuranceItem) => { 
  const { price, id } = insuranceItem;
  const months = getMonthsBetween(initDate, endDate);
  const amount = (price * months).toFixed(0);
  const codeNumber = (user?.pastInternships?.length || 0) + 1;
  const paymentInsurance = {
    amount,
    code: `${user.code}/${user.number}/insurance/${codeNumber}`,
    currency: 'EUR',
    dateBefore: new Date(initDate),
    paymentServiceId: id,
    quotaName: 'insurance',
    status: 'pending',
    paymentStudentId: user.id,
  };
  return paymentInsurance;
};

export { getInsurancePayment, getMonthsBetween };



