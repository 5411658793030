import React, { useEffect, useState } from 'react';
import moment from 'moment';
import uuid from 'uuid/v1';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';

import { uploadFile, getPdfURL } from '../../../../entities/services/storage';
import { getEntity, saveEntity } from '../../../../entities/services/entities';
import ModalWarningEdit from '../ModalWarning/ModalWarningEdit';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    padding: theme.spacing(6),
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  pdfLink: {
    display: 'block',
    margin: theme.spacing(2),
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    }
  }
}));

const NewDocumentModal = (props) => {
  const { user, document, index = -1, title, onUpdate } = props;
  const { id, documents } = user;
  const name = 'documentFile';
  const classes = useStyles();
  
  const [isOpen, setIsOpen] = useState(false);
  const [newDocument, setNewDocument] = useState({ name: '', documentFile: '', status: 'pending', dateEnd: null });
  const [showModal, setShowModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [backofficeEdits, setBackofficeEdits] = useState({});

  useEffect(() => {
    if (document) {
      setNewDocument(document);
    }
  }, [document, isOpen]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true); 

      const timestamp = +new Date();
      const filename = `${timestamp}-${file.name}`.replace(/\s/g, '-').replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi, '-');
      const fileLocation = `docpattern/${name}/${filename}`;
      const { success } = await uploadFile(file, fileLocation);
      
      if (success) {
        setNewDocument(prevDocument => ({ ...prevDocument, documentFile: filename }));
        setBackofficeEdits(prevEdits => ({ ...prevEdits, [newDocument.docpatternId]: true }));
      }
      
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data: fetchedStudentData } = await getEntity('student', id);
    const { documents: fetchedDocuments } = fetchedStudentData;
    const updatedValues = { ...newDocument, dateEnd: newDocument.dateEnd ? new Date(newDocument.dateEnd) : null };
 
    const updatedDocuments = index !== -1
      ? documents?.map((doc, idx) => idx === index ? updatedValues : doc)
      : [...(documents || []), updatedValues];

    const hasChanges = updatedDocuments.some(newDoc => {
      const correspondingFetchedDoc = fetchedDocuments?.find(fetchedDocument =>
        fetchedDocument.docpatternId === newDoc.docpatternId
      );
  
      if (!correspondingFetchedDoc || newDoc.responsible === 'nexus' || backofficeEdits[newDoc.docpatternId]) return false;
  
      const oldDocFile = correspondingFetchedDoc.documentFile || null;
      const newDocFile = newDoc.documentFile || null;
  
      return !(!oldDocFile && newDocFile) && newDocFile !== oldDocFile;
    });

    if (hasChanges) {
      return setShowModal(true);
    }

    const formattedDocuments = updatedDocuments.map((doc) => {
      const { dateEnd, docpatternId, responsible, status } = doc;
      if (typeof dateEnd !== 'string') {
        doc.dateEnd = moment(dateEnd).format('YYYY-MM-DD');
      }
      if (dateEnd === null) {
        delete doc.dateEnd;
      }

      return {
        ...doc,
        docpatternId: docpatternId ?? uuid(),
        status: responsible === 'nexus' ? 'accepted' : status,
        responsible: responsible ?? 'nexus',
      };
    });

    const response = await saveEntity({ entity: 'student', values: { documents: formattedDocuments, id }, id });
    const { success, data } = response;
    if (success) {
      onUpdate(data);
      setIsOpen(false);
      setNewDocument({ name: '', documentFile: '', status: 'pending', dateEnd: null });
      setBackofficeEdits(prevEdits => {
        const newEdits = { ...prevEdits };
        delete newEdits[document.docpatternId];
        return newEdits;
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setNewDocument({ name: '', documentFile: '', status: 'pending', dateEnd: null });
  };

  const fileUrl = newDocument?.documentFile ? getPdfURL(`docpattern/${name}/${newDocument?.documentFile}`) : '';

  return (
    <>
      <Button size="small" color="primary" onClick={() => setIsOpen(true)}>
        {title}
      </Button>
      <Modal
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.modalPaper}>
          <form onSubmit={handleSubmit}>
            <CardHeader title="Añadir documento" />
            <CardContent>
              <TextField
                defaultValue={document?.name || ''}
                fullWidth
                label="Nombre"
                name="name"
                type="text"
                variant="outlined"
                className={classes.textField}
                onChange={(e) => setNewDocument({ ...newDocument, name: e.target.value })}
              />

              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.textField}>Subir archivo </Typography>
                  <input
                    type="file"
                    onChange={handleFileUpload}
                    className={classes.textField}
                  // defaultValue={document?.documentFile || ''}
                  />
                </Grid>
                {
                  fileUrl && (
                    <Grid item xs={12}>
                      <Typography className={classes.textField}>
                        <a className={classes.pdfLink} onClick={() => window.open(`${fileUrl}`)} target="_blank">VER ARCHIVO</a>
                      </Typography>
                    </Grid>
                  )
                }
              </Grid>

              <TextField
                defaultValue={document?.dateEnd || null}
                fullWidth
                label="Antes de"
                name="dateEnd"
                type="date"
                variant="outlined"
                className={classes.textField}
                InputLabelProps={{ shrink: true }} // Firefox date label bug
                onChange={(e) => setNewDocument({ ...newDocument, dateEnd: e.target.value })}
              />


              <TextField
                select
                className={classes.textField}
                variant="outlined"
                label="Estado del documento"
                fullWidth
                defaultValue={document?.status || 'pending'}
                name="status"
                onChange={(e) => setNewDocument({ ...newDocument, status: e.target.value })}
              >
                <MenuItem value="pending">Pendiente</MenuItem>
                <MenuItem value="received">Recibido</MenuItem>
                <MenuItem value="inReview">En revisión</MenuItem>
                <MenuItem value="accepted">Aceptado</MenuItem>
                <MenuItem value="rejected">Rechazado</MenuItem>
              </TextField>

              <TextField
                label="Razón"
                defaultValue={document?.statusReason || ''}
                name="statusReason"
                onChange={(e) => setNewDocument({ ...newDocument, statusReason: e.target.value })}
                className={classes.textField}
                variant="outlined"
                fullWidth
              />

            </CardContent>

            <CardActions>
              <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => setIsOpen(false)}
                    size="large"
                    type="button"
                    variant="contained"
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={isUploading}
                  >
                    {isUploading ? 'Loading...' : 'Guardar'}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </Card>
      </Modal>
      <ModalWarningEdit showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

NewDocumentModal.propTypes = {};

NewDocumentModal.defaultProps = {};

export default NewDocumentModal;
