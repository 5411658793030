import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import HSpacer from '../../../../entities/components/HSpacer';
import ErrorBoundary from '../../../../components/ErrorBoundary';


const useStyles = makeStyles(theme => ({
  inner: {
    paddingTop: 20,
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  popoverContainer: {
    padding: theme.spacing(1)
  },
  rowImage: {
    maxWidth: '50px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageNumber: {
    padding: theme.spacing(2)
  },
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none',
  },
  innerTable: {
    padding: '10px 0',
  },
  innerCell: {
    border: '1px solid rgba(0, 0, 255, 0.2)',
    padding: '10px',
  },
  pending: {
    backgroundColor: '#ff9800',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  },
  inReview: {
    backgroundColor: '#4d4d4d',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  },
  error: {
    backgroundColor: '#f44336',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  },
  done: {
    backgroundColor: '#4caf50',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    height: '18px',
  }
}));

const quotaNameDictionary = {
  firstPayment: 'Primer pago',
  secondPayment: 'Segundo pago',
  insurance: 'Seguro',
};

const statusDictionary = {
  pending: { tagText: 'Pendiente', tagColor: 'pending' },
  inReview: { tagText: 'En revisión', tagColor: 'inReview'},
  error: { tagText: 'Error', tagColor: 'error' },
  done: { tagText: 'Pagado', tagColor: 'done' },
};

const PaymentsTable = (props) => {
  const {
    hasNextPage, loading, onNextPage,
    onPrevPage, page, users,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const handleEditItem = (id) => {
    history.push(`/users/${id}`);
  };

  const renderUserPaymentList = (payments) => {
    if (!payments) return null;
    return (
      <Table>
        {
          payments.map((payment) => {
            const { amount, quotaName, status } = payment;
            const title = quotaNameDictionary[quotaName];
            const { tagText, tagColor } = statusDictionary[status];
            if (!amount) return;
        
            return (
              <TableRow>
                <TableCell className={classes.innerCell}>{title || 'Pago'}</TableCell>
                <TableCell className={classes.innerCell}>{amount}</TableCell>
                <TableCell className={classes.innerCell}>
                  <Chip
                    label={tagText}
                    size="small"
                    className={classes[tagColor]}
                  />
                </TableCell>
              </TableRow>
            )
          })
        }
      </Table>
    );
  };

  return (
    <>
      <Card
        className={clsx(classes.root)}
      >
        <CardContent className={classes.content} style={{maxHeight: "60vh", overflow: 'auto'}}>

            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Alumno</TableCell>
                    <TableCell>Código</TableCell>
                    <TableCell>Pagos</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ErrorBoundary>
                    {
                      loading
                        ? <TableRow>Cargando</TableRow>
                        : (users.map((user) => {
                          const paymentsTable = renderUserPaymentList(user.payments);

                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={user.id}
                            >
                              <TableCell>{user.name} {user.lastname}</TableCell>
                              <TableCell>{user.code}/{user.number}</TableCell>
                              <TableCell className={classes.innerTable}>{paymentsTable}</TableCell>

                              <TableCell>
                                <IconButton
                                  color="inherit"
                                  onClick={() => handleEditItem(user.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        }))
                    }
                  </ErrorBoundary>
                </TableBody>
              </Table>
            </div>
       </CardContent>
      </Card>

      <HSpacer />

      <ErrorBoundary>
        {(page > 0 || hasNextPage) && (
          <Card className={clsx(classes.root)} p={4}>
            <CardContent className={classes.content}>
              <Grid container item justifyContent="flex-end" align="center" xs={12} spacing={2}>
                {page > 0 && (
                  <Button
                    color="primary"
                    onClick={onPrevPage}
                    type="submit"
                    variant="contained"
                  >
                    Anterior
                  </Button>
                )}
                <Typography className={classes.pageNumber}>{page + 1}</Typography>
                {hasNextPage && (
                  <Button
                    color="primary"
                    onClick={onNextPage}
                    type="submit"
                    variant="contained"
                  >
                    Siguiente
                  </Button>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </ErrorBoundary>
    </>
  );
};

PaymentsTable.propTypes = {
  users: PropTypes.array.isRequired
};

export default PaymentsTable;
