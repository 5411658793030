const REGION = 'eu-central-1';

export default {
  Auth: {
    userPoolId: 'eu-central-1_QzZpNfA1g',
    userPoolWebClientId: '6s9oaqdaodnkj0e8micckacucb',
    region: REGION,
    identityPoolId: 'eu-central-1:e9914239-cbdc-4b61-b81f-5e11ec221a55',
  },
  API: {
    aws_project_region: 'eu-central-1',
    aws_appsync_graphqlEndpoint:
      'https://kce6yajaljdkxkejmke46hji7q.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  Storage: {
    bucket: 'nexus-backend-nexus-prod-platform-bucket-767397827072',
    imagesCloudfront: '',
    region: 'eu-central-1',
    identityPoolId: 'eu-central-1:e9914239-cbdc-4b61-b81f-5e11ec221a55',
  },
  REGION
};
