import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getMonthsBetween, getInsurancePayment } from '../../helpers'
import { saveEntity } from '../../../../entities/services/entities';
import { getInsuranceItem } from '../../../../entities/services/entitiesCustom';

const useStyles = makeStyles(theme => ({
  modalPaper: {
    padding: theme.spacing(6),
    minWidth: '500px',
  },
}));

const AddInsurance = (props) => {
  const { user, setIsOpen, onUpdate } = props;
  const { internship, payments = [] } = user;

  const [insuranceItem, setInsuranceItem] = useState(''); 
  const [error, setError] = useState(false);

  const classes = useStyles();

  const { initDate, endDate } = internship;
  const insurancePrice = insuranceItem?.price;
  const months = getMonthsBetween(initDate, endDate);
  const total = months * insurancePrice;

  useEffect(() => {
    if (!user) return;
    setError(false);
    const getInsurancePrice = async () => {
      const { success, data} = await getInsuranceItem();
      if (success) {
        setInsuranceItem(data?.items?.[0]);
      } else setError(true);
    }
    getInsurancePrice();
  }, [user]);

  const onAccept = async () => {
    const insurancePayment = getInsurancePayment(user, initDate, endDate, insuranceItem);
    const response = await saveEntity({ entity: 'payment', values: insurancePayment });
    const { success, msg, data } = response;
    if (success) {
      const newPayments = [];
      user.payments.forEach((item) => {
        console.log(item?.id)
        if (item?.id) newPayments.push(item.id);
      });
      const studentPaymentsId = [...newPayments, data.id];
      const result = await saveEntity({ entity: 'student', values: { studentPaymentsId, id: user.id }, id: user.id });
      setIsOpen(false);
      onUpdate(result.data);
    }
  };

  return (
    <Card className={classes.modalPaper}>
      <CardHeader title="Añadir pago del seguro" />
      {
        initDate && endDate ? (
          <CardContent>
            <Typography>
              Fecha de inicio de las prácticas: {moment(initDate).format('DD/MM/YYYY')}
            </Typography>
            <Typography>
              Fecha final de las prácticas: {moment(endDate).format('DD/MM/YYYY')}
            </Typography>
            <Typography>
              Meses contabilizados: {months}
            </Typography>
            <Typography>Precio por mes: 45€</Typography>
            <Typography>Precio total: {total}€</Typography>
          </CardContent>
        ) : (
          <CardContent>
            <Typography>Fechas no indicadas</Typography>
          </CardContent>
        )
      }
      
      <CardActions>
        <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              color="secondary"
              fullWidth
              onClick={() => setIsOpen(false)}
              size="large"
              type="button"
              variant="contained"
            >
              Cancelar
            </Button>
          </Grid>
          {
            initDate && endDate && (
              <Grid item xs={6}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={onAccept}
                >
                  Crear pago
                </Button>
              </Grid>
            )
          }
          
        </Grid>
      </CardActions>
    </Card>
  );
};

AddInsurance.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    payments: PropTypes.array,
    internship: PropTypes.object,
    id: PropTypes.string,
  }).isRequired,
};

AddInsurance.defaultProps = {};

export default AddInsurance;
