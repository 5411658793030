import React from 'react';
import { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';

import SchemaManager from '../../../../entities/schema';
import TableImport from '../../../../entities/components/table/TableImport';
import TableExport from '../../../../entities/components/table/TableExport';
import FilterSelectEntity from '../../../../entities/components/table/GenericTable/FilterSelectEntity';
import FilterSelectEnum from '../../../../entities/components/table/GenericTable/FilterSelectEnum';
import FilterString from '../../../../entities/components/table/GenericTable/FilterString';
import FilterRange from '../../../../entities/components/table/GenericTable/FilterRange';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { SearchInput } from '../../../../components';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    // height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const getUniversityNames = (data) => {
  const universities = data || [];
  const universityNames = universities.map((university) => university?.name).filter(Boolean);
  return universityNames.length ? universityNames.join(', ') : '';
}

const fieldsTransform = (data, entity) => {
  const dataFiltered = Object.keys(data).reduce((dataAgg, field) => {
    if (typeof data[field] === 'undefined' || data[field] === null) return dataAgg;
    return { 
      ...dataAgg,
      [field]: data[field]
    }
  }, {});

  if (entity === 'Vacancy') {
    const dataTransformed = {
      ...dataFiltered,
      vacancyCountryId: data?.country?.id ?? 0,
      'descriptionLang.es': data?.descriptionLang?.es ?? '',
      'descriptionLang.en': data?.descriptionLang?.en ?? '',
      'benefitMealLang.es': data?.benefitMealLang?.es ?? '',
      'benefitMealLang.en': data?.benefitMealLang?.en ?? '',
      'richTextObsLang.es': data?.richTextObsLang?.es ?? '',
      'conditionsLang.es': data?.conditionsLang?.es ?? '',
      'datesOpen[0].dateIni': data?.datesOpen?.[0]?.dateIni ?? '',
      'datesOpen[0].dateEnd': data?.datesOpen?.[0]?.dateEnd ?? '',
      'datesOpen[1].dateIni': data?.datesOpen?.[1]?.dateIni ?? '',
      'datesOpen[1].dateEnd': data?.datesOpen?.[1]?.dateEnd ?? '',
    }
  
    delete dataTransformed.datesOpen;
    delete dataTransformed.richTextObsLang;
    delete dataTransformed.country;
  
    return dataTransformed;
  }

  if (entity === 'Price') {
    const dataTransformed = {
      ...dataFiltered,
      'destination': data?.destination?.nameLang.es ?? '',
      'residence': data?.residence?.nameLang.es ?? '',
      'service': data?.service?.key ?? '',
      'universities': getUniversityNames(data?.universities),
      'eu': data?.eu ? 'TRUE' : 'FALSE',
      'graduate': data?.graduate ? 'TRUE' : 'FALSE',
    }
  
    return dataTransformed;
  }

  if (entity === 'Country') {
    const dataTransformed = {
      ...dataFiltered,
      'nameLang.es': data?.nameLang?.es,
    }
    delete dataTransformed.nameLang; 
    return dataTransformed;
  }

  return dataFiltered;
}

const GenericToolbar = (props) => {
  const { entity, filters, setFilters, tableFields } = props;

  const history = useHistory();
  const classes = useStyles();
  const hasImport = SchemaManager.ENTITIES_WITH_IMPORT.includes(entity);
  const hasExport = SchemaManager.ENTITIES_WITH_EXPORT.includes(entity);
  const canCreate = !SchemaManager.CREATE_EXCLUDE_ENTITIES.includes(entity);
  const filtersFields = SchemaManager.TABLE_FILTERS?.[entity] || [];
  const filtersList = filtersFields.map((el) => Object.keys(el)[0]);

  let timefired = null;

  const filteredTableFields = [];
  filtersList.forEach((el) => {
    if (el === 'search') {
      filteredTableFields.push({ name: 'search', filter: { type: 'search' } });
      return;
    }
    const newFilter = tableFields.find((f) => f.name === el);
    if (newFilter) filteredTableFields.push(newFilter);
  });


  //const onChangeSearchString = (e) => {
    //const value = e.target.value;
    //clearTimeout(timefired);
    //timefired = setTimeout(function () {
    //  if (value) setFilters({ ...filters, search: value });
    //  else {
    //    delete filters.search;
    //    setFilters({ ...filters });
    //  }
    //}, 600);
  //};
  const normalizeString = (str) => {
    return str
      .normalize('NFD') // Descompone los caracteres acentuados en sus componentes
      .replace(/[\u0300-\u036f]/g, '') // Elimina los acentos
      .toLowerCase(); // Convierte a minúsculas
  };

  const debounceTimeout = useRef(null);

  const onChangeSearchString = useCallback((e) => {
    e.persist();
    const value = normalizeString(e.target.value);
  
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  
    debounceTimeout.current = setTimeout(() => {
      if (value){

       setFilters((prevFilters) => ({
        ...prevFilters,
        search: value
      }));
      }else {
          setFilters({ ...filters });
      }
    }, 600);
  }, []);

  return (
    <div
      className={clsx(classes.root)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {hasImport && <TableImport entity={entity} />}
        {hasExport && <TableExport key={entity} entity={entity} fieldsTransform={fieldsTransform} />}
        {/* <Button className={classes.exportButton}>Enviar mensaje</Button> */}
        {/* <Button className={classes.exportButton}>Exportar</Button> */}
        {
          canCreate && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push(`/${entity.toLowerCase()}/0`)}
            >
              Nuevo
            </Button>
          )
        }
      </div>

      <div className={classes.row}>
        <Grid container spacing={2} alignItems="center">
          {
            filteredTableFields.map((field) => {
              const { name, filter } = field;
              let filterComponent = '';

              if (filter?.type === 'search') filterComponent = <SearchInput key={name} placeholder="buscar" onChange={onChangeSearchString} />
              
              if (filter?.type === 'boolean') filterComponent = <FilterSelectEnum key={name} field={field} filters={filters} setFilters={setFilters} />;

              if (filter?.type === 'select' && filter.from === 'entity') {
                filterComponent = <FilterSelectEntity key={name} field={field} filters={filters} setFilters={setFilters} />;
              }
              if (filter?.type === 'select' && filter.from === 'enum') {
                filterComponent = <FilterSelectEnum key={name} field={field} filters={filters} setFilters={setFilters} />;
              }
              if (filter?.type === 'string') filterComponent = <FilterString key={name} field={field} filters={filters} setFilters={setFilters} />;
              if (filter?.type === 'range') filterComponent = <FilterRange key={name} field={field} filters={filters} setFilters={setFilters} />;

              return (
                <ErrorBoundary key={name}>
                  <Grid item xs={3}>
                    {filterComponent}
                  </Grid>
                </ErrorBoundary>);
            })
          }
        </Grid>
      </div> 
    </div>
  );
};

GenericToolbar.propTypes = {
  entity: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    search: PropTypes.string,
  }),
  setFilters: PropTypes.func,
  tableFields: PropTypes.shape().isRequired,
};

export default GenericToolbar;
