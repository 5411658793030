import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import NewDocumentModal from '../Documents/NewDocumentModal';
import NewNotificationModal from '../Notifications/NewNotificationModal';
import { getFileURL } from '../../../../entities/services/storage';
import { saveEntity } from '../../../../entities/services/entities';
import { deleteNotification } from '../../../../entities/services/entitiesCustom';
import HSpacer from '../../../../entities/components/HSpacer';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
}));


const DataTable = ({ rowHeader, data, options, user, onUpdate, rawData, field }) => {
  const classes = useStyles();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showRemoveDocModal, setShowRemoveDocModal] = useState(false);
  const [notificationToRemove, setNotificationToRemove] = useState(null);
  const [docToRemove, setDocToRemove] = useState(NaN);

  const keys = Object.keys(rowHeader);
  const labels = Object.values(rowHeader);

  const onDownFile = (row) => {
    const fileUrl = getFileURL(row.url);
    //const fileUrl = getFileURL(url);
    window.open(`${fileUrl}`);
  };
  
  const renderEditDocument = (index) => {
    return (
      <NewDocumentModal
        key={`document${index}`}
        title={<IconButton color="black"><EditIcon /></IconButton>}
        index={index}
        user={user}
        document={user?.documents?.[index] || null}
        onUpdate={onUpdate}
      />
    )
  };

  const handleRemoveDoc = (index) => {
    setDocToRemove(index);
    setShowRemoveDocModal(true);
  }

  const handleConfirmRemoveDoc = async () => {
    const { documents } = user;
    const newDocuments = [...documents];
    newDocuments.splice(docToRemove, 1);
    const response = await saveEntity({ entity: 'student', values: { documents: newDocuments, id: user.id }, id: user.id });
    const { success, data } = response;
    if (success) {
      onUpdate(data);
      setShowRemoveDocModal(false);
    }
  };

  const renderEditNotification = (notification) => {
    return (
      <NewNotificationModal
        key={notification.timestamp}
        title={<IconButton color="black"><EditIcon /></IconButton>}
        user={user}
        notification={notification}
        onUpdate={onUpdate}
      />
    )
  };

  const handleRemoveNotification = (notification) => {
    setNotificationToRemove(notification);
    setShowRemoveModal(true);
  }

  const handleConfirmRemove = async () => {
    const response = await deleteNotification(notificationToRemove);
    const { success, data } = response;
    if (success) {
      onUpdate(data);
      setShowRemoveModal(false);
    }
  };

  const renderLinkPayment = (payment) => {
    const { id } = payment;
    return (
      <Link to={`/payment/${id}`}>
        <IconButton><LinkIcon/></IconButton>
      </Link>
    )
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {
              labels.map((label) => (
                <TableCell key={label}>{label}</TableCell>
              ))
            }
            {
              options && <TableCell>Opciones</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data?.map((row, index) => {
              if (!row) return null;
              return (
                <TableRow key={`row${index}`}>
                  {
                    keys.map((key, i) => {
                      if (field === 'notifications' && key === 'content') return <TableCell dangerouslySetInnerHTML={{ __html: row[key] }} key={`cell${i}`}/>
                      return <TableCell key={`cell${i}`}>{row[key]}</TableCell>
                    })
                  }
                  {
                    options && (
                      <TableCell>
                        {
                          options?.down && row.url && (
                            <IconButton
                              color="inherit"
                              onClick={() => onDownFile(row)}
                            >
                              <CloudDownload />
                            </IconButton>
                          )
                        }
                        {options?.editDocument && renderEditDocument(index)}
                        {
                          options?.removeDocument && (
                            <IconButton
                              color="inherit"
                              onClick={() => handleRemoveDoc(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )
                        }
                        {options?.linkPayment && renderLinkPayment(row)}
                        {options?.editNotification && renderEditNotification(row)}
                        {options?.removeNotification && (
                          <IconButton
                            color="inherit"
                            onClick={() => handleRemoveNotification(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )
                  }
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>

      <Modal
        className={classes.modal}
        open={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <Typography>¿Quieres borrar este mensaje?</Typography>
          <HSpacer />
          <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
            <Grid item xs={6}>
              <Button
                color="secondary"
                fullWidth
                onClick={() => setShowRemoveModal(false)}
                size="large"
                type="button"
                variant="contained"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                fullWidth
                onClick={handleConfirmRemove}
                size="large"
                type="submit"
                variant="contained"
              >
                Borrar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>

      <Modal
        className={classes.modal}
        open={showRemoveDocModal}
        onClose={() => setShowRemoveDocModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <Typography>¿Quieres quitar este documento?</Typography>
          <HSpacer />
          <Grid container item justifyContent="flex-end" xs={12} spacing={2}>
            <Grid item xs={6}>
              <Button
                color="secondary"
                fullWidth
                onClick={() => setShowRemoveDocModal(false)}
                size="large"
                type="button"
                variant="contained"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                fullWidth
                onClick={handleConfirmRemoveDoc}
                size="large"
                type="submit"
                variant="contained"
              >
                Borrar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  )
}
 
export default DataTable;