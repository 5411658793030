import { useState, useEffect } from 'react';
import { getEntity } from '../services/entities';
import { getRelatedEntityFieldName } from '../schema';

function getFieldValue(field, dataField) {
  if (field.type === 'subObjectList') {
    return dataField || [];
  }
  if (field.type !== 'listFree' && Array.isArray(dataField)) {
    // Avoid null values in the array
    const data = [];
    dataField.forEach((df) => {
      if (df) data.push(df.id)
    });
    return data;
  }
  if (dataField && typeof dataField === 'object' && dataField.id) {
    return dataField.id
  }
  return dataField;
}

function getFieldName(entity, data, field) {
  const fieldName = field.name;

  if (field.type === 'subObjectList') {
    return fieldName;
  }
  if (field.type !== 'listFree' && Array.isArray(data[fieldName])) {
    return getRelatedEntityFieldName(entity, fieldName);
  }
  if (data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName].id) {
    return getRelatedEntityFieldName(entity, fieldName);
  }
  return fieldName;
}

const useGraphGet = (entity, id, fields) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState(null);

  useEffect(() => {
    const fetchEntity = async () => {
      const { success, msg, data } = await getEntity(entity, id);
      if (success) {
        const formData = fields.map(f => {
          const fieldName = getFieldName(entity, data, f);
          const fieldValue = getFieldValue(f, data[f.name])
          return {[fieldName]: fieldValue}
        });
        setData(formData);
        setRawData(data);
      } else {
        setError(msg)
      }
      setLoading(false);
    }
    fetchEntity();
  }, [entity, fields]);

  if (entity === 'student') {
    return { loading, error, data, rawData };
  }

  return { loading, error, data };
}

export default useGraphGet;
