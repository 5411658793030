import React, { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/styles';
import {
  TextField,
  Button,
  Grid
} from '@material-ui/core';

import FormEntitySelectField from '../../../../entities/components/formFields/FormEntitySelectField';
import { saveEntity } from '../../../../entities/services/entities';


const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  doubleTextField: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const CurrentStudy = (props) => {
  const { user, loading, error, rawUser } = props;
  const formRef = useRef();
  const classes = useStyles();

  const { handleSubmit, errors, setValue, control, register, getValues, watch } = useForm();

  useEffect(() => {
    if (user) setValue(user);
  }, [user]);
  
  const handleFormSubmit = async (values, event) => {
    if (formRef.current !== event.target) return null;
    const newValues = { ...values, id: rawUser.id };
    const country = values?.currentStudy?.country?.id;
    if (country) {
      delete newValues.currentStudy.country;
      newValues.currentStudy.studyCountryId = country;
    }

    // AWS dates can not be empty string
    if (values?.currentStudy?.iniDate === '') newValues.currentStudy.iniDate = null;
    if (values?.currentStudy?.endDate === '') newValues.currentStudy.endDate = null;
    if (values?.currentStudy?.gradDate === '') newValues.currentStudy.gradDate = null;

    const response = await saveEntity({ entity: 'student', values: newValues, id: rawUser.id });
    const { success, msg, data } = response;
  }

  return (
    <form
      ref={formRef}
      className={classes.form}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className={classes.textField}>
        <FormEntitySelectField
          entityFieldName="studentUniversityId"
          field={{ name: 'university', label: 'Universidad', relatedEntity: 'University', type: 'entitySelect' }}
          fullWidth
          register={register}
          setValue={setValue}
          watch={watch}
        />
      </div>

      <div className={classes.textField}>
        <FormEntitySelectField
          entityFieldName="currentStudy.country.id"
          field={{ name: 'currentStudy.country.id', label: 'País', relatedEntity: 'Residence', type: 'entitySelect' }}
          fullWidth
          register={register}
          setValue={setValue}
          watch={watch}
        />
      </div>

      <Grid container>
        <Grid item md={6} xs={12}>
          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.currentStudy?.region}
            fullWidth
            // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Estado/Región"
            name="currentStudy.region"
            type="text"
            variant="outlined"
            className={classes.doubleTextField}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.currentStudy?.city}
            fullWidth
            // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Ciudad"
            name="currentStudy.city"
            type="text"
            variant="outlined"
            className={classes.textField}
          />
        </Grid>
      </Grid>

      <Controller
        as={TextField}
        control={control}
        defaultValue=""
        error={!!errors?.currentStudy?.address}
        fullWidth
        // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
        label="Dirección"
        name="currentStudy.address"
        type="text"
        variant="outlined"
        className={classes.textField}
      />

      <Controller
        as={TextField}
        control={control}
        defaultValue=""
        error={!!errors?.currentStudy?.cp}
        fullWidth
        // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
        label="Código postal"
        name="currentStudy.cp"
        type="text"
        variant="outlined"
        className={classes.textField}
      />

      <Grid container>
        <Grid item md={6} xs={12}>
          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.currentStudy?.iniDate}
            fullWidth
            // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Inicio"
            name="currentStudy.iniDate"
            type="date"
            variant="outlined"
            className={classes.doubleTextField}
            InputLabelProps={{shrink: true}} // Firefox date label bug
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.currentStudy?.endDate}
            fullWidth
            // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Fin previsto"
            name="currentStudy.endDate"
            type="date"
            variant="outlined"
            className={classes.textField}
            InputLabelProps={{shrink: true}} // Firefox date label bug
          />
        </Grid>
      </Grid>

      <Controller
        as={TextField}
        control={control}
        defaultValue=""
        error={!!errors?.currentStudy?.gradDate}
        fullWidth
        // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
        label="Titulación prevista"
        name="currentStudy.gradDate"
        type="text"
        variant="outlined"
        className={classes.textField}
        InputLabelProps={{shrink: true}} // Firefox date label bug
      />
            
      <Controller
        as={TextField}
        control={control}
        defaultValue=""
        error={!!errors?.tutor?.name}
        fullWidth
        // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
        label="Nombre tutor"
        name="tutor.name"
        type="text"
        variant="outlined"
        className={classes.textField}
      />

      <Controller
        as={TextField}
        control={control}
        defaultValue=""
        error={!!errors?.tutor?.lastname}
        fullWidth
        // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
        label="Apellido tutor"
        name="tutor.lastname"
        type="text"
        variant="outlined"
        className={classes.textField}
      />

      <Controller
        as={TextField}
        control={control}
        defaultValue=""
        error={!!errors?.tutor?.email}
        fullWidth
        // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
        label="Email tutor"
        name="tutor.email"
        type="text"
        variant="outlined"
        className={classes.textField}
      />

      <Controller
        as={TextField}
        control={control}
        defaultValue=""
        error={!!errors?.tutor?.phone}
        fullWidth
        // helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
        label="Teléfono tutor"
        name="tutor.phone"
        type="text"
        variant="outlined"
        className={classes.textField}
      />

      <Button
        className={classes.signInButton}
        color="primary"
        // disabled={!isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Guardar
      </Button>
    </form>
  )
}
 
export default CurrentStudy;