import capitalize from '@bit/iamtechnologies.iamtech-js.capitalize';
// console.log(data.__schema.types);

const DEFAULT_CREATE_EXCLUDE_FIELDS = ['id', 'order', 'createdAt', 'updatedAt'];
const DEFAULT_CREATE_EXCLUDE_ENTITIES = [];
const DEFAULT_TABLE_FIELDS_EXCLUDES = ['subObjectList', 'order'];
const DEFAULT_TABLE_FILTERS = [];
const DEFAULT_DB_STRING_FILTERS = [];
const DEFAULT_ENTITIES_WITH_MANUAL_IDS = [];
const DEFAULT_ENTITIES_WITH_PREVIEW = [];
const DEFAULT_ENTITIES_WITH_IMPORT = [];
const DEFAULT_ENTITIES_WITH_EXPORT = [];
const DEFAULT_LABELS_OVERRIDE = {};
const DEFAULT_SPECIFIC_GROUP_FIELDS = {}
const DEFAULT_TRANSLATION_LANGUAGES = ['es'];
const DEFAULT_DEPENDENT_FIELDS = {};
const DEFAULT_RICHTEXT_FIELDS = [];
const DEFAULT_CACHED_ENTITIES = [];
const DEFAULT_FIELDS_FULLWIDTH = {};
const DEFAULT_EXTRA_COMPONENTS = {};
const DEFAULT_FIELDS_BREAKLINES = {};
const DEFAULT_DEFAULT_VALUES = {};
const DEFAULT_SUBOBJECT_VALIDATIONS = {};
const DEFAULT_CUSTOM_COMPONENTS = {};
const DEFAULT_FIELDS_BACKGROUND = {};
const DEFAULT_FIELD_GROUPS = {};

class SchemaManager {
  static TYPES;
  static CREATE_EXCLUDE_FIELDS;
  static CREATE_EXCLUDE_ENTITIES;
  static ENTITIES_WITH_IMPORT;
  static ENTITIES_WITH_EXPORT;
  static TABLE_FIELDS_EXCLUDES;
  static TABLE_FILTERS;
  static DEFAULT_DB_STRING_FILTERS
  static ENTITIES_WITH_MANUAL_IDS;
  static ENTITIES_WITH_PREVIEW;
  static SPECIFIC_GROUP_FIELDS;
  static TRANSLATION_LANGUAGES;
  static DEPENDENT_FIELDS;
  static RICHTEXT_FIELDS;
  static CACHED_ENTITIES;
  static LABELS_OVERRIDE;
  static FIELDS_FULLWIDTH;
  static EXTRA_COMPONENTS;
  static FIELDS_BREAKLINES;
  static DEFAULT_VALUES;
  static CUSTOM_COMPONENTS;
  static FIELDS_BACKGROUND;
  static FIELD_GROUPS;
  static queries;
  static mutations;
  static awsConfig;

  static configure ({
    data,
    createExcludeFields = DEFAULT_CREATE_EXCLUDE_FIELDS,
    createExcludeEntities = DEFAULT_CREATE_EXCLUDE_ENTITIES,
    entitiesImport = DEFAULT_ENTITIES_WITH_IMPORT,
    entitiesExport = DEFAULT_ENTITIES_WITH_EXPORT,
    tableExcludesFields = DEFAULT_TABLE_FIELDS_EXCLUDES,
    tableFilters = DEFAULT_TABLE_FILTERS,
    dbStringFilters = DEFAULT_DB_STRING_FILTERS,
    entitiesWithManualIds = DEFAULT_ENTITIES_WITH_MANUAL_IDS,
    entitiesWithPreview = DEFAULT_ENTITIES_WITH_PREVIEW,
    labelsOverride = DEFAULT_LABELS_OVERRIDE,
    specificGroupFields = DEFAULT_SPECIFIC_GROUP_FIELDS,
    translationLanguages = DEFAULT_TRANSLATION_LANGUAGES,
    dependentFields = DEFAULT_DEPENDENT_FIELDS,
    richTextFields = DEFAULT_RICHTEXT_FIELDS,
    cachedEntities = DEFAULT_CACHED_ENTITIES,
    fieldsFullWidth = DEFAULT_FIELDS_FULLWIDTH,
    extraComponents = DEFAULT_EXTRA_COMPONENTS,
    fieldsBreakLineAfter = DEFAULT_FIELDS_BREAKLINES,
    fieldsBreakLineBefore = DEFAULT_FIELDS_BREAKLINES,
    subObjectValidations = DEFAULT_SUBOBJECT_VALIDATIONS,
    defaultValues = DEFAULT_DEFAULT_VALUES,
    customComponents = DEFAULT_CUSTOM_COMPONENTS,
    fieldsBackground = DEFAULT_FIELDS_BACKGROUND,
    fieldGroups = DEFAULT_FIELD_GROUPS,
    previewBaseUrl = '',
    queries,
    awsConfig,
    mutations,
    userEntity
  }) {
    SchemaManager.TYPES = data.__schema.types;
    SchemaManager.CREATE_EXCLUDE_FIELDS = createExcludeFields;
    SchemaManager.CREATE_EXCLUDE_ENTITIES = createExcludeEntities;
    SchemaManager.ENTITIES_WITH_IMPORT = entitiesImport;
    SchemaManager.ENTITIES_WITH_EXPORT = entitiesExport;
    SchemaManager.TABLE_FIELDS_EXCLUDES = tableExcludesFields;
    SchemaManager.TABLE_FILTERS = tableFilters;
    SchemaManager.DB_STRING_FILTERS = dbStringFilters;
    SchemaManager.ENTITIES_WITH_MANUAL_IDS = entitiesWithManualIds;
    SchemaManager.ENTITIES_WITH_PREVIEW = entitiesWithPreview;
    SchemaManager.LABELS_OVERRIDE = labelsOverride;
    SchemaManager.SPECIFIC_GROUP_FIELDS = specificGroupFields;
    SchemaManager.TRANSLATION_LANGUAGES = translationLanguages;
    SchemaManager.DEPENDENT_FIELDS = dependentFields;
    SchemaManager.RICHTEXT_FIELDS = richTextFields;
    SchemaManager.CACHED_ENTITIES = cachedEntities;
    SchemaManager.FIELDS_FULLWIDTH = fieldsFullWidth;
    SchemaManager.EXTRA_COMPONENTS = extraComponents;
    SchemaManager.FIELDS_BREAKLINES_AFTER = fieldsBreakLineAfter;
    SchemaManager.FIELDS_BREAKLINES_BEFORE = fieldsBreakLineBefore;
    SchemaManager.DEFAULT_VALUES = defaultValues;
    SchemaManager.SUBOBJECT_VALIDATIONS = subObjectValidations;
    SchemaManager.CUSTOM_COMPONENTS = customComponents;
    SchemaManager.FIELDS_BACKGROUND = fieldsBackground;
    SchemaManager.FIELD_GROUPS = fieldGroups;
    SchemaManager.queries = queries;
    SchemaManager.mutations = mutations;
    SchemaManager.awsConfig = awsConfig;
    SchemaManager.userEntity = userEntity;
    SchemaManager.previewBaseUrl = previewBaseUrl;
  }

  static getEntityFromSchema(name) {
    const schemaEntities = SchemaManager.TYPES.filter(t => t.name.toLowerCase() === name.toLowerCase());
    if (schemaEntities.length !== 1) {
      return null;
    }
    return schemaEntities[0];
  }

  static getEntity(entity) {
    const schemaEntity = SchemaManager.getEntityFromSchema(entity);
    const fields = schemaEntity.fields.map(mapField);
    let hasOrder = false;
    // console.log("getEntity -> fields", fields)
    const tableFilters = SchemaManager.TABLE_FILTERS[entity];
    const tableFields = [];
    fields.forEach((field) => {
      const { name } = field;
      if (name === 'order') hasOrder = true;
      if (name.indexOf('richText') === 0) return;
      if (SchemaManager.TABLE_FIELDS_EXCLUDES.includes(name)) return;
      if (tableFilters) {
        const filter = tableFilters.filter((el) => Object.keys(el).includes(name))?.[0];
        if (filter) field.filter = filter[name];
      }
      tableFields.push(field);
    });

    // const tableFields = fields.filter(({name}) => {
    //   if (name === 'order') hasOrder = true;
    //   if (name.indexOf('richText') === 0) return false;
    //   return !SchemaManager.TABLE_FIELDS_EXCLUDES.includes(name);
    // });
  
    return {
      name: entity,
      fields,
      tableFields,
      hasOrder
    }
  }

  static getFieldsGrouped(entity, fields) {
    const groups = SchemaManager.FIELD_GROUPS[entity] || null;
    if(!groups) return [{ title: '', fields }];

    return groups.map(({ title, fields: groupFields }) => ({
      title,
      fields: groupFields.map(groupField => fields.find(f => f.name === groupField))
    }))
  }

  static getCustomComponent(entity, fieldName) {
    return SchemaManager.CUSTOM_COMPONENTS?.[entity]?.[fieldName] ?? null;
  }

  static getExtraComponents(entity) {
    return SchemaManager.EXTRA_COMPONENTS[entity] || [];
  }

  static getLabelOverride(label) {
    return SchemaManager.LABELS_OVERRIDE[label] || '';
  }

  static isRichText(name) {
    return SchemaManager.RICHTEXT_FIELDS.includes(name);
  }
  static entityHasPreview(entity) {
    return SchemaManager.ENTITIES_WITH_PREVIEW.includes(entity);
  }

  static isFieldFullWidth(entity, field) {
    return SchemaManager.FIELDS_FULLWIDTH[entity] && SchemaManager.FIELDS_FULLWIDTH[entity].includes(field);
  }

  static fieldBreaksLineBefore(entity, field) {
    return SchemaManager.FIELDS_BREAKLINES_BEFORE[entity] && SchemaManager.FIELDS_BREAKLINES_BEFORE[entity].includes(field);
  }

  static fieldBreaksLineAfter(entity, field) {
    return SchemaManager.FIELDS_BREAKLINES_AFTER[entity] && SchemaManager.FIELDS_BREAKLINES_AFTER[entity].includes(field);
  }
  
  static getSubobjectValidations(entity, field) {
    return SchemaManager.SUBOBJECT_VALIDATIONS[entity] && SchemaManager.SUBOBJECT_VALIDATIONS[entity][field];
  }

  static getDefaultValue(entity, field) {
    return SchemaManager.DEFAULT_VALUES[entity] && SchemaManager.DEFAULT_VALUES[entity][field];
  }
  
  static getFieldBackground(entity, field) {
    return SchemaManager.FIELDS_BACKGROUND?.[entity]?.[field] ?? '#fff';
  }

  static isHiddenField(values, fieldName, entity, subObject) {
    if (fieldName === 'createdAt' || fieldName === 'updatedAt') return true;
    if (fieldName === 'order') return true;
    if (SchemaManager.isDependentFieldHidden(values, fieldName, entity, subObject)) return true;
    return false;
  }

  static isDependentFieldHidden(values, fieldName, entity, subObject) {
    if (SchemaManager.DEPENDENT_FIELDS[entity]) {
      const entityDependentFields = SchemaManager.DEPENDENT_FIELDS[entity];
      if (subObject && entityDependentFields[subObject] && entityDependentFields[subObject][fieldName]) {
        const subObjectRules = Object.keys(entityDependentFields[subObject][fieldName]);
        // console.log("🚀 ~ file: schema.js ~ line 102 ~ SchemaManager ~ isHiddenField ~ subObjectRules", subObjectRules)
        // console.log("🚀 ~ file: schema.js ~ line 96 ~ SchemaManager ~ isHiddenField ~ values", values)
        return !subObjectRules.every(rule => entityDependentFields[subObject][fieldName][rule].includes(values[rule]))
      }

      if (!entityDependentFields || !entityDependentFields[fieldName]) return false;
      const fieldRules = Object.keys(entityDependentFields[fieldName]);
      return !fieldRules.every(rule => {
        if (!Array.isArray(entityDependentFields[fieldName][rule])) return true;        
        return entityDependentFields[fieldName][rule].includes(values[rule])
      })
    }
    return false;
  }

  static getEnumLabel(value) {
    return SchemaManager.getLabelOverride(value) || value.split('_').map(capitalize).join(' ')
  }
}

function isVideoField(fieldName) {
  return fieldName.slice(-5) === 'Video';
}
function isImageField(fieldName) {
  return fieldName.slice(-5) === 'Image';
}
function isFileField(fieldName) {
  return fieldName.slice(-4) === 'File';
}

function getFieldLabel(fieldName) {
  const labelOverrided = SchemaManager.getLabelOverride(fieldName);
  if (labelOverrided) return labelOverrided;
  
  const fieldNameCleaned = fieldName.replace(/(Lang|File|Img|URL|Image)$/,'');
  if (fieldNameCleaned === 'name') return 'Name';
  if (fieldNameCleaned === 'createdAt') return 'Created';
  if (fieldNameCleaned === 'updatedAt') return 'Updated';
  if (fieldNameCleaned === 'richTextDesc') return 'Description';
  if (fieldNameCleaned === 'type') return 'Type';
  if (fieldNameCleaned === 'country') return 'Country';
  if (fieldNameCleaned === 'includes') return 'Includes';
  if (fieldNameCleaned === 'notIncludes') return 'Not includes';
  if (fieldNameCleaned === 'iva') return 'IVA (%)';
  return capitalize(fieldNameCleaned);
}

function getSubFields(field) {
  const fieldName = field.type.name;
  switch(fieldName) {
    case 'Translation': {
      return SchemaManager.TRANSLATION_LANGUAGES.map(t => ({name: t, type: 'string'}));
    }
    default: {
      return null;
    }
  }
}

function getEnumOptions(optionsName) {
  const optionsField = SchemaManager.getEntityFromSchema(optionsName);
  return optionsField
    .enumValues.map(op => {
      const labelParsed = SchemaManager.getEnumLabel(op.name);
      return { value: op.name, label: labelParsed };
    })
    .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
}

function getType({type: fieldType, name: fieldName, relatedEntity}) {
  // console.log("getType -> fieldType", fieldType, fieldName)
  if (fieldName === 'createdAt') return 'timestamp';
  if (fieldName === 'updatedAt') return 'timestamp';
  if (fieldName.slice(-4) === 'Lang') return 'translation';
  if (fieldType === 'ENUM') return 'enumSelect';
  if (fieldType === 'OBJECT') return 'entitySelect';
  if (fieldType === 'String') {
    if (isVideoField(fieldName)) return 'videoUpload';
    if (isImageField(fieldName)) return 'imageUpload';
    if (isFileField(fieldName)) return 'fileUpload';
  }
  if (fieldType === 'LIST') {
    const isIndependentEntity = !!SchemaManager.queries[`get${relatedEntity}`];
    if (isIndependentEntity) {
      return 'entitySelectMultiple';
    }
    return 'subObjectList';
  }

  if (fieldType === 'ID') return 'string';
  if (fieldType === 'Int') return 'int';
  if (fieldType === 'AWSDate') return 'date';
  if (fieldType === 'AWSDateTime') return 'timestamp';
  if (fieldType === 'LIST_FREE') return 'listFree';
  if (fieldType === 'LIST_IMG') return 'imgUploadList';
  return fieldType.toLowerCase();
}

function getFieldType (fieldType, fieldName) {
  if (fieldType.kind === 'NON_NULL') return getFieldType(fieldType.ofType);
  if (fieldType.kind === 'SCALAR') return fieldType.name;
  if (fieldType.kind === 'LIST' && isImageField(fieldName)) return 'LIST_IMG';
  if (fieldType.kind === 'LIST' && fieldType.ofType.name === 'String') return 'LIST_FREE';
  return fieldType.kind;
}

function getFieldName (fieldType, name) {
  if (fieldType.kind === 'NON_NULL' && fieldType.ofType.name.indexOf('AWS') !== 0) {
    return getFieldName(fieldType.ofType, name);
  }
  if (fieldType.kind === 'SCALAR' && fieldType.name === 'ID') return 'id';
  return name;
}

function getOptionsName (fieldType) {
  if (fieldType.kind === 'NON_NULL') return getOptionsName(fieldType.ofType);
  return fieldType.name;
}

function getRelatedEntity (fieldType, field) {
  if (field.type.kind === 'NON_NULL') return getRelatedEntity(fieldType, { type: field.type.ofType })
  if (fieldType === 'OBJECT') return field.type.name;
  if (fieldType === 'LIST') return field.type.ofType.name;
}

export function mapField(field) {
  // console.log('mapField -> field', field)
  const fieldType = getFieldType(field.type, field.name);
  const fieldName = getFieldName(field.type, field.name);

  const subfields = fieldType === 'OBJECT' ? getSubFields(field) : null;
  const options = fieldType === 'ENUM' ? getEnumOptions(getOptionsName(field.type)) : [];

  const relatedEntity = getRelatedEntity(fieldType, field);
  const type = getType({ type: fieldType, name: fieldName, relatedEntity });

  const label = getFieldLabel(fieldName);

  return {
    subfields,
    type,
    label,
    options,
    relatedEntity,
    name: fieldName,
    required: fieldType === 'NON_NULL'
  }
}

export function getRelatedEntityFieldName(entity, relatedEntity) {
  return `${entity.toLowerCase()}${capitalize(relatedEntity)}Id`
}

export function getEntityLabel(entityRecord) {
  if (!entityRecord) return '';
  if (entityRecord.nameLang) {
    return entityRecord.code ? `${entityRecord.code} - ${entityRecord.nameLang.es}` : entityRecord.nameLang.es;
  }
  // if (entityRecord.email && entityRecord.name) return `${entityRecord.email} - ${entityRecord.name} ${entityRecord.lastname}`;
  if (entityRecord.email && entityRecord.name) return `${entityRecord.lastname}, ${entityRecord.name} `;

  return entityRecord.label
    || entityRecord.name
    || entityRecord.title
    || entityRecord.code
    || (entityRecord.company && `${entityRecord.company} - ${entityRecord.department}`)
    || '';
}

export default SchemaManager;