import capitalize from '@bit/iamtechnologies.iamtech-js.capitalize';
import {
  PartnerUrl,
  StudentPaymentLink,
  VacancyUrl
} from './components';
import { labelsOverride } from './labelsOverride';

export default {
  userEntity: 'Student',
  entitiesImport: ['Country', 'Product', 'Vacancy'],
  entitiesExport: ['Partner', 'Vacancy', 'Country', 'Price'],
  entitiesWithManualIds: ['Country', 'Product', 'Partner'],
  createExcludeEntities: ['Company'],
  specificGroupFields: { 'Partner.name': 'marketing' },
  translationLanguages: ['es', 'en'],
  tableExcludesFields: ['order', 'seoDesc', 'seoTitle', 'shortDesc', 'pastinternships', 'birthday', 'gender', 'residencehasexpiration', 'format', 'studentId', 'paymentIntentId', 'answerLang'],
  tableFilters: {
    Student: [
      { search: { type: 'search' } },
      { status: { type: 'select', from: 'enum', schemaField: 'status' } },
      { country: { type: 'select', from: 'entity', entity: 'Country', value: 'id', label: 'nameLang.es', schemaField: 'studentCountryId' } },
      { residence: { type: 'select', from: 'entity', entity: 'Residence', value: 'id', label: 'nameLang.es', schemaField: 'studentResidenceId' } },
      { university: { type: 'select', from: 'entity', entity: 'University', value: 'id', label: 'name', schemaField: 'studentUniversityId' } },
      { partner: { type: 'select', from: 'entity', entity: 'Partner', value: 'id', label: 'name', schemaField: 'studentPartnerId' } },
      // { vacancyPrioritiesLocked: { type: 'boolean', label: 'Prioridades bloqueadas', schemaField: 'vacancyPrioritiesLocked' } },
      // euNationality
      // internship
      // payments
      // priorities
      // vacancyPrioritiesLocked
    ],
    Vacancy: [
      { search: { type: 'search' } },
    ],
    Payment: [
      { status: { type: 'select', from: 'enum', schemaField: 'status' } },
      { student: { type: 'select', from: 'entity', entity: 'Student', value: 'id', label: ['name', 'lastname'], schemaField: 'paymentStudentId' } },
    ],
    Docpattern: [
      { name: { type: 'search' } },
      { studentStatus: { type: 'select', from: 'enum', schemaField: 'studentStatus' } },
      { responsible: { type: 'select', from: 'enum', schemaField: 'responsible' } },
      { euNationality: { type: 'boolean', schemaField: 'euNationality' } },
      { active: { type: 'boolean', schemaField: 'active' } },
    ],
  },
  dbStringFilters: {
    Vacancy: [
      { key: 'companyName', transform: (string) => string.toUpperCase()},
      { key: 'companyName', transform: (string) => capitalize(string)},
      { key: 'department', transform: (string) => capitalize(string)},
      { key: 'zonaGeografica', transform: (string) => string.toUpperCase()},
      { key: 'company', transform: (string) => string.toUpperCase()},
    ],
    Student: ['name', 'lastname'],
    Docpattern: [{ key: 'name', transform: (string) => capitalize(string) }],
  },
  cachedEntities: ['Country', 'Residence'],
  labelsOverride,
  richTextFields: ['context', 'challenge', 'goal', 'text', 'answerLang', 'richTextObsLang', 'richTextLang'],
  dependentFields: {
    Project: {
      title: { clientName: ['TV3', 'TV4'] },
      content: {
        blockListImage: { type: ['img_columns', 'img_slider'] },
        blockImage: { type: ['video_link', 'img_text_left', 'img_text_right', 'img'] },
        bgColor: { type: ['img_text_left', 'img_text_right'] },
        caption: { type: ['img_text_left', 'img_text_right'] },
        challenge: { type: ['context_challenge_goal'] },
        context: { type: ['context_challenge_goal'] },
        goal: { type: ['context_challenge_goal'] },
        text: { type: ['text', 'link'] },
        title: { type: ['video_embed'] },
        url: { type: ['video_embed', 'tweet', 'link', 'video_link'] },
        videoSubtype: { type: ['video_embed'] }
      }
    }
  },
  extraComponents: {
    Partner: [
      PartnerUrl
    ],
    Payment: [
      StudentPaymentLink
    ],
    Vacancy: [
      VacancyUrl
    ],
  },
  defaultValues: {
    internship: {
      status: 'pending'
    }
  }
};
