import React, { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';

import FormEntitySelectField from '../../../../entities/components/formFields/FormEntitySelectField';
import { saveEntity } from '../../../../entities/services/entities';
import { getContriesSortByName } from '../../../../libs/countries';


const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(2),
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  checkboxLabel: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  title: {
    marginTop: theme.spacing(3)
  },
  error: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  booleanTextField: {
    margin: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const ERROR_MESSAGES = {
  required: 'Campo obligatorio',
  default: '',
};

const countriesList = getContriesSortByName('es');

const UserForm = (props) => {
  const { user, loading, error, rawUser } = props;
  const classes = useStyles();
  const formRef = useRef();

  const { handleSubmit, errors, setValue, control, register, watch } = useForm();
  
  useEffect(() => {
    if (user) {
      const newUser = user.filter((elem) => Object.keys(elem)[0] !== 'nationalitiesList');
      register('euNationality');
      setValue(newUser);
      setValue('euNationality', rawUser?.euNationality || false);
      setValue('nationalitiesList', rawUser?.nationalitiesList || []);
      setValue('contactEmail', rawUser?.emergencyContacts
        ?.filter(contact => contact.email)
        ?.map(contact => contact.email).join(', '));
    }
  }, [user]);
  
  const handleFormSubmit = async (values, event) => {
    if (formRef.current !== event.target) return null;
    const newValues = { ...values, id: rawUser.id };

    // AWS dates can not be empty string
    if (values?.passport?.expiration === '') newValues.passport.expiration = null;
    if (values?.birthday === '') newValues.birthday = null;
    
    const response = await saveEntity({ entity: 'student', values: newValues, id: rawUser.id });
    const { success, msg, data } = response;
  }

  return (
    <form
      ref={formRef}
      className={classes.form}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors.name}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Nombre"
            name="name"
            type="text"
            variant="outlined"
            className={classes.textField}
          />

          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.passport?.number}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Pasaporte"
            name="passport.number"            
            type="text"
            variant="outlined"
            className={classes.textField}
          />

          {/* <TextField value="12345678Z" className={classes.textField} fullWidth label="Documento Identidad" variant="outlined" /> */}

          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.email}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Email"
            name="email"            
            type="text"
            variant="outlined"
            className={classes.textField}
          />

          <Controller
            as={
              <TextField
                select
                className={classes.textField}
                variant="outlined"
                label="Género"
                fullWidth
                defaultValue="other"
              >
                <MenuItem value="male">Hombre</MenuItem>
                <MenuItem value="female">Mujer</MenuItem>
                <MenuItem value="other">Otro</MenuItem>
              </TextField>
            }
            name="gender"
            defaultValue=""
            control={control} 
            error={!!errors.gender}
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            // InputLabelProps={{shrink: true}} // Firefox label bug
          />

          <div className={classes.textField}>
            <FormEntitySelectField
              entityFieldName="studentCountryId"
              field={{ name: 'country', label: 'Destino', relatedEntity: 'Country', type: 'entitySelect' }}
              fullWidth
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </div>

          <div className={classes.booleanTextField}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  defaultChecked={rawUser?.euNationality || false}
                  onChange={(e) => setValue('euNationality', e.target.checked)}
                  name="euNationality"
                />
              }
              label="Nacionalidad europea"
            />
          </div>        
    
          <FormControl variant="outlined" fullWidth className={classes.textField}>
            <InputLabel>Nacionalidades</InputLabel>
            <Controller
              name="nationalitiesList"
              defaultValue={[]}
              control={control} 
              error={!!errors.gender}
              helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
              as={
                <Select
                  multiple
                >
                  {
                    countriesList.map((elem) => {
                      const { name } = elem;
                      const value = name.es;
                      return <MenuItem value={value} key={value}>{value}</MenuItem>
                    })
                  }
                </Select>
              }
              // InputLabelProps={{shrink: true}} // Firefox label bug
            />
          </FormControl>

          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.skypeID}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="SkypeID"
            name="skypeUsername"
            type="text"
            variant="outlined"
            className={classes.textField}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors.lastname}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Apellidos"
            name="lastname"            
            type="text"
            variant="outlined"
            className={classes.textField}
          />

          <Controller
            as={TextField}
            control={control}
            // defaultValue=""
            error={!!errors?.passport?.expiration}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Pasaporte caduca"
            name="passport.expiration"            
            type="date"
            variant="outlined"
            className={classes.textField}
            InputLabelProps={{shrink: true}} // Firefox date label bug
          />

          {/* <TextField value="" className={classes.textField} fullWidth label="NIE" variant="outlined" /> */}

          <Controller
            as={TextField}
            control={control}
            // defaultValue=""
            error={!!errors?.birthday}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Fecha nacimiento"
            name="birthday"            
            type="date"
            variant="outlined"
            className={classes.textField}
            InputLabelProps={{shrink: true}} // Firefox date label bug
          />

          <div className={classes.textField}>
            <FormEntitySelectField
              entityFieldName="studentResidenceId"
              field={{ name: 'residence', label: 'Residencia', relatedEntity: 'Residence', type: 'entitySelect' }}
              fullWidth
              register={register}              
              setValue={setValue}
              watch={watch}
            />
          </div>

          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.passport?.birthPlace}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Lugar de nacimiento"
            name="passport.birthPlace"            
            type="text"
            variant="outlined"
            className={classes.textField}
          />

          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.contactEmail}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Email de contacto"
            name="contactEmail"
            type="text"
            variant="outlined"
            className={classes.textField}
          />

          <Controller
            as={TextField}
            control={control}
            defaultValue=""
            error={!!errors?.mobilePhone}
            fullWidth
            helperText={errors && errors.type && ERROR_MESSAGES[errors.type]}
            label="Teléfono móvil"
            name="mobilePhone"            
            type="text"
            variant="outlined"
            className={classes.textField}
          />
        </Grid>
      </Grid>
      {/* <TextField multiline value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." className={classes.textField} fullWidth label="Aptitudes y habilidades" variant="outlined" /> */}
      <Button
        className={classes.signInButton}
        color="primary"
        // disabled={!isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Guardar
      </Button>
    </form>
  );
}
 
export default UserForm;
